import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Grid, ListItem, ListItemButton, useMediaQuery } from '@mui/material';
import { useTheme, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { EditIcon } from '../Misc';
import { styles } from '../../styles';

export interface LabeledFieldProperties {
    label?: React.ReactNode;
    hint?: string;
    itemClass?: string;
    value: React.ReactNode;
    icon?: React.ReactNode;
    edit?: (e: React.MouseEvent) => void;
    onLabelClick?: (e: React.MouseEvent) => void;
    noLabel?: boolean;
    wideIcon?: boolean;
    editMode?: boolean;
}

type Props = LabeledFieldProperties & WithStyles<typeof styles>;

const LabeledField = (props: Props) => {
    const { noLabel, wideIcon, hint, itemClass, value, classes, icon, edit, editMode, onLabelClick, label } = props;
    const theme = useTheme();
    const variant = useMediaQuery(theme.breakpoints.up('sm')) ? 'body2' : 'caption';
    const rightIcon = icon || <EditIcon invisible={!edit && !editMode} />;
    const val = (typeof value === 'string') ? (<Typography variant="body1" noWrap style={{ overflow: 'hidden', wordWrap: 'break-word' }} >{value}</Typography>) : value;
    const hnt = hint ? (<Typography component="span" variant="caption">{hint}</Typography>) : null;
    if (noLabel) {
        const content = wideIcon ?
            (
                <Grid container>
                    <Grid item xs={7} sm={8} md={9}>{val}{hnt}</Grid>
                    <Grid item xs={5} sm={4} md={3} onClick={onLabelClick} className={classes.colorWhite + ' ' + classes.rightText}>{rightIcon}</Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={8} sm={9} md={10}>{val}{hnt}</Grid>
                    <Grid item xs={4} sm={3} md={2} onClick={onLabelClick} className={classes.colorWhite + ' ' + classes.rightText}>{rightIcon}</Grid>
                </Grid>
            );
        if (edit || editMode) {
            return (
                <div>
                    <ListItemButton className={itemClass ? itemClass : classes.listItem1} onClick={edit}>
                        {content}
                    </ListItemButton>
                </div>
            );
        }
        return (
            <div>
                <ListItem className={itemClass ? itemClass : classes.listItem1}>
                    {content}
                </ListItem>
            </div>
        );
    } else {
        const content = wideIcon ?
            (
                <Grid container>
                    <Grid item xs={12} sm={3} md={3} onClick={onLabelClick}><Typography component="div" variant={variant} className={classes.boldText}>{label}</Typography></Grid>
                    <Grid item xs={12} sm={6} md={7}>{val}{hnt}</Grid>
                    <Grid item xs={12} sm={3} md={2} onClick={onLabelClick} className={classes.colorWhite + ' ' + classes.rightText}>{rightIcon}</Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={12} sm={3} md={3} onClick={onLabelClick}><Typography component="div" variant={variant} className={classes.boldText}>{label}</Typography></Grid>
                    <Grid item xs={9} sm={7} md={8}>{val}{hnt}</Grid>
                    <Grid item xs={3} sm={2} md={1} onClick={onLabelClick} className={classes.colorWhite + ' ' + classes.rightText}>{rightIcon}</Grid>
                </Grid>
            );
        if (edit || editMode) {
            return (
                <div>
                    <ListItemButton className={itemClass ? itemClass : classes.listItem1} onClick={edit}>
                        {content}
                    </ListItemButton>
                </div>
            );
        } else {
            return (
                <div>
                    <ListItem className={itemClass ? itemClass : classes.listItem1}>
                        {content}
                    </ListItem>
                </div>
            );
        }
    }

}

export default withStyles(styles)(LabeledField);
