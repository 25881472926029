import * as React from 'react';
import { List, Typography, ListItemButton, useMediaQuery, useTheme, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CopyIcon, GolfIcon, LocationIcon, PlusInCircleIcon } from '../../common/Icons';
import { Event, EventBase, Portal, Round, fixLegacyTees, getCurrentCourseName, isEvent, isPastEvent, isRound, isTodayEvent } from '../../types/EventTypes';
import { eventBadgeUrl, formatEventDate, formatEventDateAndTime, hasEmptyTees } from '../Event';
import { FirebaseDataComponent } from '../../common/WithData';
import * as Backend from '../../util/firebase';
import { pushUrl } from '../../redux/ReduxConfig';
import { UserAware, WithUserId, withUserId, userProviderContextTypes } from '../../auth/Auth';
import { formatDateDashed1, getUserToday, dbgLog, withS, Func1 } from '../../util/utility';
import AppButton from '../../common/components/AppButton';
import CompleteEventSetupDialog from './CompleteEventSetupDialog';
import { ListTitle, FlexCenter, IconLinkElement, Flex, Container, Item, ItemS } from '../../common/Misc';
import ArcStepper from './ArcStepper';
import { DEBUG, FEATURE_DUPLICATE_EVENT, Urls } from '../../util/config';
import { appStyles, greyBorderImg, styles } from '../../styles';
import { AppColors } from '../../main/Theme';
import NewEventDialog from './NewEventDialogDetailed';
import DuplicateEventDialog from './DuplicateEventDialog';

type EventItemProps = {
    event: Event,
    rounds: Array<Round>;
    portal: Portal,
    portalLoaded: boolean,
    stepsTotal: number,
    stepsCompleted: number,
    participantsOk: boolean;
    scheduleOk: boolean;
    teesOk: boolean;
    today: boolean;
    handleItemClick: (event: Event, copyEvent: boolean) => void;
};

const PortalLink = (props: { event: Event; click?: Func1<React.MouseEvent>; other?: React.ReactNode; }) => {
    const { event, click, other } = props;
    return (
        <IconLinkElement href={`/event/${event.publicId}`} target="_blank" rel="noopener noreferrer" click={click} other={other}>
            <Typography sx={{ fontSize: '13px' }}>Event site</Typography>
        </IconLinkElement>
    );
};

const CopyEventlLink = (props: { click: Func1<React.MouseEvent> }) => {
    const { click } = props;
    const classes = appStyles();
    if (!FEATURE_DUPLICATE_EVENT) {
        return null;
    }
    return <a href="" rel="noopener noreferrer" onClick={click}>
        <Flex>
            <CopyIcon className={classes.textIcon} />
            <Typography sx={{ fontSize: '13px' }}>Duplicate</Typography>
        </Flex>
    </a>;
};

export const TileEventItem = (props: EventItemProps) => {
    const { event, rounds, portal, portalLoaded, stepsTotal, stepsCompleted, participantsOk, scheduleOk, teesOk, handleItemClick } = props;
    const classes = appStyles();
    const [completeSetup, setCompleteSetup] = React.useState(0);
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    let buttonClicked = false;
    const aclick = () => {
        buttonClicked = true;
        setTimeout(() => buttonClicked = false);
    };
    const click = (e: React.MouseEvent, copyEvent: boolean) => {
        if (buttonClicked) {
            return;
        }
        e.preventDefault();
        buttonClicked = true;
        handleItemClick(event, copyEvent);
        setTimeout(() => buttonClicked = false);
    };
    const clickComplete = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(1);
        setTimeout(() => buttonClicked = false);
    };
    const clickCustomize = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(2);
        setTimeout(() => buttonClicked = false);
    };
    const clickStandings = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        pushUrl(`/event/${event.publicId}/standings`);
    };
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    const arcStepper = <span style={{ width: '25%', padding: 8 }}>
        <ArcStepper
            onClickSteps={clickComplete}
            badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''}
            stepsTotal={stepsTotal}
            stepsCompleted={stepsCompleted}
            size={'100%'}
        />
    </span>;
    return <>
        <ListItemButton className={classes.tileBox} onClick={e => click(e, false)}>
            <Flex style={{ width: '100%' }}>
                {!lessThanSm && arcStepper}
                <span style={{ width: '75%' }} className={classes.labelOverflow}>
                    <Container spacing={0.5} alignItems="center">
                        <ItemS xs={12}>
                            <Typography className={classes.tileEventTitle}>{event.name}</Typography>
                        </ItemS>
                        <ItemS>
                            <Typography className={classes.link + ' ' + classes.tileEventText} noWrap>
                                <PortalLink event={event} click={aclick} />
                            </Typography>
                        </ItemS>
                        <ItemS>
                            <Typography className={classes.link + ' ' + classes.tileEventText} noWrap>
                                <CopyEventlLink click={e => click(e, true)} />
                            </Typography>
                        </ItemS>
                        <ItemS xs={12}>
                            <Container>
                                {event.type === 'multiday' && rounds.length && <ItemS>
                                    <GolfIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    <Typography sx={{ fontSize: '14px' }}>{withS(rounds.length, 'round')} &nbsp;</Typography>
                                </ItemS>}
                                <ItemS xs={12} sm={6}>
                                    <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    <Typography sx={{ fontSize: '14px' }}>{formatEventDateAndTime(event, rounds, false, false)}</Typography>
                                </ItemS>
                            </Container>
                        </ItemS>
                        <ItemS xs={12}>
                            <LocationIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                            <Typography sx={{ fontSize: '14px' }}>{getCurrentCourseName(event, rounds)}</Typography>
                        </ItemS>
                        {portalLoaded && <ItemS xs={12}>
                            {stepsCompleted < stepsTotal &&
                                <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                            {stepsCompleted > stepsTotal &&
                                <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                            {stepsCompleted === stepsTotal &&
                                <AppButton color="info" onClick={clickCustomize}>Customize event</AppButton>}
                        </ItemS>}
                    </Container>
                </span>
                {lessThanSm && arcStepper}
            </Flex>
        </ListItemButton>
        {completeSetup > 0 &&
            <CompleteEventSetupDialog
                event={event}
                participantsOk={participantsOk}
                scheduleOk={scheduleOk}
                teesOk={teesOk}
                handleClose={eventSetupClosed}
                customize={completeSetup === 2}
            />}
    </>;
};

type AddNewEventItemProps = {
    taller: boolean;
    onClick: () => void
};

const AddNewEventItem = ({ onClick, taller }: AddNewEventItemProps) => {
    const classes = appStyles();
    const [hover, setHover] = React.useState(false);
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Grid>
            <ListItemButton
                onClick={onClick}
                className={classes.tileNewEvent}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                    minHeight: lessThanSm ? 100 : (taller ? 200 : 160),
                    color: hover ? AppColors.webBlue500 : AppColors.bluish,
                    backgroundImage: hover ? 'none' : `url(${greyBorderImg})`,
                    backgroundSize: 'contain'
                }}>
                <Grid container spacing={0} alignItems="center">
                    <Grid xs={12}>
                        <FlexCenter>
                            <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', margin: '4px', width: 400 }}>
                                Add new event
                            </Typography>
                        </FlexCenter>
                        <FlexCenter>
                            <PlusInCircleIcon hover={hover} />
                        </FlexCenter>
                    </Grid>
                </Grid>
            </ListItemButton>
        </Grid>
    );
};

type ScheduleSessionProps = {
    onClose: () => void
};

const ScheduleSessionItem = ({ onClose }: ScheduleSessionProps) => {
    const classes = appStyles();
    const moreThanSm = useMediaQuery(useTheme().breakpoints.up('sm'));
    const openSessionSchedule = () => window.open(Urls.eventsUserSession, '_blank');
    return (
        <Grid minHeight={moreThanSm ? 200 : 160}>
            <ListItemButton className={classes.tileScheduleSession} sx={{ backgroundColor: AppColors.webBlue100 }}>
                <Grid container spacing={0} alignItems="center">
                    {moreThanSm && <Grid xs={2}>
                        <FlexCenter><img src={Urls.scheduleOnlineSessionImg} style={{ width: '100%', maxWidth: 120 }} alt="" /></FlexCenter>
                    </Grid>}
                    <Grid xs={moreThanSm ? 10 : 12}>
                        <IconButton color="secondary" sx={{ float: 'right' }} onClick={onClose}><CloseIcon /></IconButton>
                        <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', margin: '4px' }}>
                            Schedule an online session
                        </Typography>
                        <Typography sx={{ fontWeight: 400, fontSize: 14, lineHeight: '21px', margin: '4px' }}>
                            The Golf Pad team can be your on-hand resource as you create your golf tournament from start to finish. Seeing your experience and learning about your tournament needs will improve the Events platform.
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: '21px', margin: '4px' }}>
                            Schedule a video call with the Golf Pad Events team today!
                        </Typography>
                        <AppButton color="secondary" onClick={openSessionSchedule}><DateRangeIcon className={classes.textIcon} /> Book session</AppButton>
                    </Grid>
                </Grid>
            </ListItemButton>
        </Grid>
    );
};

type EventDedails = {
    rounds: Array<Round>;
    portal: Portal;
    stepsCompleted: number;
    portalLoaded: boolean;
    teesOk: boolean;
    stepsTotal: number;
    participantsOk: boolean;
    scheduleOk: boolean;
    today: boolean;
};

type EventsGroupListProps = {
    title: string;
    showMore: boolean;
    eventsGroup: Event[];
    firstEventsPortion: number;
    onShowMoreOrLessClick: () => void;
    eventDedails: (event: Event, today: boolean) => EventDedails;
    handleItemClick: (event: Event, copyEvent: boolean) => void;
    onElementScroll: (id: string) => void;
};

const EventsGroupList = (props: EventsGroupListProps) => {
    const classes = appStyles();
    const { title, eventsGroup, showMore, firstEventsPortion, onShowMoreOrLessClick, eventDedails, handleItemClick, onElementScroll } = props;
    const lastItemRefs = new Map<string, React.RefObject<HTMLDivElement>>();
    eventsGroup.forEach(event => lastItemRefs.set(event.id, React.createRef<HTMLDivElement>()));
    React.useEffect(() => {
        const observer = new IntersectionObserver(
            entries => entries.filter(entry => entry.isIntersecting && entry.target.id).forEach(entry => onElementScroll(entry.target.id))
        );
        lastItemRefs.forEach(lastItemRef => {
            if (lastItemRef.current) {
                observer.observe(lastItemRef.current);
            }
        });
        return () => {
            lastItemRefs.forEach(lastItemRef => {
                if (lastItemRef.current) {
                    observer.unobserve(lastItemRef.current)
                }
            });
        };
    }, [lastItemRefs]);
    const showMoreVisible = eventsGroup.length > firstEventsPortion;
    return <>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <ListTitle text={title} />
            {eventsGroup.length > 0 && <Typography style={{ color: AppColors.webGrey300, paddingRight: 32, paddingTop: 16 }}>
                {withS(eventsGroup.length, 'event')}
            </Typography>}
        </Box>
        <List className={classes.listRootGreyRound}>
            {eventsGroup.slice(0, firstEventsPortion).map(
                event => <RowEventItem
                    key={event.id}
                    event={event}
                    {...eventDedails(event, false)}
                    ref={lastItemRefs.get(event.id)}
                    handleItemClick={handleItemClick}
                />)}
            {showMoreVisible && <div style={{ width: '100%', height: 50, display: 'flex', alignItems: 'center' }}>
                <Typography color="info" onClick={onShowMoreOrLessClick} style={{
                    cursor: 'pointer', fontSize: 14, lineHeight: '21px', marginLeft: 4,
                    color: AppColors.webBlue500, display: 'flex', justifyContent: 'left',
                    alignItems: 'center', fontFamily: 'poppins, sans-serif'
                }}>
                    {`Show ${showMore ? 'less' : 'more'}`}
                    <ExpandMoreIcon style={{ transform: showMore ? 'rotate(180deg)' : 'none' }} />
                </Typography>
            </div>}
            {showMore && eventsGroup.slice(firstEventsPortion).map(
                event => <RowEventItem
                    key={event.id}
                    event={event}
                    {...eventDedails(event, false)}
                    ref={lastItemRefs.get(event.id)}
                    handleItemClick={handleItemClick}
                />)}
        </List>
    </>;
};

const RowEventItem = React.forwardRef(((props: EventItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const classes = appStyles();
    const { event, rounds, handleItemClick } = props;
    const { portal, portalLoaded } = props;
    let buttonClicked = false;
    const click = (e: React.MouseEvent, copyEvent: boolean) => {
        if (buttonClicked) {
            return;
        }
        e.preventDefault();
        buttonClicked = true;
        handleItemClick(event, copyEvent);
        setTimeout(() => buttonClicked = false);
    };
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    const arcStepper = <span style={{ paddingRight: 6 }}>
        <ArcStepper
            badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''}
            stepsTotal={0}
            stepsCompleted={0}
            size={60}
        />
    </span>;
    const copyIcon = <CopyEventlLink click={e => click(e, true)} />;
    return <ListItemButton className={classes.listItem1} ref={ref} id={event.id}>
        {arcStepper}
        {lessThanSm ? <Box onClick={e => click(e, false)} display="flex" flexDirection="column" width="75%">
            <Typography variant="body1" className={classes.boldText}>{event.name}</Typography>
            <Typography variant="body2" noWrap className={classes.link}>
                <PortalLink event={event} other={copyIcon} />
            </Typography>
            <Typography variant="body2" noWrap style={{ width: '130px', minWidth: '130px' }}>
                <Flex>
                    <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                    {formatDateDashed1(event.date)}
                </Flex>
            </Typography>
            <Typography variant="body2" className={classes.secondaryText} noWrap>
                {event.course && <LocationIcon className={classes.textIcon} />}
                {getCurrentCourseName(event, rounds)}
            </Typography>
        </Box> : <Container spacing={0.5} sx={{ width: '100%' }}>
            <Item xs={10} onClick={e => click(e, false)}>
                <Typography variant="body1" className={classes.boldText}>{event.name}</Typography>
            </Item>
            <Item xs={2} wide>
                <div style={{ float: 'right', marginRight: 10 }}>
                    <Typography variant="body2" noWrap className={classes.link}>
                        <PortalLink event={event} other={copyIcon} />
                    </Typography>
                </div>
            </Item>
            <Item xs={12} onClick={e => click(e, false)}>
                <span style={{ display: 'flex' }}>
                    <Typography variant="body2" noWrap style={{ minWidth: '136px' }}>
                        <Flex>
                            <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                            {formatEventDate(event, rounds)}
                        </Flex>
                    </Typography>
                    <Typography variant="body2" className={classes.secondaryText} noWrap>
                        <Flex>
                            {event.course && <LocationIcon className={classes.textIcon} />}
                            {getCurrentCourseName(event, rounds)}
                        </Flex>
                    </Typography>
                </span>
            </Item>
        </Container>}
    </ListItemButton>;
}));

type Props = WithUserId & WithStyles<typeof styles> & ReactCookieProps;

type State = {
    addDialogOpened: boolean;
    duplicateEvent?: Event;
    events: Array<EventBase>;
    eventPortals: Map<string, Portal>;
    eventStatGolfers: Map<string, number>;
    eventStatGroups: Map<string, number>;
    eventStatTees: Map<string, number>;
    showMore: boolean;
    loading: boolean;
};

class EventList extends React.Component<Props, State> {
    private firstPastEventsPortion = 5;
    private interval?: NodeJS.Timeout;
    private mounted = false;

    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    state: State = {
        addDialogOpened: false,
        events: [],
        eventPortals: new Map(),
        eventStatGolfers: new Map(),
        eventStatGroups: new Map(),
        eventStatTees: new Map(),
        showMore: false,
        loading: true
    };

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        Backend.trackEvent('view_events');
        this.mounted = true;
        this.interval = setInterval(() => this.downloadEventsData(), 50);
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private handleAddClick = () => this.setState({ addDialogOpened: true });

    private handleItemClick = (event: Event, copyEvent: boolean) => {
        if (copyEvent) {
            this.setState({ duplicateEvent: event });
        } else {
            this.openEvent(event.id);
        }
    }

    private openEvent(id: string) {
        dbgLog(`openEvent ${id} - ${this.context.hasUpdated}`)
        if (this.context.hasUpdated) {
            window.location.replace(`/events/${id}`)
        } else {
            pushUrl(`/events/${id}`);
        }
    }

    private eventDedails = (event: Event, today: boolean) => {
        const { events, eventPortals, eventStatGolfers, eventStatGroups, eventStatTees } = this.state;
        const rounds = events.filter(round => isRound(round) && round.eventId === event.id) as Array<Round>;
        rounds.sort((a, b) => a.roundOrder - b.roundOrder)
        let stepsTotal = 0;
        let stepsCompleted = 0;
        let participantsOk = false;
        let scheduleOk = false;
        let teesOk = false;
        if (!eventPortals.has(event.id)) {
            stepsTotal = -1;
            stepsCompleted = -1;
        } else if (eventStatGolfers.has(event.id)) {
            stepsTotal = 3;
            if (eventStatGolfers.get(event.id)) {
                stepsCompleted++;
                participantsOk = true;
            }
            if (eventStatGroups.get(event.id)) {
                stepsCompleted++;
                scheduleOk = true;
            }
            if (eventStatTees.get(event.id)) {
                stepsCompleted++;
                teesOk = true;
            }
            if (today && stepsCompleted === stepsTotal) {
                stepsCompleted++;
            }
        }
        return {
            rounds,
            portal: eventPortals.get(event.id) ?? {} as Portal,
            portalLoaded: eventPortals.has(event.id),
            stepsCompleted,
            stepsTotal,
            participantsOk,
            scheduleOk,
            teesOk,
            today
        };
    }

    private handleCloseDialog = (addedId?: string) => {
        this.setState({ addDialogOpened: false });
        if (addedId) {
            this.context.setEventId(addedId);
            this.openEvent(addedId);
        }
    }

    private handleDuplicateEvent = () => {
        this.setState({ addDialogOpened: false, duplicateEvent: { id: '' } as Event });
    }

    private onEvents = (events: Array<EventBase>) => {
        events.sort((a, b) => b.date - a.date)
        this.setState({ events, loading: false }, () => {
            const today = getUserToday();
            const notPast = events.filter(event => isEvent(event) && !event.deleted && !isPastEvent(event, today));
            const todays = notPast.filter(event => isTodayEvent(event, today));
            const upcoming = notPast.filter(event => !isTodayEvent(event, today));
            todays.forEach(event => this.requestDownloadEventData(event.id));
            upcoming.forEach(event => this.requestDownloadEventData(event.id));
        });
    }

    render() {
        const { classes, userId } = this.props;
        const { events, addDialogOpened, duplicateEvent } = this.state;
        const { effectiveUserId } = this.context;
        const today = getUserToday();
        const deletedEvents: Array<Event> = [];
        const todayEvents: Array<Event> = [];
        const pastEvents: Array<Event> = [];
        const upcomingEvents: Array<Event> = [];
        events.forEach(event => {
            if (isEvent(event)) {
                if (event.deleted) {
                    deletedEvents.push(event);
                } else if (isPastEvent(event, today)) {
                    pastEvents.push(event);
                } else if (isTodayEvent(event, today)) {
                    todayEvents.push(event);
                } else {
                    upcomingEvents.splice(0, 0, event);
                }
            }
        });
        const totalEvents = todayEvents.length + upcomingEvents.length + pastEvents.length;
        return <>
            {!navigator.onLine ? this.renderNoInternet() :
                totalEvents === 0 && (deletedEvents.length === 0 || !effectiveUserId) ? this.renderNoEvents() :
                    <div className={classes.root} style={{ paddingTop: 0 }}>
                        {this.renderEvents(todayEvents, 'Today', true)}
                        {this.renderEvents(upcomingEvents, 'Upcoming Events', true)}
                        {this.renderEvents(pastEvents, 'Past', false)}
                        {!!effectiveUserId && this.renderEvents(deletedEvents, 'Deleted')}
                    </div>}
            {addDialogOpened &&
                <NewEventDialog
                    noDuplicate={totalEvents === 0}
                    handleClose={this.handleCloseDialog}
                    handleDuplicateEvent={this.handleDuplicateEvent} />}
            {duplicateEvent &&
                <DuplicateEventDialog
                    event={duplicateEvent}
                    handleClose={(addedId) => {
                        this.setState({ duplicateEvent: undefined }, () => {
                            if (addedId) {
                                this.context.setEventId(addedId);
                                this.openEvent(addedId);
                            }
                        });
                    }}
                />}
            <FirebaseDataComponent<EventBase>
                dbg={DEBUG}
                name="events"
                queryPath={userId}
                query={Backend.eventsQuery(userId)}
                onData={this.onEvents}
            />
        </>;
    }

    private renderEvents(events: Array<Event>, title: 'Today' | 'Upcoming Events' | 'Past' | 'Deleted', tiles?: boolean) {
        if (!events.length && title !== 'Upcoming Events') {
            return null;
        }
        const today = title === 'Today';
        const { classes, cookies } = this.props;
        if (tiles) {
            const closeScheduleTile = () => {
                cookies?.set('scheduleBookClosed', true, { path: '/' });
            }
            const showAddNew = !today;
            const showSchedule = !today && events.length === 0 && !cookies?.get('scheduleBookClosed');
            return <>
                <ListTitle text={title} onClick={() => title === 'Upcoming Events' && cookies?.remove('scheduleBookClosed')} />
                <Container className={classes.listRootGreyRound}>
                    {events.map(item => <TileEventItem
                        key={item.id}
                        event={item}
                        handleItemClick={this.handleItemClick}
                        {...this.eventDedails(item, today)}
                    />)}
                    {showSchedule && <ScheduleSessionItem onClose={closeScheduleTile} />}
                    {showAddNew && <AddNewEventItem onClick={this.handleAddClick} taller={showSchedule} />}
                </Container>
            </>;
        } else {
            const { showMore } = this.state;
            return <EventsGroupList
                title={title}
                showMore={showMore}
                eventsGroup={events}
                eventDedails={this.eventDedails}
                firstEventsPortion={this.firstPastEventsPortion}
                onShowMoreOrLessClick={this.onShowMoreOrLessClick}
                handleItemClick={this.handleItemClick}
                onElementScroll={this.requestDownloadEventData}
            />;
        }
    }

    private onShowMoreOrLessClick = async () => {
        const { showMore } = this.state;
        this.setState({ showMore: !showMore });
    };

    private eventsDataToDownload = new Array<string>();
    private eventsDataDownloading = new Set<string>();

    private requestDownloadEventData = (eventId: string) => {
        const { eventPortals } = this.state;
        if (!this.eventsDataToDownload.includes(eventId) && !this.eventsDataDownloading.has(eventId) && !eventPortals.has(eventId)) {
            this.eventsDataToDownload.push(eventId);
        }
    }

    private downloadEventsData = () => {
        const { eventPortals } = this.state;
        const eventIds = this.eventsDataToDownload.splice(0, 10).filter(id => !eventPortals.has(id));
        if (eventIds.length === 0) {
            return;
        }
        eventIds.forEach(id => this.eventsDataDownloading.add(id));
        this.loadEventsData(eventIds);
    }

    private loadEventsData = async (eventIds: Array<string>) => {
        const { events, eventPortals, eventStatGolfers, eventStatGroups, eventStatTees } = this.state;
        const t0 = Date.now();
        const portals = await Backend.getEntities<Portal>(
            Backend.portalInfoDb,
            Backend.where(Backend.documentId(), 'in', eventIds)
        );
        if (!this.mounted) return;
        const t1 = Date.now();
        portals.forEach(portal => eventPortals.set(portal.id, portal));
        eventIds.forEach(id => this.eventsDataDownloading.delete(id));
        this.setState({ eventPortals });
        const today = getUserToday();
        const eventsToDownload = eventIds.map(id => events.find(e => e.id === id)!).filter(event => event && !event.deleted && !isPastEvent(event, today));
        const singleDayEvents = eventsToDownload.filter(event => event.type !== 'multiday');
        const multiDayEvents = eventsToDownload.filter(event => event.type === 'multiday');
        const golferCounts = await Promise.all(singleDayEvents.map(currentEvent => Backend.getCountFromServer(Backend.eventGolfersQuery(currentEvent.id))));
        if (!this.mounted) return;
        const groupCounts = await Promise.all(singleDayEvents.map(currentEvent => Backend.getCountFromServer(Backend.eventNonemptyGroupsQuery(currentEvent.id))));
        if (!this.mounted) return;
        const t2 = Date.now();
        for (let i = 0; i < singleDayEvents.length; i++) {
            fixLegacyTees(singleDayEvents[i], []);
            eventStatGolfers.set(singleDayEvents[i].id, golferCounts[i].data().count);
            eventStatGroups.set(singleDayEvents[i].id, groupCounts[i].data().count);
            eventStatTees.set(singleDayEvents[i].id, hasEmptyTees(singleDayEvents[i]) ? 0 : 1);
        }
        this.setState({ eventStatGolfers, eventStatGroups, eventStatTees });
        let loadedRouns = 0;
        for (const multiDayEvent of multiDayEvents) {
            const rounds = events.filter(r => isRound(r) && r.eventId === multiDayEvent.id && !r.deleted) as Array<Round>;
            const roundGolferCounts = await Promise.all(rounds.map(round => Backend.getCountFromServer(Backend.eventGolfersQuery(round.id))));
            if (!this.mounted) return;
            const roundGroupCounts = await Promise.all(rounds.map(round => Backend.getCountFromServer(Backend.eventNonemptyGroupsQuery(round.id))));
            if (!this.mounted) return;
            loadedRouns += rounds.length;
            let golferCount = 1000000;
            let groupCount = 1000000;
            let teesCount = 1;
            for (let i = 0; i < rounds.length; i++) {
                if (golferCount > roundGolferCounts[i].data().count) {
                    golferCount = roundGolferCounts[i].data().count;
                }
                if (groupCount > roundGroupCounts[i].data().count) {
                    groupCount = roundGroupCounts[i].data().count;
                }
                if (hasEmptyTees(rounds[i])) {
                    teesCount = 0;
                }
                eventStatGolfers.set(rounds[i].id, roundGolferCounts[i].data().count);
                eventStatGroups.set(rounds[i].id, roundGroupCounts[i].data().count);
                eventStatTees.set(rounds[i].id, hasEmptyTees(rounds[i]) ? 0 : 1);
            }
            eventStatGolfers.set(multiDayEvent.id, golferCount);
            eventStatGroups.set(multiDayEvent.id, groupCount);
            eventStatTees.set(multiDayEvent.id, teesCount);
        };
        const t3 = Date.now();
        if (!this.mounted) {
            return;
        }
        this.setState({ eventStatGolfers, eventStatGroups, eventStatTees });
        dbgLog(`loadEventsData events: ${eventIds.length}, rounds: ${loadedRouns}, t1: ${t1 - t0}, t2: ${t2 - t1}, t3: ${t3 - t2}`);
    }

    private renderNoEvents() {
        const { classes } = this.props;
        const { loading } = this.state;
        if (loading) {
            return null;
        }
        return (
            <Grid container justifyContent="center">
                <Grid xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FlexCenter>
                        <div className={classes.welcomeHeader1}>Welcome to Golf Pad Events</div>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography className={classes.welcomeHeader2}>Steps to creating your first golf tournament</Typography>
                    </FlexCenter>
                </Grid>
                <Grid xs={12} style={{ paddingTop: 24, paddingBottom: 24 }}>
                    <div className={classes.centerOuter}>
                        <div className={classes.centerInner}>
                            <Grid container>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeCreateEventImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">1. Create your event</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1" alignContent="center">Select the course, format, date and set up registration.</Typography></FlexCenter>
                                </Grid>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeAddGolfersImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">2. Add golfers</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1">Add, import or share registration link with golfers.</Typography></FlexCenter>
                                </Grid>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeViewScoringImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">3. Set up scoring</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1">Get scores from players' apps or enter  yourself and share live standings.</Typography></FlexCenter>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <FlexCenter className={classes.welcomeNormalButton}>
                        <AppButton style={{ height: 40 }} color="secondary" onClick={this.handleAddClick}>
                            <AddIcon /> Create new event
                        </AppButton>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            Need help with getting started?
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            <a href={Urls.helpURL} target="help" className={classes.link}>Visit FAQ</a>
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        or
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            <a href={Urls.eventsUserSession} target="_blank" className={classes.link}>Schedule an online session</a>
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 400, fontSize: 14, lineHeight: '21px', padding: '4px', maxWidth: 580 }}>
                            The Golf Pad team can be your on-hand resource as you create your golf tournament from start to finish.
                            Seeing your experience and learning about your tournament needs will improve the Events platform.
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: '21px', padding: '4px', maxWidth: 580 }}>
                            Schedule a video call with the Golf Pad Events team today!
                        </Typography>
                    </FlexCenter>
                </Grid>
                <div className={classes.welcomeFooterButton}>
                    <FlexCenter padding={16}>
                        <AppButton fullWidth style={{ height: 48 }} color="secondary" onClick={this.handleAddClick}><AddIcon /> Create new event</AppButton>
                    </FlexCenter>
                </div>
            </Grid>
        );
    }

    private renderNoInternet() {
        const { classes } = this.props;
        const { loading } = this.state;
        const status = 'No Internet connection.';
        if (loading) {
            return null;
        }
        return (
            <div className={classes.centerOuter}>
                <div className={classes.centerInner}>
                    <div className={classes.childrenCentered}>
                        <div className={classes.margin20}>
                            <img src={Urls.golfImage} style={{ height: 170 }} alt="" />
                        </div>
                        <div className={classes.margin20}>
                            <Typography variant="h5" className={classes.boldText}>{status}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withUserId(withStyles(styles)(withCookies(EventList)));
