import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppButton from '../../common/components/AppButton';
import { processEnterKey } from '../../util/react_utils';

type Props = {
    open: boolean;
    title?: string;
    content: string | React.ReactNode;
    okLabel?: string;
    cancelLabel?: string;
    onOk: () => void;
    onCancel: () => void;
    disableEscapeKeyDown?: boolean;
    disableBackdropClick?: boolean;
};

type State = { focused: string; };

class ConfirmDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { focused: '' };
    }

    private handleEnter = () => {
        const { onOk, onCancel } = this.props;
        const { focused } = this.state;
        if (focused === 'ok') {
            onOk();
        } else if (focused === 'cancel') {
            onCancel();
        }
    }

    private handleClose = (uiEvent: string, reason: string) => {
        const { onCancel, disableEscapeKeyDown, disableBackdropClick } = this.props;
        if ("backdropClick" === reason && disableBackdropClick) { 
            return;
        }
        if ("escapeKeyDown" === reason && disableEscapeKeyDown) { 
            return;
        }
        onCancel();
    }
    
    render() {
        const {
            open,
            onOk,
            onCancel,
            content,
            title = 'Confirm operation',
            okLabel = 'OK',
            cancelLabel = 'Cancel'
        } = this.props;
        return (
            <Dialog open={open} onClose={this.handleClose} onKeyDown={e => processEnterKey(e, this.handleEnter)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={onCancel} color="info" autoFocus
                        onFocus={() => this.setState({ focused: 'cancel' })}
                        onBlur={() => this.setState({ focused: '' })}>
                        {cancelLabel}
                    </AppButton>
                    <AppButton onClick={onOk} color="secondary"
                        onFocus={() => this.setState({ focused: 'ok' })}
                        onBlur={() => this.setState({ focused: '' })}>
                        {okLabel}
                    </AppButton>
                </DialogActions>
            </Dialog >
        );
    }
}

export default ConfirmDialog;
