import * as React from 'react';
import { Course, Tee, ResultStatus, EventBase } from '../../../types/EventTypes';
import { loadCourseTees } from '../../Courses';
import { withProgress } from '../../../util/ProgressPromise';

interface Props {
    eventOrRound: EventBase;
    course?: Course;
    dontCombineCompound?: boolean;
    includeDeleted?: boolean;
    includeNotCombined?: boolean;
    onResult: (loadStatus: ResultStatus, tees?: Array<Tee>, notCombinedTees?: Array<Tee>) => void;
}

export default class TeesProvider extends React.Component<Props> {

    private mounted: boolean = false;
    private controller = new AbortController();

    componentDidMount() {
        this.mounted = true;
        this.loadTees();
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    render() {
        return null;
    }

    public loadTees = async () => {
        const { eventOrRound, course, includeDeleted, dontCombineCompound, includeNotCombined, onResult } = this.props;
        if (course) {
            onResult('in_progress');
            const notCombinedTees = includeNotCombined ? new Array<Tee>() : undefined;
            withProgress(loadCourseTees(eventOrRound.userId, course, includeDeleted ?? false, !dontCombineCompound, notCombinedTees, this.controller), true)
                .then(tees => this.teesLoaded(tees, notCombinedTees))
                .catch(this.teesFailed);
        } else {
            onResult('error');
        }
    }

    private teesLoaded = (tees: Array<Tee>, notCombinedTees?: Array<Tee>) => {
        if (!this.mounted) {
            return;
        }
        const { onResult } = this.props;
        onResult('ok', tees, notCombinedTees);
    }

    private teesFailed = (err: any) => {
        if (!this.mounted) {
            return;
        }
        const { onResult } = this.props;
        onResult('error');
    }
}
