import * as React from 'react';
import { ChangeEvent } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AppButton from '../../common/components/AppButton';
import { TextField } from '@mui/material';
import { XSMobileDialog } from './MobileDialog';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import { toSafeString } from '../../util/utility';
import { Typography } from '@mui/material';

type Props = {
    title: string;
    label: string;
    content: string;
    handleClose: (val: string) => void;
};

interface State {
    content: string;
    isMax: boolean; 
}

const MAX_LINES  = 20;
const MAX_LENGTH = 4000;

class TextEditDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            content: this.props.content,
            isMax: this.props.content.length >= MAX_LENGTH
        };
    }

    private onClose = () => {
        this.props.handleClose(this.state.content || '');
    }

    private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        if (val.length > MAX_LENGTH) {
            val = val.slice(0, MAX_LENGTH);
        }
        const lines: string[] = val.split(/\r\n|\r|\n/);
        if (lines.length > MAX_LINES) {
            val = '';
            for (let i = 0; i < MAX_LINES; ++i) {
                val += lines[i] + (i < 11 ? '\n' : ''); 
            }
        } 
        this.setState({isMax: val.length >= MAX_LENGTH || lines.length > MAX_LINES, content: toSafeString(val)});
    }

    private onCancel = () => {
        this.props.handleClose(this.props.content || '');
    }

    render() {
        const {title, label} = this.props;
        return (
            <XSMobileDialog open={true} onClose={this.onClose} maxWidth={'lg'} fullWidth={true}>
                <DialogAppBar label={title} close={this.onCancel} />
                <DialogContent>
                    <TextField label={label} fullWidth value={this.state.content} onChange={this.handleChange} rows="12" variant="outlined" multiline={true} />
                    {this.state.isMax && <Typography color="error" variant="body1" style={{ margin: 16 }}>Text size limit reached</Typography>}
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={this.onClose} color="secondary">Ok</AppButton>
                </DialogActions>
          </XSMobileDialog>
        );
    }
}

export default TextEditDialog;
