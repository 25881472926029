import * as React from 'react';
import { XSMobileDialog } from '../../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../../common/dialog/DialogAppBar';
import { Facility, ResultStatus, } from '../../../../../types/EventTypes';
import { FacilityList } from './FacilityView';
import * as Backend from '../../../../../util/firebase';
import { withProgress } from '../../../../../util/ProgressPromise';
import RecentProvider from './providers/RecentProvider';

interface Props {
    handleClose: () => void;
    handleFacilitySelect: (selectedFacility: Facility) => void;
    savedCourses?: boolean;
    allowDelete?: boolean;
    title?: string;
    titleHint?: string;
    userId: string;
}

class RecentCoursesSelectionDialog extends React.Component<Props> {
    state: { loadStatus: ResultStatus, loadResult: string, facilities?: Array<Facility> } =
        { loadStatus: 'ok', loadResult: '' };

    private recentProvider: React.RefObject<RecentProvider>;

    constructor(props: Props) {
        super(props);
        this.recentProvider = React.createRef();
    }

    private onResult = (loadStatus: ResultStatus, facilities?: Array<Facility>, err?: any) => {
        const loadResult = err ?? '';
        this.setState({ loadStatus, loadResult });
        if (facilities || loadStatus === 'ok') {
            this.setState({ facilities });
        }
    }

    private deleteFacility = (facility: Facility) => {
        const { userId, savedCourses: getSavedCourse } = this.props;
        withProgress(Backend.deleteDocument((getSavedCourse ? Backend.coursesDb : Backend.recentCoursesDb)(userId), facility.id))
            .then(this.recentProvider.current?.load);
    }

    render() {
        const { handleClose, handleFacilitySelect, userId, savedCourses, allowDelete, title = 'Select course', titleHint } = this.props;
        const { facilities, loadStatus, loadResult } = this.state;
        return (
            <XSMobileDialog open={true} onClose={handleClose}>
                <DialogAppBar label={title + ' ' + (titleHint ? titleHint : '')} close={handleClose} />
                <FacilityList userId={userId} facilitiesGroups={[{ facilities, loadStatus, loadResult }]}
                    onSelect={handleFacilitySelect} onDelete={this.deleteFacility}
                    allowDelete={allowDelete} savedCourses={savedCourses} extraInfo={savedCourses} />
                <RecentProvider userId={userId} savedCourse={savedCourses} onResult={this.onResult} ref={this.recentProvider} />
            </XSMobileDialog>
        );
    }
}

export default RecentCoursesSelectionDialog;
