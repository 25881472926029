import * as React from 'react';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import { FormControl, Typography, DialogContent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogProps } from '@mui/material/Dialog';
import Button from '../common/components/AppButton';
import { styles } from '../styles';
import { remoteLogin, remoteSignup } from './Account';

class LoginPromptDialog extends React.Component<DialogProps & WithStyles<typeof styles>> {

    private handleLogin = () => remoteLogin();
    private handleSignup = () => remoteSignup();

    render() {
        const { classes } = this.props; 
        return (
            <XSMobileDialog open={true} maxWidth={'xs'} fullWidth={true} onClose={this.props.onClose} > 
                <DialogContent classes = {{ root: classes.body }}>
                    <Typography variant='h6' classes = {{ root: classes.padding2u }}>Are you a Golf Pad app user?</Typography>
                    <FormControl variant="standard" margin="dense">
                        <div style={{textAlign: 'center', margin: '6px'}}>
                            <Button variant="contained" style={{ width: 220 }} onClick={this.handleLogin} color="secondary">Yes, I have an account</Button>
                        </div>
                        <div style={{textAlign: 'center', margin: '6px'}}>
                            <Button variant="contained" style={{ width: 220 }} onClick={this.handleSignup}>I am new/sign me up</Button>
                        </div>
                    </FormControl>
                </DialogContent>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(LoginPromptDialog);
