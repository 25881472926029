import * as React from 'react';
import { ChangeEvent } from 'react';
import { toSafeString } from '../../util/utility';
import { ValidateProps } from '../../validation/ValidatedForm';
import ValidatedTextField, { ValidatedTextFieldProps } from '../../validation/ValidatedTextField';
import { CommonEditDialogProps, EditDialogProps } from './WithEditDialog';
import withEditableField from './WithEditField';

type Props = EditDialogProps<string> & ValidatedTextFieldProps & CommonEditDialogProps<string> & ValidateProps;

const InnerField = class extends React.Component<Props> {

    private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { label } = this.props;
        const val = toSafeString(event.target.value);
        if (this.props.validateField) {
            this.props.validateField(label, val)
        }
        this.props.setValue(val!);
    }

    render() {
        const { label, hint, register, rules, value, textFieldProps, inputValueModificationFunc } = this.props;
        const onChange = this.handleChange;
        return (
            <ValidatedTextField textFieldProps={{
                id: label, label, placeholder: hint, value, margin: 'normal', onChange,
                InputProps: textFieldProps.InputProps ?? {}, InputLabelProps: { shrink: true }, autoFocus: true,
                inputProps: textFieldProps.inputProps ?? {}
            }} register={register} keepError rules={rules} inputValueModificationFunc={inputValueModificationFunc} />
        );
    }
};

export default withEditableField<ValidatedTextFieldProps, string>({ toShow: s => s })(InnerField);
