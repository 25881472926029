import * as React from 'react';
import { Action, isOfType, withKey } from './ReduxCommon';
import { AnyAction, Reducer } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface RouterState {
    token: number;
    path: string;
    replace: boolean;
}

const DEFAULT_STATE: RouterState = {
    token: 1,
    path: '',
    replace: false
};

const ROUTER_TYPE = 'router_url';

interface RouterAction extends Action {
    path: string;
    replace: boolean;
}

export function routeToAction(path: string, replace: boolean): RouterAction {
    return { type: ROUTER_TYPE, path: path, replace: replace };
}

export const routerReducer: Reducer<RouterState> = (state: RouterState | undefined, action: AnyAction) => {
    if (state && isOfType<RouterAction>(action, ROUTER_TYPE)) {
        const copy = { ...state };
        copy.path = action.path;
        copy.replace = action.replace;
        copy.token = Math.random();
        return copy;
    }
    return state || DEFAULT_STATE;
};

type Props = RouterState & RouteComponentProps<any>;

class WithRouter extends React.Component<Props> {
    componentDidUpdate(prevProps: Readonly<Props>) {
        const { token, path, history, replace } = this.props;
        if (token !== prevProps.token) {
            replace ? history.replace(path) : history.push(path);
        }
    }
    render() {
        return null;
    }
}

export const RouteHandler = withRouter(connect(withKey<RouterState>('routerReducer'))(WithRouter));
