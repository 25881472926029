import * as React from 'react';
import { Event } from '../../../../types/EventTypes';
import PracticeRoundSettingsDialog from './PracticeRoundSettingsDialog';
import LabeledField from '../../../../common/form/LabeledField';
import { ListElem } from '../../../../common/Misc';

interface Props {
    event: Event;
}

interface State {
    dialogOpened?: boolean;
}

const PRACTICE_ELEM = 'practice_select_elem';

class PracticeRoundField extends React.Component<Props, State> {
    state: State = {};

    private handleOpen = () => this.setState({ dialogOpened: true });
    private handleClose = () => this.setState({ dialogOpened: false });

    render() {
        const { event } = this.props;
        const { dialogOpened } = this.state;
        const title = event.practiceRoundInfo || 'No';
        return <>
            <LabeledField
                label="Practice round"
                edit={this.handleOpen}
                value={(<ListElem id={PRACTICE_ELEM} title={title} subtitle=" " />)}
            />
            {dialogOpened && <PracticeRoundSettingsDialog
                open
                event={event}
                onClose={this.handleClose}
            />}
        </>;
    }
}

export default PracticeRoundField;
