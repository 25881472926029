import axios from 'axios';
import * as Backend from '../util/firebase';
import { Urls, Emails } from '../util/config';
import { showError, showProgress } from '../redux/ReduxConfig';
import { compareTee, combineTees } from '../scoring/handicap';
import { Course, SimpleCourse, CompoundCourse, Facility, CourseResponse, Tee, Tees, CourseTee, CourseTees, HolesType, isCompoundCourse, getCourseName, HOLES_9, HOLES_18 } from '../types/EventTypes';

export function iCourse(course: Course | undefined, checkHasBoth?: boolean) {
    return course && isCompoundCourse(course) && (!checkHasBoth || course.inCourse?.id !== course.outCourse?.id) && course.inCourse?.id ? course.inCourse : undefined;
}

export function oCourse(course: Course | undefined, checkHasBoth?: boolean) {
    return course && isCompoundCourse(course) && (!checkHasBoth || course.inCourse?.id !== course.outCourse?.id) && course.outCourse?.id ? course.outCourse : undefined;
}

export function teeCourse(tee: Tee, course: Course): SimpleCourse | undefined {
    if (isCompoundCourse(course)) {
        if (tee.facilityId === course.outCourse?.id) {
            return course.outCourse;
        }
        if (tee.facilityId === course.inCourse?.id) {
            return course.inCourse;
        }
        return undefined;
    } else {
        return course;
    }
}

export function getHolesType(selectedFacility: Facility, tees: Tees): HolesType {
    return selectedFacility.courses.length === 1 && tees.length > 0 && tees[0].par.length === 9 ? HOLES_9 : HOLES_18;
}

export async function handleFacilityMissingTees(selectedFacility: Facility, course: Course) {
    if (Backend.firebaseAuth.currentUser) {
        const uid = Backend.firebaseAuth.currentUser.uid;
        const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
        const replyTo = Emails.supportEmail;
        const msg = {
            token,
            individual: false,
            mailData: {
                to: replyTo,
                from: replyTo,
                replyTo: replyTo,
                subject: `[EVENTS] Missing tees for '${getCourseName(course)}'`,
                text: `Missing tees for course id: ${selectedFacility.id},<br/> Name: ${selectedFacility.name},<br/> Courses: ${selectedFacility.courses.map(c => c.id + '--' + c.name).join(', ')},<br/> uid: ${uid}`
            }
        };
        const hideProgress = showProgress();
        try {
            await axios.post(Urls.sendMail, msg);
            hideProgress();
        } catch (err) {
            showError(`Failed to send e-mail to support (${err})`);
        } finally {
            hideProgress();
        }
    }
}

export function getFacilityName(facility?: Facility, outCourse?: SimpleCourse, inCourse?: SimpleCourse) {
    if (!facility || !facility.courses) {
        return '';
    }
    if (facility.courses.length > 1) {
        if (outCourse && outCourse.id && inCourse && inCourse.id) {
            return `${facility.name} (${outCourse.name}, ${inCourse.name})`;
        }
        if (outCourse && outCourse.id) {
            return `${facility.name} (${outCourse.name})`;
        }
        if (inCourse && inCourse.id) {
            return `${facility.name} (${inCourse.name})`;
        }
    }
    return facility.name;
}

export function getCourse(selectedFacility?: Facility, selectedIn?: SimpleCourse, selectedOut?: SimpleCourse, holesType?: HolesType): Course | undefined {
    if (selectedFacility && selectedFacility.courses) {
        if (selectedFacility.courses.length === 1) {
            return {
                id: selectedFacility.courses[0].id,
                name: selectedFacility.name
            } as SimpleCourse;
        } else {
            if (holesType === HOLES_9) {
                return {
                    facilityId: selectedFacility.id,
                    facilityName: selectedFacility.name,
                    outCourse: { id: '', name: '' },
                    inCourse: selectedIn ?? selectedFacility.courses[0],
                    courses: selectedFacility.courses
                } as CompoundCourse;
            } else {
                return {
                    facilityId: selectedFacility.id,
                    facilityName: selectedFacility.name,
                    outCourse: selectedOut ?? selectedFacility.courses[0],
                    inCourse: selectedIn ?? selectedFacility.courses[0],
                    courses: selectedFacility.courses
                } as CompoundCourse;
            }
        }
    }
    return undefined;
}

class CourseTeeImpl implements CourseTee {
    private readonly userTee?: Tee;
    private readonly mainTee?: Tee;
    constructor(facility: SimpleCourse, userTee?: Tee, mainTee?: Tee) {
        if (userTee) {
            userTee.facilityId = facility.id;
            userTee.facilityName = facility.name;
        }
        if (mainTee) {
            mainTee.facilityId = facility.id;
            mainTee.facilityName = facility.name;
        }
        this.userTee = userTee;
        this.mainTee = mainTee;
    }
    isDeleted = (): boolean => {
        return Boolean(this.userTee?.deleted);
    }
    isEdited = (): boolean => {
        return Boolean(this.userTee?.edited);
    }
    getId = () => this.getTee().id;
    getTee = (): Tee => {
        const { userTee, mainTee } = this;
        if (userTee && (userTee.edited || userTee.deleted || !mainTee)) {
            return userTee;
        } else {
            return mainTee!;
        }
    }
    getUserTee = () => this.userTee;
    getMainTee = () => this.mainTee;
}

export async function loadFacilityCourseTees(userId: string, facility: SimpleCourse, controller?: AbortController): Promise<CourseTees> {
    try {
        const userTeesSnapshot = await Backend.getDocument(Backend.coursesDb(userId), facility.id);
        const { data: mainTees }: { data: Tees } = await axios.post(Urls.listTees, [facility.id], { signal: controller?.signal });
        const userTees: Tees = userTeesSnapshot?.data()?.tees as Tees ?? [];
        const userCourseTees = userTees.map(userTee => new CourseTeeImpl(facility, userTee, mainTees.find(mainTee => mainTee.id === userTee.id)));
        const userMainTees = mainTees.filter(mainTee => !userTees.find(userTee => mainTee.id === userTee.id))
            .map(mainTee => new CourseTeeImpl(facility, undefined, mainTee));
        const courseTees = userCourseTees.concat(userMainTees);
        return courseTees;
    } catch (err) {
        return Promise.reject(`Failed to load tees for ${facility.id} - ${facility.name}, ${err}`);
    }
}

export async function loadFacilityTees(userId: string, facility: SimpleCourse, includeDeleted: boolean, controller?: AbortController): Promise<Tees> {
    const courseTees = await loadFacilityCourseTees(userId, facility, controller);
    const tees = courseTees
        .filter(courseTee => !courseTee.isDeleted() || !includeDeleted)
        .map(courseTee => courseTee.getTee())
        .sort(compareTee);
    return tees;
}

export async function loadFacilityTeesOld(userId: string, facility: SimpleCourse, includeDeleted: boolean, controller?: AbortController): Promise<Tees> {
    try {
        const userTeesSnapshot = await Backend.getDocument(Backend.coursesDb(userId), facility.id);
        const { data: mainTees }: { data: Tees } = await axios.post(Urls.listTees, [facility.id], { signal: controller?.signal });
        let tees: Tees;
        const userTeesData = userTeesSnapshot.data();
        if (userTeesSnapshot.exists() && userTeesData) {
            const userTees = userTeesData.tees as Tees;
            const userTeeToInclude = (userTee: Tee) => userTee.edited || userTee.deleted || !mainTees.find(mainTee => mainTee.id === userTee.id);
            const mainTeeToExclude = (userTee?: Tee) => userTee?.edited || userTee?.deleted;
            const mainTeeToInclude = (mainTee: Tee) => !mainTeeToExclude(userTees.find(userTee => userTee.id === mainTee.id));
            tees = userTees.filter(userTeeToInclude).concat(mainTees.filter(mainTeeToInclude));
            if (!includeDeleted) {
                tees = tees.filter(tee => !tee.deleted);
            }
        } else {
            tees = mainTees;
        }
        tees.forEach(tee => {
            tee.facilityId = facility.id;
            tee.facilityName = facility.name;
        });
        return tees.sort(compareTee);
    } catch (err) {
        return Promise.reject(`Failed to load tees for ${facility.id} - ${facility.name}, ${err}`);
    }
}

export async function loadCourseTees(userId: string, course: Course, includeDeleted: boolean, combineCompound: boolean, notCombinedTees?: Tee[], controller?: AbortController): Promise<Tees> {
    if (!course) {
        return Promise.reject('Cannot load tees: no course specified');
    }
    const teesArray: Array<Tees> = [];
    if (isCompoundCourse(course)) {
        if (course.outCourse?.id) {
            teesArray.push(await loadFacilityTees(userId, course.outCourse, includeDeleted, controller));
        }
        if (course.inCourse?.id && course.inCourse?.id !== course.outCourse?.id) {
            teesArray.push(await loadFacilityTees(userId, course.inCourse, includeDeleted, controller));
        }
    } else {
        teesArray.push(await loadFacilityTees(userId, course, includeDeleted, controller));
    }
    if (teesArray.length === 0) {
        return Promise.reject(`Cannot load tees for course: ${course}`);
    }
    let tees: Tees;
    if (teesArray.length === 2) {
        const teesRes = combineCompound ? combineTees(teesArray[0], teesArray[1]) : teesArray[0].concat(teesArray[1]);
        if (notCombinedTees) {
            notCombinedTees.push(...teesArray[0].concat(teesArray[1]).sort(compareTee));
        }
        tees = teesRes.sort(compareTee);
    } else {
        if (combineCompound && isCompoundCourse(course) && course.inCourse?.id && course.inCourse.id === course.outCourse?.id) {
            tees = combineTees(teesArray[0], teesArray[0]).sort(compareTee);
        } else {
            tees = teesArray[0].sort(compareTee);
        }
        if (notCombinedTees) {
            notCombinedTees.push(...teesArray[0].sort(compareTee));
        }
    }
    return tees;
}

export const toStringPosition = (position?: GeolocationPosition) => `position [lat: ${position?.coords.latitude}, lon: ${position?.coords.longitude}]`;

export async function getFacilitiesForLocation(withTees: boolean, position?: GeolocationPosition, controller?: AbortController): Promise<CourseResponse> {
    const signal = controller?.signal;
    if (!position || signal?.aborted) {
        return Promise.reject();
    }
    try {
        const { data: courseResponse }: { data: CourseResponse } = await axios.get(Urls.listCourses, {
            signal,
            params: {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
                withTees
            }
        });
        if (signal?.aborted) {
            return Promise.reject();
        }
        return courseResponse;
    } catch (err) {
        return Promise.reject(`Failed to load course facilities for ${toStringPosition(position)}, ${err instanceof Error ? err.message : err}`);
    }
}

export async function getFacilitiesForAddress(address: string, withTees: boolean, controller?: AbortController): Promise<CourseResponse> {
    const signal = controller?.signal;
    if (signal?.aborted) {
        return Promise.reject();
    }
    try {
        const { data: courseResponse }: { data: CourseResponse } = await axios.get(Urls.listCourses, {
            signal,
            params: { address, withTees }
        });
        if (signal?.aborted) {
            return Promise.reject();
        }
        return courseResponse;
    } catch (err) {
        return Promise.reject(`Failed to load course facilities for '${address}', ${err instanceof Error ? err.message : err}`);
    }
}
