import * as React from "react";
import { XSMobileDialog } from "../../common/dialog/MobileDialog";
import { Box, DialogContent, useMediaQuery, Typography } from "@mui/material";
import {
    Event, Contact, PaymentStatus
} from '../../types/EventTypes';
import { AppColors, theme } from "../../main/Theme";
import DialogAppBar from "../../common/dialog/DialogAppBar";
import CurrencyList from "currency-list";
import { shortSurname } from "../../contact/Contact";
import { PayPalPayment } from "./PayPalPayment";

type PayPalPaymentDialogProps = {
    event: Event;
    contact: Contact;
    handlePaymentStatusChanged: (status: PaymentStatus, paymentDocId?: string) => void;
    handleClose: () => void;
};

export const PayPalPaymentDialog = (props: PayPalPaymentDialogProps) => {
    const { event, contact, handlePaymentStatusChanged, handleClose } = props;
    if (!event.paymentSettings?.enabled) {
        return null;
    }
    const { paymentSettings } = event;
    const currencySymbol = React.useMemo(
        () => paymentSettings ? CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '' : '',
        [paymentSettings?.currencyCode]
    );
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <XSMobileDialog open={true} PaperProps={{
            style: { maxWidth: isXs ? 360 : 443, margin: isXs ? 28 : 'unset', maxHeight: isXs ? 520 : 800 }
        }} maxWidth={'xs'} fullWidth={true}>
            <DialogAppBar label="Payment of entry fee" close={handleClose} />
            <DialogContent style={{ padding: '20px 16px 8px' }}>
                <Box display="flex" flexDirection="column" fontFamily="poppins, sans-serif" fontSize="14px" lineHeight="21px">
                    <Typography style={{ fontWeight: 500, fontSize: 20, lineHeight: '30px', color: AppColors.webBlack }}>
                        {event.name}
                    </Typography>
                    <Box height={8} />
                    <Typography style={{ fontSize: 16, lineHeight: '24px', color: AppColors.webBlack }}>
                        {`${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${paymentSettings.feeCost} entry fee`}
                    </Typography>
                    {paymentSettings.feeDescription && <>
                        <Typography style={{ color: AppColors.webGrey }}>{paymentSettings.feeDescription}</Typography>
                    </>}
                    <Box height={12} />
                    <Box style={{ backgroundColor: AppColors.webGreyLight }} borderRadius={2} display="flex"
                         padding={2} flexDirection="column">
                        <Typography style={{ fontSize: 16, fontWeight: 500, lineHeight: '23.2px' }}>
                            {shortSurname(contact)}, registered
                        </Typography>
                        <Box height={4} />
                        <Typography>Email: {contact.email ?? 'Not set'}</Typography>
                        <Box height={4} />
                        <Typography>Handicap index: {contact.handicapIndex ?? 0}</Typography>
                    </Box>
                    <Box height={12} />
                    <PayPalPayment
                        style={{ padding: '12px 0px' }}
                        feeCost={paymentSettings.feeCost}
                        payeeEmailAddress={paymentSettings.payeeEmailAddress}
                        currencyCode={paymentSettings.currencyCode}
                        event={event}
                        eventId={event.id}
                        contact={contact}
                        skipPaymentAvailable={false}
                        onPaymentSuccess={(withException, paymentDocId) => handlePaymentStatusChanged(
                            withException ? PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION :
                                PaymentStatus.SUCCESSFUL_PAYMENT, paymentDocId
                        )}
                        onPaymentFailure={() => handlePaymentStatusChanged(PaymentStatus.PAYMENT_FAILURE)}
                        onRegisterWithoutPayment={() => handlePaymentStatusChanged(PaymentStatus.PAY_LATER)} />
                </Box>
            </DialogContent>
        </XSMobileDialog>
    );
}
