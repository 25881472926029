export interface CancelablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}

export interface Cancelable {
    canceled: boolean;
}

export const makeCancelable = function <T>(promise: Promise<T>): CancelablePromise<T> {
    let cancelled = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise.then(
            val => cancelled ? reject({ canceled: true }) : resolve(val),
            error => cancelled ? reject({ canceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            cancelled = true;
        }
    };
};
