import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import * as React from 'react';

export const PracticeRoundPage = ({ practiceRound, setPracticeRound, practiceRoundInfo, setPracticeRoundInfo, autoFocus }: {
    autoFocus?: boolean,
    practiceRound: boolean, setPracticeRound: (a: boolean) => void,
    practiceRoundInfo: string, setPracticeRoundInfo: (s: string) => void
}) => {
    return <Grid container>
        <Grid item xs={12} sm={4}>
            <FormControlLabel
                style={{ marginLeft: 4, marginBottom: 20 }}
                label="Practice round"
                control={<Checkbox
                    color="secondary"
                    checked={practiceRound}
                    sx={{ alignSelf: 'start' }}
                    onChange={(e, v) => { e.stopPropagation(); setPracticeRound(v); }}
                />}
            />
        </Grid>
        <Grid item xs={12} sm={8}>
            {practiceRound && <TextField
                id="name"
                variant="standard"
                autoFocus={autoFocus}
                label="Info for the participants"
                value={practiceRoundInfo ?? ''}
                placeholder="Date, time, course contacts, etc"
                onChange={e => setPracticeRoundInfo(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%' }}
            />}
        </Grid>
    </Grid>
}
