import * as React from 'react';
import { Tabs, Tab, Badge, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { everybodyIsPairedEventOrRounds, everybodyIsScheduledEventOrRounds, golfersAvailableEventOrRounds } from '../../Event';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { EventData } from '../../../types/EventTypes';
import { pushUrl } from '../../../redux/ReduxConfig';
import GolfersList from './list/GolfersList';
import InvitesList from './list/InvitesList';
import TeamsList from './list/TeamsList';
import ScheduleList from './pairings/ScheduleList';
import { AppColors } from "../../../main/Theme";
import { withS } from 'src/util/utility';

type SelectedTab = 'list' | 'teams' | 'schedule' | 'invites';

interface Properties {
    url: string;
    eventData: EventData;
    initial?: string;
    onSelectedTab: (value: string) => void;
}

const golfersTabTheme = createTheme({
    palette: {
        primary: { main: '#4595c8', contrastText: "#fff" },
        secondary: { main: '#005b88', contrastText: "#fff" }
    },
    components: {
        MuiBadge: {
            styleOverrides: {
                colorError: {
                    backgroundColor: AppColors.webWarning
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: AppColors.webGrey100,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        background: AppColors.webGrey100
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                caption: {
                    fontFamily: 'Poppins',
                    fontWeight: 600
                },
                subtitle1: {
                    fontFamily: 'Poppins',
                    fontWeight: 300,
                    fontSize: 12,
                    lineHeight: '18px',
                    color: AppColors.webGrey,
                    textTransform: 'lowercase'
                }
            }
        },
    }
});

function GolfersTab(props: Properties & WithStyles<typeof styles>) {
    const { classes, initial, url, eventData } = props;
    const golfersSubLabel = withS(Array.from(eventData.golfersAggregated.values()).filter(c => !c.hidden).length, 'golfer');
    const { allTeamsPaired, teamsCount } = everybodyIsPairedEventOrRounds(eventData);
    const { allScheduled, groupsCount } = everybodyIsScheduledEventOrRounds(eventData);
    const teamsSubLabel = eventData.event.teamSize > 1 ? teamsCount > 0 ? withS(teamsCount, 'team') : 'not paired' : 'no teams';
    const groupsSubLabel = groupsCount > 0 ? withS(groupsCount, 'group') : 'not set';
    const invitesSubLabel = `${Array.from(eventData.invitedContacts.values()).length} invited`;
    const golfersTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={golfersAvailableEventOrRounds(eventData)}><Typography variant="caption">LIST &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{golfersSubLabel}</Typography>
        </Box>
    </>;
    const teamsTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={allTeamsPaired}><Typography variant="caption">TEAMS &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{teamsSubLabel}</Typography>
        </Box>
    </>;
    const groupsTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={allScheduled && groupsCount > 0}><Typography variant="caption">SCHEDULE &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{groupsSubLabel}</Typography>
        </Box>
    </>;
    const invitesTabLabel = <>
        <Box>
            <Typography variant="caption">INVITES</Typography>
            <Typography variant="subtitle1">{invitesSubLabel}</Typography>
        </Box>
    </>;
    const className = (classes.tabRoot) + ' ' + (classes.stickyTop);
    const changeTab = (_: React.ChangeEvent<{}>, value: SelectedTab) => {
        props.onSelectedTab(value);
        pushUrl(`${url}/golfers/${value}`);
    }
    return <>
        <Box className={className} sx={{ background: 'white' }}>
            <ThemeProvider theme={golfersTabTheme}>
                <Tabs value={initial ?? 'list'} onChange={changeTab} textColor="secondary"
                    variant="scrollable" scrollButtons="auto" >
                    <Tab label={golfersTabLabel} value="list" />
                    <Tab label={teamsTabLabel} value="teams" />
                    <Tab label={groupsTabLabel} value="schedule" />
                    <Tab label={invitesTabLabel} value="invites" />
                </Tabs>
            </ThemeProvider>
            {initial === "list" && <GolfersList eventData={eventData} />}
            {initial === "schedule" && <ScheduleList eventData={eventData} />}
            {initial === "teams" && <TeamsList eventData={eventData} />}
            {initial === "invites" && <InvitesList eventData={eventData} />}
        </Box>
    </>
}

export default withStyles(styles)(GolfersTab);
