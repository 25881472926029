import { Alert, Collapse } from '@mui/material';
import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import RichTextView from 'src/common/form/richtext/RichTextView';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { AppColors } from './Theme';
import { Announcement } from 'src/types/EventTypes';

type Props = {
    announcement?: Announcement;
    cookies?: any;
} & ReactCookieProps;

class AnnouncementAlert extends React.Component<Props> {

    public show = () => this.props.cookies?.remove('announcementShown');

    render() {
        const { announcement, cookies } = this.props;
        const closeAnnouncement = () => {
            cookies?.set('announcementShown', announcement?.timestamp, { path: '/' });
        }
        const announcementShown = parseInt(cookies?.get('announcementShown'));
        return (
            <TransitionGroup>
                {(announcement?.text && announcementShown != announcement?.timestamp) &&
                    <Collapse>
                        <Alert
                            icon={false}
                            onClose={closeAnnouncement}
                            sx={{ color: AppColors.white, backgroundColor: AppColors.webSuccess }}>
                            <RichTextView value={announcement.text} style={{ minHeight: 0, padding: 0, width: '100%' }} />
                        </Alert>
                    </Collapse>}
            </TransitionGroup>
        );
    }
}

export default withCookies(AnnouncementAlert);
