import { withStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { AppColors } from "../../main/Theme";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { BasePlacement, VariationPlacement } from "@popperjs/core/lib/enums";
import * as React from "react";
import { InfoIcon } from "../Icons";

export const InfoTooltip = withStyles((_: Theme) => {
    return {
        tooltip: {
            backgroundColor: '#E2ECF5',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '18px',
            color: AppColors.webBlack,
            borderRadius: 4
        }
    };
})(Tooltip);

type InfoToolTipProps = {
    placement: BasePlacement | VariationPlacement;
    title: React.ReactNode;
    color?: string;
    disabled?: boolean;
    offset1?: number;
    offset2?: number;
    style?: React.CSSProperties;
    leaveTouchDelay?: number;
};

export const InfoIconTooltip = ({ placement, title, color, disabled, offset2, offset1, style, leaveTouchDelay }: InfoToolTipProps) => {
    const popperProps = {
        modifiers: [{
            name: "offset",
            options: {
                offset: [offset2 ?? 0, offset1 ?? 0]
            },
        }]
    };
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [showTooltip, setShowTooltip] = React.useState(false);
    return (<InfoTooltip PopperProps={popperProps} color={color} disableInteractive open={showTooltip}
        onOpen={() => setShowTooltip(true)} onClose={() => setShowTooltip(false)}
        placement={isXs ? 'bottom' : placement} title={disabled ? '' : title}
        leaveTouchDelay={leaveTouchDelay ?? 3000} leaveDelay={2000}>
        <span style={style}>
            <InfoIcon sx={disabled ? undefined : { ':hover': { fill: '#E2ECF5' } }}
                onClick={e => { e.stopPropagation(); setShowTooltip(!showTooltip); }}
                style={{ verticalAlign: 'text-top', pointerEvents: 'stroke' }}
                color={disabled ? 'inherit' : 'secondary'} />
        </span>
    </InfoTooltip>);
};