import * as React from 'react';
import { Typography, TextField, DialogContent, DialogActions, MenuItem, Menu } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { EventBase, Contact, Competition, Units, DistanceValue, Distance, MAX_HOLES } from '../../../types/EventTypes';
import { isFoot, updateGolferDistance } from '../../Event';
import { FirebaseUserPubDataComponent } from '../../../common/WithData';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import { fullName } from '../../../contact/Contact';
import SelectGolfersDialog from '../../tabs/common/SelectGolfersDialog';
import * as Backend from '../../../util/firebase';
import * as Utils from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import { styles } from '../../../styles';
import { withProgress } from 'src/util/ProgressPromise';

const SELECT_UNITS = ['METERS', 'FEET AND INCHES'];

type Props = {
    competition: Competition;
    hole: number;
    eventOrRound: EventBase;
    open: boolean;
    close: () => void;
    distance?: DistanceValue;
    initialGolfer?: Contact;
    golfers: Map<string, Contact>;
    distances: Map<string, Distance>;
} & WithStyles<typeof styles>;

type State = {
    selectedGolfer?: Contact;
    units?: Units;
    distanceMeters: string;
    distanceFt: string;
    distanceIn: string;
    changeGolfer?: boolean;
    loading: boolean;
    anchorSelectUnits?: any;
};

const INCHES_FACTOR = 0.0254;

export function distanceToFeet(distance?: DistanceValue) {
    if (!distance) {
        return { ft: '', in: '' };
    }
    const inches = distance < 0 ? -distance : distance / INCHES_FACTOR;
    const feet = Math.floor(inches / 12);
    return { ft: '' + feet, in: '' + Utils.round(inches - 12 * feet, 1) };
}

export function distanceToMeters(distance?: DistanceValue) {
    if (!distance) {
        return '';
    }
    const meters = distance < 0 ? -distance * INCHES_FACTOR : distance;
    return '' + Utils.round(meters, 2);
}

export function metersToFeet(meters: string) {
    if (!meters) {
        return { ft: '', in: '' };
    }
    const inches = parseFloat(meters.trim()) / INCHES_FACTOR;
    const feet = Math.floor(inches / 12);
    return { ft: '' + feet, in: '' + Utils.round(inches - 12 * feet, 1) };
}

export function feetToMeters(ft: string, inches: string) {
    if (!ft && !inches) {
        return '';
    }
    if (!ft) {
        ft = '0';
    }
    if (!inches) {
        inches = '0';
    }
    const meters = (12 * parseFloat(ft.trim()) + parseFloat(inches.trim())) * INCHES_FACTOR;
    return '' + Utils.round(meters, 2);
}

export function getInches(ft: string, inches: string) {
    if (!ft && !inches) {
        return 0;
    }
    if (!ft) {
        ft = '0';
    }
    if (!inches) {
        inches = '0';
    }
    return 12 * parseFloat(ft.trim()) + parseFloat(inches.trim());
}

class AddResultDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const meters = distanceToMeters(props.distance);
        const fi = distanceToFeet(props.distance);
        this.state = {
            loading: true,
            selectedGolfer: props.initialGolfer,
            distanceMeters: meters,
            distanceFt: fi.ft,
            distanceIn: fi.in
        };
    }

    private handleSelectGolfer = (contacts: Array<Contact>) => {
        this.setState({ selectedGolfer: contacts.length > 0 ? contacts[0] : undefined, changeGolfer: undefined });
    }

    private handleSelectUnits = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({ anchorSelectUnits: e.currentTarget });
    }

    private handleUnitsSelected = (selectedUnits?: string) => {
        if (selectedUnits) {
            const units: Units = selectedUnits === SELECT_UNITS[0] ? 'meters' : 'inches';
            this.setState({ anchorSelectUnits: undefined }, () => Backend.setUserPubData({ units }));
        } else {
            this.setState({ anchorSelectUnits: undefined });
        }
    }

    private adjustValues = (oldUnits?: Units, newUnits?: Units) => {
        if (isFoot(oldUnits) !== isFoot(newUnits)) {
            if (isFoot(newUnits)) {
                const { distanceMeters } = this.state;
                const fi = metersToFeet(distanceMeters);
                this.setState({ distanceFt: fi.ft, distanceIn: fi.in });
            } else {
                const { distanceFt, distanceIn } = this.state;
                const distanceMeters = feetToMeters(distanceFt, distanceIn);
                this.setState({ distanceMeters });
            }
        }
    }

    private onUnits = (newUnits?: Units) => {
        const { units, loading } = this.state;
        if (loading) {
            this.setState({ units: newUnits, loading: false });
        } else {
            this.setState({ units: newUnits, loading: false }, () => this.adjustValues(units, newUnits));
        }
    }

    private handleDelete = () => {
        const { eventOrRound, competition, distances, hole, close } = this.props;
        const golferDistance = distances.get(competition.id) || { id: competition.id, lengths: Utils.array(MAX_HOLES, 0) } as Distance;
        if (!golferDistance.winners) {
            golferDistance.winners = Utils.array(MAX_HOLES, '');
        }
        golferDistance.lengths[hole] = 0;
        golferDistance.winners[hole] = '';
        Backend.updateOrAddBatch(Backend.golferDistancesDb(eventOrRound.id), [golferDistance], true).then(() => close());
    }

    private handleSave = () => {
        const { eventOrRound, competition, distances, hole, close } = this.props;
        const { selectedGolfer, distanceMeters, distanceFt, distanceIn, units } = this.state;
        if (!selectedGolfer) {
            return;
        }
        const lengthValue = isFoot(units) ? -getInches(distanceFt, distanceIn) : parseFloat(distanceMeters.trim());
        withProgress(updateGolferDistance(eventOrRound, competition, distances, hole, lengthValue, selectedGolfer))
            .then(() => close());

    }

    private MetersInput = () => {
        const { classes } = this.props;
        const { distanceMeters } = this.state;
        return <>
            <TextField
                autoFocus
                error={false}
                label="meters"
                margin="normal"
                variant="outlined"
                helperText=""
                value={distanceMeters}
                InputLabelProps={{ shrink: true }}
                className={classes.textFieldCompact}
                onChange={e => this.setState({ distanceMeters: e.target.value })}
            />
        </>;
    }

    private FtInput = () => {
        const { classes } = this.props;
        const { distanceFt, distanceIn } = this.state;
        return <>
            <TextField
                autoFocus
                error={false}
                label="ft"
                margin="normal"
                variant="outlined"
                helperText=""
                value={distanceFt}
                InputLabelProps={{ shrink: true }}
                className={classes.textFieldCompact}
                onChange={e => this.setState({ distanceFt: e.target.value })}
            />
            <TextField
                error={false}
                label="in"
                margin="normal"
                variant="outlined"
                helperText=""
                value={distanceIn}
                InputLabelProps={{ shrink: true }}
                className={classes.textFieldCompact}
                onChange={e => this.setState({ distanceIn: e.target.value })}
            />
        </>;
    }

    render = () => {
        const { eventOrRound, competition, golfers, open, close, initialGolfer, hole, classes } = this.props;
        const { loading, selectedGolfer, changeGolfer, anchorSelectUnits, units } = this.state;
        const title = !!initialGolfer ? 'Edit result' + (competition.roundOrder ? ` - Round ${competition.roundOrder}` : '') : 'Add result';
        const user = selectedGolfer ? fullName(selectedGolfer) : (<span className={classes.notSelected}>Not selected</span>);
        const disabledSave = !selectedGolfer;
        const unitsFt = isFoot(units);
        const distanceTitle = <Typography>Distance (optional), <a href="/" className={classes.linkBlue} onClick={this.handleSelectUnits}>{unitsFt ? 'feet and inches' : 'meters'}</a></Typography>;
        return (
            <XSMobileDialog open={open} onClose={close}>
                <DialogAppBar label={title} close={close} />
                <DialogContent onKeyDown={e => processEnterKey(e, this.handleSave, disabledSave)}>
                    <Typography variant="subtitle2" className={classes.subhead}>Closest to the pin, hole {hole + 1}</Typography>
                    <DialogActions style={{ justifyContent: 'flex-start' }}>
                        <Typography variant="h6">{user}</Typography>
                        <AppButton onClick={() => this.setState({ changeGolfer: true })} color="info">Change</AppButton>
                    </DialogActions>
                    {!loading && distanceTitle}
                    {!loading && (unitsFt ? this.FtInput() : this.MetersInput())}
                </DialogContent>
                <DialogActions>
                    {!!selectedGolfer && <AppButton onClick={this.handleDelete} color="info">Delete</AppButton>}
                    <AppButton onClick={close} color="info">Cancel</AppButton>
                    <AppButton onClick={this.handleSave} color="secondary" disabled={disabledSave}>Save</AppButton>
                </DialogActions>
                {!!anchorSelectUnits &&
                    <Menu anchorEl={anchorSelectUnits} open={true}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        onClose={() => this.handleUnitsSelected(undefined)}>
                        {SELECT_UNITS.map(a => <MenuItem key={a} onClick={_ => this.handleUnitsSelected(a)}>{a}</MenuItem>)}
                    </Menu>}
                {!!changeGolfer && <SelectGolfersDialog
                    competition={competition}
                    golfers={golfers}
                    label={'Select golfer'}
                    selectMode={'single'}
                    handleSelect={this.handleSelectGolfer}
                    handleCancel={() => this.setState({ changeGolfer: undefined })}
                    selectedGolferIds={selectedGolfer ? [selectedGolfer.id] : []} />}
                <FirebaseUserPubDataComponent uid={eventOrRound.userId} onData={data => this.onUnits(data.units)} />
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(AddResultDialog);
