import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { gap } from "../../styles";
import { AppColors } from 'src/main/Theme';

export function fixedWidthChip(width: number) {
    return (
        withStyles((theme: Theme) => ({
            root: {
                margin: theme.spacing(0.5),
                background: 'white',
                border: '1px solid gray',
                '&:hover, &:focus': {
                    backgroundColor: AppColors.webBlue100
                }
            },
            /* Styles applied to the root element if `color="secondary"`. */
            colorSecondary: {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.getContrastText(theme.palette.primary.light),
            },
            clickableColorSecondary: {
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.getContrastText(theme.palette.primary.light),
                }
            },
            deletableColorSecondary: {
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.getContrastText(theme.palette.primary.light),
                }
            },
            label: {
                width: gap(theme, 1) * width,
                maxWidth: gap(theme, 1) * width,
                minWidth: gap(theme, 1) * width,
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            '@media (max-width: 560px)': {
                label: {
                    width: gap(theme, 1) * (Math.min(width, 28)),
                    maxWidth: gap(theme, 1) * (Math.min(width, 28)),
                    minWidth: gap(theme, 1) * (Math.min(width, 28)),
                },
            },
            '@media (max-width: 420px)': {
                label: {
                    width: gap(theme, 1) * (Math.min(width, 22)),
                    maxWidth: gap(theme, 1) * (Math.min(width, 22)),
                    minWidth: gap(theme, 1) * (Math.min(width, 22)),
                },
            },
            '@media (max-width: 380px)': {
                label: {
                    width: gap(theme, 1) * (Math.min(width, 14)),
                    maxWidth: gap(theme, 1) * (Math.min(width, 14)),
                    minWidth: gap(theme, 1) * (Math.min(width, 14)),
                },
            },
        }))(Chip)
    );
}

export const FixedWidthChip = fixedWidthChip(20);
export const FixedWidthChipLong = fixedWidthChip(28);
export const FixedWidthChipLonger = fixedWidthChip(36);
export const FixedWidthChipLongest = fixedWidthChip(44);
