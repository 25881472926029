import {
    Event, Contact, TeeTime, TeeTimeSettings, Team, GolferGroup, HolesRange, groupsPerTime, getStartingHoles,
    FlightsNamingMode, getHolesRange, EventBase
} from '../types/EventTypes';
import { charA, msToMin, TIME_FORMATTER, getTimeZoneOffsetMs } from '../util/utility';

export function teeSettingsName(event: EventBase): string {
    if (!event.teeTime) {
        return 'n/a';
    }
    const holesRange = getHolesRange(event.holesType);
    const holes = getStartingHoles(event.teeTime.startingHolesType, event.teeTime.startingHoles, holesRange, event.teeTime.mode);
    const holesInfo = event.teeTime.mode !== 'shotgun' && holes.length > 0 && holes[0] !== 0 ? ' on hole #' + (holes[0] + 1) : '';
    return (event.teeTime.mode === 'regular' ? 'Every ' + msToMin(event.teeTime.interval) + ' minutes' : 'Shotgun') +
        ', starting at ' + formatTeeTime(event.teeTime) + holesInfo;
}

export function formatTeeTime(teeTime: TeeTimeSettings, toLower?: boolean): string {
    if (!teeTime) {
        return '';
    }
    const startTime = evalStartTime(teeTime);
    const str = TIME_FORMATTER.format(startTime);
    return toLower ? str.replace(' AM', 'am').replace(' PM', 'pm') : str;
}

export function evalStartTime(teeTime: TeeTimeSettings): number {
    return teeTime.startTimeUTC ?
        teeTime.startTimeUTC - getTimeZoneOffsetMs(teeTime.startTimeUTC) :
        teeTime.startTime;
}

function getTeeTimeRegular(startTime: number, interval: number, order: number) {
    const msInM = 60000;
    const add = Math.floor(interval * order / msInM) * msInM;
    return startTime + add;
}

export function generateTeeTimes(teeTime: TeeTimeSettings, holesRange: HolesRange, count: number) {
    const res: Array<TeeTime> = [];
    for (let i = 0; i < count; ++i) {
        res.push(getTeeTime(teeTime, holesRange, i));
    }
    return res;
}

export function getTeeTimeShotgunHole(idx: number, startingHoles: Readonly<Array<number>>): number {
    const holesCount = startingHoles.length;
    idx = idx % holesCount;
    return startingHoles[idx];
}

function getTeeTimeShotgun(idx: number, startingHoles: Readonly<Array<number>>) {
    const holesCount = startingHoles.length;
    const hole = getTeeTimeShotgunHole(idx, startingHoles);
    let charGroup = Math.floor(idx / holesCount);
    let letter: string;
    if (charGroup < 26) {
        letter = String.fromCharCode(charA + charGroup);
    } else {
        charGroup -= 26;
        const charGroup1 = charGroup / 26;
        const charGroup2 = charGroup % 26;
        letter = String.fromCharCode(charA + charGroup1) + '' + String.fromCharCode(charA + charGroup2);
    }
    return `${hole + 1}${letter}`;
}

export function getTeeTime(teeTime: TeeTimeSettings, holesRange: HolesRange, order: number) {
    if (teeTime.mode === 'regular') {
        return getTeeTimeRegular(evalStartTime(teeTime), teeTime.interval, order);
    }
    if (teeTime.mode === 'shotgun') {
        const startingHoles = getStartingHoles(teeTime.startingHolesType, teeTime.startingHoles, holesRange, teeTime.mode);
        return getTeeTimeShotgun(order, startingHoles);
    }
    throw new Error('Unknown mode');
}

export interface ScheduleItem {
    order: number;
    time: TeeTime;
    golfers: Array<Contact>;
    teams: Array<Team>;
}

export function getSchedule(eventOrRound: EventBase, groups: Array<GolferGroup>, golfers: Map<string, Contact>, teams: Map<string, Team>): Array<ScheduleItem> {
    const holesRange = getHolesRange(eventOrRound.holesType);
    const groupsPerTimeNum = groupsPerTime(eventOrRound, eventOrRound.teamSize === 1 ? golfers.size : teams.size);
    const maxOrder = groups.reduce((acc, g) => Math.max(g.order, acc), 0) + 1;
    const maxCount = Math.max(groupsPerTimeNum, maxOrder);
    if (eventOrRound.teamSize === 1) {
        const schedule = groups.filter(group => group.contactIds.length > 0).map(group => {
            return {
                order: group.order,
                time: getTeeTime(eventOrRound.teeTime, holesRange, group.order),
                golfers: group.contactIds.map(id => golfers.get(id) || { id, lastName: '' } as Contact) || [],
                teams: []
            };
        });
        return schedule;
    } else {
        const schedule: Array<ScheduleItem> = [];
        for (let i = 0; i < maxCount; i++) {
            const group = groups.find(g => g.order === i);
            if (group) {
                schedule.push({
                    order: group.order,
                    time: getTeeTime(eventOrRound.teeTime, holesRange, group.order),
                    teams: group.contactIds.map(id => teams.get(id) || { id, contactIds: [], order: -1 }).filter(g => g.contactIds.length > 0) || [],
                    golfers: []
                });
            }
        }
        return schedule;
    }
}

export function getGolferGroup(event: Event, golferId: string, groups: Array<GolferGroup>, teams: Array<Team>): GolferGroup | undefined {
    if (event.teamSize === 1) {
        return groups.find(group => group.contactIds.indexOf(golferId) >= 0);
    } else {
        for (const team of teams) {
            if (team.contactIds.indexOf(golferId) >= 0) {
                return groups.find(group => group.contactIds.indexOf(team.id) >= 0);
            }
        }
        return undefined;
    }
}

export interface CartSignItem {
    time: TeeTime;
    group: GolferGroup;
    golfers: Array<Contact>;
}

export function getCartSigns(event: EventBase, groups: Array<GolferGroup>, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    const cartSigns: CartSignItem[] = [];
    const holesRange = getHolesRange(event.holesType);
    if (event.teamSize === 1) {
        groups.filter(group => group.contactIds.length > 0).forEach(group => {
            cartSigns.push({
                time: getTeeTime(event.teeTime, holesRange, group.order),
                group,
                golfers: []
            } as CartSignItem);
            group.contactIds.forEach(cid => {
                if (golfers.has(cid) && !golfers.get(cid)!.hidden) {
                    if (cartSigns.length === 0 || !cartSigns[cartSigns.length - 1] || cartSigns[cartSigns.length - 1].golfers.length > 1) {
                        cartSigns.push({
                            time: getTeeTime(event.teeTime, holesRange, group.order),
                            group,
                            golfers: [golfers.get(cid)]
                        } as CartSignItem);
                    } else {
                        cartSigns[cartSigns.length - 1].golfers.push(golfers.get(cid)!);
                    }
                }
            });
        });
    } else {
        groups.filter(group => group.contactIds.length > 0).forEach(group => {
            if (group.contactIds.findIndex(tid => teams.has(tid)) > -1) {
                cartSigns.push({
                    time: getTeeTime(event.teeTime, holesRange, group.order),
                    group,
                    golfers: []
                } as CartSignItem);
            }
            group.contactIds.forEach(tid => {
                if (teams.has(tid)) {
                    teams.get(tid)!.contactIds.forEach(cid => {
                        if (golfers.has(cid) && !golfers.get(cid)!.hidden) {
                            if (cartSigns.length === 0 || !cartSigns[cartSigns.length - 1] || cartSigns[cartSigns.length - 1].golfers.length > 1) {
                                cartSigns.push({
                                    time: getTeeTime(event.teeTime, holesRange, group.order),
                                    group,
                                    golfers: [golfers.get(cid)]
                                } as CartSignItem);
                            } else {
                                cartSigns[cartSigns.length - 1].golfers.push(golfers.get(cid)!);
                            }
                        }
                    });
                }
            });
        });
    }
    return cartSigns.filter(crt => crt?.golfers?.length);
}

export function getFlightName(flight: number, flightsNaming?: FlightsNamingMode, toUpperCase?: boolean, short?: boolean) {
    if (flight !== 0) {
        const res = (short ? '' : 'Flight\u00A0') + (flightsNaming === 'literal' ? String.fromCharCode(charA + flight - 1) : flight);
        return toUpperCase ? res.toUpperCase() : res;
    } else {
        return '';
    }
}
