import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import { Checkbox, DialogActions, DialogContent, InputLabel, Typography, FormControlLabel } from '@mui/material';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { Competition, Contact, Team, Tee, PayoutSettings, isNetMode, isGrossMode, isTeamScoring, isSkinsScoring, getFlightParticipantsCount, ResultStatus, EventBase } from '../../../types/EventTypes';
import { copyCompetition, hasMen, hasWomen } from '../../Event';
import { getTeeName, compareTee, getGender } from '../../../scoring/handicap';
import { scoringName } from '../../../scoring/scoring';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { withUserId, WithUserId } from '../../../auth/Auth';
import { styles } from '../../../styles';
import { processEnterKey } from '../../../util/react_utils';
import FormControl from '@mui/material/FormControl';
import AppButton from '../../../common/components/AppButton';
import CompetitionPayoutSettingsDialog from './CompetitionPayoutSettingsDialog';
import { Spacing, ListElem, Container, Item } from '../../../common/Misc';
import TeesProvider from '../common/TeesProvider';

const useCompetitionTees = false;
const useDefaultTees = 'Use competition\'s default tees';
const useDefaultPayouts = 'Use competition\'s default payouts';
const customizePayouts = 'Customize payouts';

type Props = {
    competition: Competition;
    event: EventBase;
    flight: number;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    handleSave: (competition: Competition) => void;
} & WithStyles<typeof styles> & DialogProps & WithUserId;

type State = {
    competition: Competition;
    tees: Array<Tee>;
    teesLoadStatus: ResultStatus;
    selectedMTee: Tee | null;
    selectedFTee: Tee | null;
    payoutSettings?: PayoutSettings | null;
    payoutMode?: string;
};


class CompetitionFlightDetailsDialog extends React.Component<Props, State> {

    private readonly teesLoader: React.RefObject<TeesProvider>;

    constructor(props: Props) {
        super(props);
        this.teesLoader = React.createRef();
        const competition = copyCompetition(this.props.competition);
        if (!competition.flights) {
            competition.flights = 2;
        }
        if (!competition.flightsNaming) {
            competition.flightsNaming = 'numerical';
        }
        while (competition.payoutsNet && competition.payoutsNet.length < props.flight) {
            competition.payoutsNet.push(null);
        }
        while (competition.payoutsGross && competition.payoutsGross.length < props.flight) {
            competition.payoutsGross.push(null);
        }
        // TODO: remove
        /*while (competition.tees!.length < (props.flight) * 2 + 1) {
            competition.tees!.push(null);
        }*/
        this.state = {
            teesLoadStatus: 'in_progress',
            selectedMTee: null,
            selectedFTee: null,
            tees: [],
            competition: competition,
            payoutSettings: undefined
        };
    }

    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>) => {
        // TODO: remove
        /*
        const { competition, flight } = this.props;
        if (tees) {
            let selectedMTee;
            let selectedFTee;
            const mTees = tees.filter(tee => getGender(tee) === 'male');
            const wTees = tees.filter(tee => getGender(tee) === 'female');
            if (competition) {
                const curTees = competition.tees;
                selectedMTee = (curTees && curTees[flight * 2]) ? mTees.find(t => t.id === curTees[flight * 2]!.id) : undefined;
                selectedFTee = (curTees && curTees[flight * 2 + 1]) ? wTees.find(t => t.id === curTees[flight * 2 + 1]!.id) : undefined;
            }
            this.setState({
                selectedMTee: selectedMTee || null,
                selectedFTee: selectedFTee || null,
            });
        }*/
        this.setState({ tees: tees || [], teesLoadStatus });
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private onSelectPayouts = (mode: string) => {
        const { flight } = this.props;
        const { competition } = this.state;
        if (mode === 'default') {
            if (competition.payoutsNet[flight]) {
                competition.payoutsNet[flight] = null;
                competition.payoutsGross[flight] = null;
            }
        } else if (mode === 'customized') {
            if (!competition.payoutsNet[flight]) {
                competition.payoutsNet[flight] = { enabled: true, purseType: 'fixed', payoutMethod: 'divided' };
            } else {
                competition.payoutsNet[flight]!.enabled = true;
            }
            if (!competition.payoutsGross[flight]) {
                competition.payoutsGross[flight] = { enabled: true, purseType: 'fixed', payoutMethod: 'divided' };
            } else {
                competition.payoutsGross[flight]!.enabled = true;
            }
        }
        this.setState({ competition });

    }


    private handleNetPayoutChange = (checked: boolean) => {
        const { flight } = this.props;
        const { competition } = this.state;
        if (checked) {
            if (!competition.payoutsNet[flight]) {
                competition.payoutsNet.push({ enabled: true, purseType: 'fixed' } as PayoutSettings);
            } else {
                competition.payoutsNet[flight]!.enabled = true;
            }
        } else {
            if (competition.payoutsNet[flight]) {
                competition.payoutsNet[flight]!.enabled = false;
            }
        }
        this.setState({ competition, payoutMode: 'net' });
    }

    private handleGrossPayoutChange = (checked: boolean) => {
        const { flight } = this.props;
        const { competition } = this.state;
        if (checked) {
            if (!competition.payoutsGross[flight]) {
                competition.payoutsGross.push({ enabled: true, purseType: 'fixed' } as PayoutSettings);
            } else {
                competition.payoutsGross[flight]!.enabled = true;
            }
        } else {
            if (competition.payoutsGross[flight]) {
                competition.payoutsGross[flight]!.enabled = false;
            }
        }
        this.setState({ competition, payoutMode: 'gross' });
    }

    private handleSave = () => {
        // TODO: remove
        const { handleSave/*, flight*/ } = this.props;
        const { competition } = this.state;
        //const { competition, selectedMTee, selectedFTee } = this.state;
        //const { competition, selectedMTee, selectedFTee } = this.state;
        /*if (!competition.tees) {
            competition.tees = [];
        }
        competition.tees![(flight) * 2] = selectedMTee;
        competition.tees![(flight) * 2 + 1] = selectedFTee;*/
        handleSave(competition);
    }

    render() {
        const { classes, event, flight, golfers, teams } = this.props;
        const { tees, competition, selectedMTee, selectedFTee, payoutSettings, payoutMode, teesLoadStatus } = this.state;
        const title = 'Flight details';
        const charA = 65;
        const mTees = tees.filter(tee => getGender(tee) === 'male').sort(compareTee);
        const wTees = tees.filter(tee => getGender(tee) === 'female').sort(compareTee);
        const men = hasMen(event, competition);
        const women = hasWomen(event, competition);
        const loading = teesLoadStatus === 'in_progress';
        const mTeesInfo = loading ? 'Loading tees...' :
            !event.course ? 'Please select course in event settings' :
                mTees.length === 0 ? 'No men tees found in the database' : '';
        const wTeesInfo = loading ? 'Loading tees...' :
            !event.course ? 'Please select course in event settings' :
                wTees.length === 0 ? 'No women tees found in the database' : '';
        const selectedMTeeId = selectedMTee ? selectedMTee.id : '';
        const selectedFTeeId = selectedFTee ? selectedFTee.id : '';
        const flightName = competition.flightsNaming === 'numerical' ? 'Flight ' + flight : 'Flight ' + String.fromCharCode(charA + flight - 1);
        const selectedPayoutsSettings = (isNetMode(competition.scoring.mode) && competition.payoutsNet && competition.payoutsNet.length > flight && competition.payoutsNet[flight])
            || (isGrossMode(competition.scoring.mode) && competition.payoutsGross && competition.payoutsGross.length > flight && competition.payoutsGross[flight])
            ? 'cutomized' : 'default';
        const payoutGrossSettings: PayoutSettings = competition.payoutsGross[flight] ? competition.payoutsGross[flight]! : { enabled: false, purseType: 'fixed' } as PayoutSettings;
        const payoutNetSettings: PayoutSettings = competition.payoutsNet[flight] ? competition.payoutsNet[flight]! : { enabled: false, purseType: 'fixed' } as PayoutSettings;
        return <>
            <XSMobileDialog open={this.props.open} onClose={this.handleClose}>
                <DialogAppBar label={title} close={this.handleClose} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleSave)} >
                    <Typography>{scoringName(competition, event.eventGender, competition.competitionGender) + ' - ' + flightName}</Typography>
                    {useCompetitionTees && <>
                        {men &&
                            <FormControl
                                variant="standard"
                                margin="dense"
                                fullWidth
                                style={{ flexDirection: 'column' }}>
                                <InputLabel shrink margin="dense" style={{ position: 'relative' }}>{men && women ? 'Men\'s tees:' : 'Tees:'}</InputLabel>
                                {!loading && <TypedFormRadioLabel key='mensDefault' currentValue={!selectedMTeeId ? useDefaultTees : selectedMTeeId} value={useDefaultTees}
                                    handleChange={() => this.setState({ selectedMTee: null })} label={useDefaultTees} className={classes.radio} />}
                                {mTees.map(tee => <TypedFormRadioLabel key={tee.id} currentValue={selectedMTeeId} value={tee.id}
                                    handleChange={() => this.setState({ selectedMTee: tee })} label={getTeeName(tee, event.holesType)} className={classes.radio} />)}
                                <Typography>{mTeesInfo}</Typography>
                                <Spacing />
                            </FormControl>}
                        {women && <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ marginTop: -15, flexDirection: 'column' }}>
                            <InputLabel shrink style={{ position: 'relative' }}>{men && women ? 'Women\'s tees:' : 'Tees:'}</InputLabel>
                            {!loading && <TypedFormRadioLabel key='womensDefault' currentValue={!selectedFTeeId ? useDefaultTees : selectedMTeeId} value={useDefaultTees}
                                handleChange={() => this.setState({ selectedFTee: null })} label={useDefaultTees} className={classes.radio} />}
                            {wTees.map(tee => <TypedFormRadioLabel key={tee.id} currentValue={selectedFTeeId} value={tee.id}
                                handleChange={() => this.setState({ selectedFTee: tee })} label={getTeeName(tee, event.holesType)} className={classes.radio} />)}
                            <Typography>{wTeesInfo}</Typography>
                            <Spacing />
                        </FormControl>}
                    </>}
                    <FormControl
                        fullWidth
                        variant="standard"
                        style={{ marginTop: 15, flexDirection: 'column' }}>
                        <InputLabel shrink style={{ position: 'relative' }}>Payouts</InputLabel>
                        {<TypedFormRadioLabel key='payoutsDefault' currentValue={selectedPayoutsSettings} value={'default'}
                            handleChange={() => this.onSelectPayouts('default')} label={useDefaultPayouts} className={classes.radio} />}
                        {competition.payoutsNet && <TypedFormRadioLabel key='payoutsCustomize' currentValue={selectedPayoutsSettings} value={'cutomized'}
                            handleChange={() => this.onSelectPayouts('customized')} label={customizePayouts} className={classes.radio} />}
                        <FormControl
                            fullWidth
                            variant="standard"
                            style={{ marginTop: -15, flexDirection: 'row' }}>
                            {selectedPayoutsSettings === 'cutomized' && isGrossMode(competition.scoring.mode) && <FormControlLabel className={classes.formSelector} control={<Container>
                                <Item><Checkbox color="secondary" onChange={(e, v) => { e.stopPropagation(); this.handleGrossPayoutChange(v); }} checked={!!payoutGrossSettings && payoutGrossSettings.enabled} /></Item>
                                <Item><ListElem title={'Gross payouts'} subtitle="" /></Item>
                                <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutGrossSettings || !payoutGrossSettings.enabled} color="info" onClick={() => this.setState({ payoutSettings: payoutGrossSettings, payoutMode: 'gross' })}>Edit</AppButton></Item>
                            </Container>} label="" />}
                            {selectedPayoutsSettings === 'cutomized' && isNetMode(competition.scoring.mode) && <FormControlLabel className={classes.formSelector} control={<Container>
                                <Item><Checkbox color="secondary" onChange={(e, v) => { e.stopPropagation(); this.handleNetPayoutChange(v); }} checked={!!payoutNetSettings && payoutNetSettings.enabled} /></Item>
                                <Item><ListElem title={'Net payouts'} subtitle="" /></Item>
                                <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutNetSettings || !payoutNetSettings.enabled} color="info" onClick={() => this.setState({ payoutSettings: payoutNetSettings, payoutMode: 'net' })}>Edit</AppButton></Item>
                            </Container>} label="" />}
                        </FormControl>
                        <Spacing />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave} disabled={!competition.flights || competition.flights === 1}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
            {!!payoutSettings && <CompetitionPayoutSettingsDialog
                open
                event={event}
                competition={competition}
                payoutSettings={payoutSettings!}
                isTeam={isTeamScoring(competition.scoring)}
                isSkins={isSkinsScoring(competition.scoring)}
                participantCount={getFlightParticipantsCount(competition, golfers, teams, flight)}
                handleSave={(payoutSettings: PayoutSettings) => {
                    const { competition } = this.state;
                    competition.payoutsNet[flight] = !payoutMode || payoutMode === 'net' ? payoutSettings : competition.payoutsNet[flight];
                    competition.payoutsGross[flight] = !payoutMode || payoutMode === 'gross' ? payoutSettings : competition.payoutsGross[flight];
                    this.setState({ competition, payoutSettings: undefined })
                }}
                onClose={() => this.setState({ payoutSettings: undefined })}
            />}
            <TeesProvider eventOrRound={event} course={event.course} onResult={this.teesLoaded} ref={this.teesLoader} />
        </>;
    }
}

export default withStyles(styles)(withUserId(CompetitionFlightDetailsDialog));
