import { BaseModule } from './BaseModule';
import Quill from 'quill';
import Attributor from 'parchment/dist/src/attributor/attributor';
import './toolbar.css';

// TODEL __eslint-disable-next-line import/no-webpack-loader-syntax
const IconAlignLeft = require('!!raw-loader!quill/assets/icons/align-left.svg').default;
// TODEL __eslint-disable-next-line import/no-webpack-loader-syntax
const IconAlignCenter = require('!!raw-loader!quill/assets/icons/align-center.svg').default;
// TODEL __eslint-disable-next-line import/no-webpack-loader-syntax
const IconAlignRight = require('!!raw-loader!quill/assets/icons/align-right.svg').default;

const toolbarStyles = {
    position: 'absolute',
    top: '-12px',
    right: '0',
    left: '0',
    height: '0',
    minWidth: '100px',
    font: '12px/1.0 Arial, Helvetica, sans-serif',
    textAlign: 'center',
    color: '#333',
    boxSizing: 'border-box',
    cursor: 'default'
};

const toolbarButtonStyles = {
    display: 'inline-block',
    width: '24px',
    height: '24px',
    background: 'white',
    border: '1px solid #999',
    verticalAlign: 'middle'
};

const toolbarButtonSvgStyles = {
    fill: '#444',
    stroke: '#444',
    strokeWidth: '2'
};

interface Alignment {
    icon: string;
    apply: () => void;
    isApplied: () => boolean;
}

export class Toolbar extends BaseModule {
    private toolbar!: HTMLDivElement;
    private floatStyle!: Attributor;
    private marginStyle!: Attributor;
    private displayStyle!: Attributor;
    private alignments: Array<Alignment> = [];

    onCreate = () => {
        const Parchment = Quill.import('parchment');
        this.floatStyle = new Parchment.Attributor.Style('float', 'float');
        this.marginStyle = new Parchment.Attributor.Style('margin', 'margin');
        this.displayStyle = new Parchment.Attributor.Style('display', 'display');

        // Setup Toolbar
        this.toolbar = document.createElement('div');
        Object.assign(this.toolbar.style, toolbarStyles);
        this.overlay.appendChild(this.toolbar);
        // Setup Buttons
        this.defineAlignments();
        this.addToolbarButtons();
    }

    onDestroy = () => {

    }

    private defineAlignments = () => {
        this.alignments = [
            {
                icon: IconAlignLeft,
                apply: () => {
                    this.displayStyle.add(this.img, 'inline');
                    this.floatStyle.add(this.img, 'left');
                    this.marginStyle.add(this.img, '0 1em 1em 0');
                },
                isApplied: () => this.floatStyle.value(this.img) === 'left'
            },
            {
                icon: IconAlignCenter,
                apply: () => {
                    this.displayStyle.add(this.img, 'block');
                    this.floatStyle.remove(this.img);
                    this.marginStyle.add(this.img, 'auto');
                },
                isApplied: () => this.marginStyle.value(this.img) === 'auto'
            },
            {
                icon: IconAlignRight,
                apply: () => {
                    this.displayStyle.add(this.img, 'inline');
                    this.floatStyle.add(this.img, 'right');
                    this.marginStyle.add(this.img, '0 0 1em 1em');
                },
                isApplied: () => this.floatStyle.value(this.img) === 'right'
            }
        ];
    }

    private addToolbarButtons = () => {
        const buttons: Array<HTMLSpanElement> = [];
        this.alignments.forEach((alignment: Alignment, idx: number) => {
            const button = document.createElement('span');
            buttons.push(button);
            button.innerHTML = alignment.icon;
            button.addEventListener('click', (evt: MouseEvent) => {
                evt.stopPropagation();
                // deselect all buttons
                buttons.forEach(btn => btn.classList.remove('tb-active'));
                if (alignment.isApplied()) {
                    // If applied, unapply
                    this.floatStyle.remove(this.img);
                    this.marginStyle.remove(this.img);
                    this.displayStyle.remove(this.img);
                } else {
                    // otherwise, select button and apply
                    this.selectButton(button);
                    alignment.apply();
                }
                // image may change position; redraw drag handles
                this.requestUpdate();
            });
            Object.assign(button.style, toolbarButtonStyles);
            if (idx > 0) {
                button.style.borderLeftWidth = '0';
            }
            if (button.children[0]) {
                const child = button.children[0] as HTMLElement;
                Object.assign(child.style, toolbarButtonSvgStyles);
            }
            if (alignment.isApplied()) {
                this.selectButton(button);
            }
            this.toolbar.appendChild(button);
        });
    }

    private selectButton = (button: HTMLElement) => {
        button.classList.add('tb-active');
    }
}
