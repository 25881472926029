import { XSMobileDialog } from "../../common/dialog/MobileDialog";
import DialogAppBar from "../../common/dialog/DialogAppBar";
import * as React from "react";
import { Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import AppButton from "../../common/components/AppButton";
import { SendMailWhiteIcon } from "../../common/Icons";
import { theme } from "../../main/Theme";
import { ContactDetails, EventPaymentSettings } from "../../types/EventTypes";
import { Container } from "../../common/Misc";
import { shortSurname } from "../../contact/Contact";

type SendPaymentMessageDialogProps = {
    notification: boolean;
    daysBeforeEvent?: number;
    unpaidParticipants?: Array<ContactDetails>;
    paymentSettings?: EventPaymentSettings;
    showEmail: (contacts: Array<ContactDetails>) => void;
    handleSend: (contacts: Array<ContactDetails>) => void;
    handleClose: () => void;
};

export const SendPaymentMessageDialog = (props: SendPaymentMessageDialogProps) => {
    const { unpaidParticipants, notification, daysBeforeEvent = 0, showEmail, handleSend, handleClose } = props;
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const unpaidParticipantsNum = unpaidParticipants?.length ?? '...';
    const [contactsToSend, setContactsToSend] = React.useState<ContactDetails[]>(
        unpaidParticipants?.filter(c => c.email) ?? []
    );
    const changeContactSendStatus = (checked: boolean, contact: ContactDetails) =>
        setContactsToSend(checked ? [ ...contactsToSend, contact ] : contactsToSend.filter(c => c.id != contact.id));
    const buttonsDisabled = contactsToSend.every(c => !c.email);
    return (
        <XSMobileDialog open={true} PaperProps={{ style: { maxWidth: isXs ? 360 : 600, margin: isXs ? 28 : 'unset' } }}>
            <DialogAppBar label="Send payment e-mail" close={handleClose} />
            <DialogContent style={{ padding: 16 }}>
                {notification ? `${daysBeforeEvent} days left before the tournament starts! We noticed that ${unpaidParticipantsNum} participants did not pay their entry fees:`
                    : `${unpaidParticipantsNum} participants have not yet paid their entry fees. A reminder with a possibility to pay will be sent to selected participants:`}
                <Box height={16} />
                {unpaidParticipants && <FormControlLabel control={<Container marginLeft={1} display="flex" flexDirection="column">
                    {unpaidParticipants.map((golfer, index) =>
                        <Box key={index}>
                            <Box display="flex" flexDirection="row">
                                <Checkbox
                                    disabled={!Boolean(golfer.email)}
                                    color="secondary" checked={contactsToSend.findIndex(c => c.id === golfer.id) > -1}
                                    onChange={(_, v) => changeContactSendStatus(v, golfer)} />
                                <Typography>{shortSurname(golfer)}</Typography>
                            </Box>
                            {(notification || index < unpaidParticipants!.length - 1) && <Box height={8} />}
                        </Box>
                    )}
                </Container>} label="" />}
                {notification && <Box>
                    Would you like us to send them an email with a possibility to pay?
                </Box>}
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" margin="0px 8px">
                    <AppButton color="info" onClick={handleClose}>Cancel</AppButton>
                    <Box display="flex" flexDirection="row">
                        <AppButton color="info" disabled={buttonsDisabled}
                                   onClick={() => showEmail(contactsToSend)}>Review email</AppButton>
                        <Box width={8} />
                        <AppButton disabled={buttonsDisabled} color="secondary" onClick={() => handleSend(contactsToSend)}>
                            <SendMailWhiteIcon /><Box width={2} />Send
                        </AppButton>
                    </Box>
                </Box>
            </DialogActions>
        </XSMobileDialog>
    );
};