import React from "react";
import { XSMobileDialog } from "../../common/dialog/MobileDialog";
import DialogAppBar from "../../common/dialog/DialogAppBar";
import {
    Box, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography, useMediaQuery
} from "@mui/material";
import AppButton from "../../common/components/AppButton";
import { Spacing } from "../../common/Misc";
import {
    BkCloseIcon, BlueLinkIcon, InfoIcon
} from "../../common/Icons";
import TextField, { email } from '../../validation/ValidatedTextField';
import { AppColors, theme } from "../../main/Theme";
import CurrencyList from 'currency-list';
import { Urls } from "../../util/config";

type CurrencyDetails = {
    name: string,
    symbol_native: string,
    symbol: string,
    code: string,
    name_plural: string,
    rounding: number,
    decimal_digits: number
};

type PayPalSettingsDialogProps = {
    emailAddress: string;
    currencyCode: string;
    onClose: () => void;
    onSave: (payPalEmail: string, payPalCurrency: string) => void;
};

const mainCurrencies = new Set<string>([
    'USD', 'EUR', 'GBP', 'AUD', 'CAD'
]);

const allCurrencies = false;

export const PayPalSettingsDialog = (props: PayPalSettingsDialogProps) => {
    const { emailAddress, currencyCode, onClose, onSave } = props;
    const [recipientEmail, setEmail] = React.useState(emailAddress);
    const currencies = React.useMemo(
        () => {
            const currenciesObj = CurrencyList.getAll('en_US');
            const resultArray = new Array<CurrencyDetails>();
            mainCurrencies.forEach(currencyCode =>
                resultArray.push(currenciesObj[currencyCode] as CurrencyDetails)
            );
            if (allCurrencies) {
                for (const currencyCodeKey in currenciesObj) {
                    if (!mainCurrencies.has(currencyCodeKey)) {
                        resultArray.push(currenciesObj[currencyCodeKey] as CurrencyDetails);
                    }
                }
            }
            return resultArray;
        },
        []
    );
    const [selectedCurrencyCode, setSelectedCurrencyCode] = React.useState(
        currencyCode || (localStorage.getItem('localCurrency') ?? 'USD')
    );
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <XSMobileDialog PaperProps={{ style: { maxWidth: isXs ? 360 : 440, margin: isXs ? 28 : 'unset',
                maxHeight: isXs ? 430 : 'unset'
        }}} open={true} maxWidth={'xs'} fullWidth={true}>
            <DialogAppBar label="Paypal payments" close={onClose} />
            <DialogContent style={{ padding: "20px 16px 12px 16px" }}>
                <Box display="flex" flexDirection="column" fontFamily="poppins, sans-serif" fontSize="14px"
                     lineHeight="21px">
                    <Typography style={{ color: AppColors.webBlack }}>
                        Receive online registration payments using Business PayPal account:
                    </Typography>
                    <Box height={12} />
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                        <TextField
                            textFieldProps={{
                                id: 'e-mail',
                                label: 'Your PayPal Business account e-mail',
                                variant: "standard",
                                value: recipientEmail,
                                onChange: e => setEmail(e.target.value),
                                style: { width: isXs ? '100%' : '70%' },
                                autoFocus: true,
                                InputLabelProps: { shrink: true },
                                InputProps: {
                                    endAdornment: recipientEmail ?
                                        <Box onClick={() => setEmail('')} style={{
                                            transform: 'scale(1.2)', display: 'flex', alignItems: 'end',
                                            cursor: 'pointer', marginLeft: 4
                                        }}><BkCloseIcon /></Box> : undefined
                                }
                            }}
                            rules={[email]} />
                        <Box height={12} />
                        <Box display="flex" flexDirection="row" style={{ backgroundColor: AppColors.webGreyLight }}
                             alignItems="center" justifyContent="space-between" borderRadius={1} padding={1}>
                            <InfoIcon htmlColor={AppColors.webWarning} />
                            <Box width={16} />
                            <Typography>Please ensure your PayPal email address is entered correctly to avoid any misattributed payments.</Typography>
                        </Box>
                        <Box height={12} />
                        <FormControl
                            variant="standard"
                            style={{ width: isXs ? '100%' : '70%' }}>
                            <InputLabel id="currency" shrink>Currency</InputLabel>
                            <Select
                                variant="standard"
                                labelId="select-tees-label"
                                id="select-currency"
                                label="Currency"
                                value={selectedCurrencyCode}
                                MenuProps={{ style: { maxHeight: 400 } }}
                                onChange={e => setSelectedCurrencyCode(e.target.value as string)}>
                                {
                                    currencies.map(currency =>
                                        <MenuItem key={currency.code} value={currency.code}>
                                            {`${currency.name} (${currency.code})`}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box height={20} />
                    <Box style={{ backgroundColor: AppColors.webGreyLight }} display="flex" flexDirection="row"
                         alignItems="center" justifyContent="space-between" borderRadius={1} padding={1}>
                        <Typography style={{ color: AppColors.webBlack }}>
                            Don’t have a PayPal Business account?&nbsp;&nbsp;
                            <a href={Urls.payPalBusinessAccountSignUpArticle} target="_blank" rel="noopener noreferrer"
                               style={{ color: AppColors.webBlue500 }}>
                                Sign up
                            </a>
                        </Typography>
                        <Box width={20} height={20}>
                            <a href={Urls.payPalBusinessAccountSignUpArticle} target="_blank" rel="noopener noreferrer"
                               style={{ color: AppColors.webBlue500 }}>
                                <BlueLinkIcon />
                            </a>
                        </Box>
                        <Box width={8} />
                    </Box>
                    <Spacing />
                    <Typography>
                        <a style={{ textDecoration: 'underline', color: AppColors.webBlue500 }}
                           href={Urls.enablePayPalFeesArticle} target="_blank" rel="noopener noreferrer">Learn more</a>
                        &nbsp;about accepting payments via PayPal.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <AppButton color="info" onClick={onClose}>Cancel</AppButton>
                <AppButton color="secondary"
                           disabled={!recipientEmail || !email.valid(recipientEmail)}
                           onClick={() => onSave(recipientEmail, selectedCurrencyCode)}>
                    Save
                </AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
};
