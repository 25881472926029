import * as React from "react";
import { TextField, Typography } from "@mui/material";
import { AppColors } from "../../main/Theme";
import { MAX_GOLFERS, MAX_GOLFERS_PRO } from "../../types/EventTypes";

type ParticipantsNumberFieldProps = {
    maxGolfers?: number;
    disabled: boolean;
    maxWidth: number;
    pro?: boolean;
    inputRef?: React.RefObject<HTMLDivElement>;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, absMaxGolfers: number) => void;
};

export const ParticipantsNumberField = (props: ParticipantsNumberFieldProps) => {
    const { maxGolfers, disabled, maxWidth, pro, inputRef, onChange } = props;
    const absMaxGolfers = pro ? MAX_GOLFERS_PRO : MAX_GOLFERS;
    return <>
        <TextField
            variant="standard"
            id="course"
            disabled={disabled}
            ref={inputRef}
            label="Maximum number of participants"
            value={maxGolfers || ''}
            placeholder={String(absMaxGolfers)}
            onChange={e => onChange(e, absMaxGolfers)}
            InputLabelProps={{ shrink: true }}
            style={{ maxWidth }} />
        <div style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : AppColors.webGrey, marginTop: 6, maxWidth }}>
            <Typography sx={{ fontSize: '11px', lineHeight: '15.95px', }}>Looking to create an event with more than {absMaxGolfers} golfers, please contact us at&nbsp;
                <a style={{ display: 'inline-block', color: '#025987', fontWeight: 500 }} href="mailto:support@golfpadgps.com">support@golfpadgps.com</a></Typography>
        </div>
    </>;
}
