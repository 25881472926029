import { AnyAction } from 'redux';

export interface Action {
    type: string;
}

export function isOfType<T extends Action>(action: AnyAction, type: string): action is T {
    return !!action && (action as T).type === type;
}

export function withKey<T>(key: string) {
    return function(object: any): T {
        return object[key];
    };
}
