import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import { AppColors } from './main/Theme';
import makeStyles from '@mui/styles/makeStyles';

export const editscoreCellSize = 34;
export const editscoreLabelWidth = 112;
export const editscoreTitleWidth = 32;
export const editscoreSubLabelWidth = 72;
export const scorecardCellWidth = 34;
export const scorecardCellInOutWidth = 46;
export const scorecardCellHeight = 34;
export const tvItemHeight = 60;
export const NEW_EVENT_ELEM_WIDTH = 400;

export const welcomeImage = '/img/main-background-wide.svg';
export const tvBackgroundWide = '/img/tv.jpg';
export const greyBorderImg = '/img/custom_grey_border.svg';

const tvOpacity = .9;
const headerShift = 24;
const badgeSize = 128;
const badgeSizeSmall = 96;
const itemRadius = 5;
const tvItemRadius = 8;

export const gap = (theme: Theme, spacing: number) => {
    return parseInt(theme.spacing(spacing), 10);
}

export const styles = (theme: Theme) => ({
    colorWhite: {
        color: AppColors.white,
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    textSecondary: {
        color: theme.palette.grey[50],
    },
    redText: {
        color: 'red',
    },
    backgroundPrimary: {
        color: AppColors.white,
        backgroundColor: theme.palette.primary.main,
    },
    backgroundSecondary: {
        color: AppColors.white,
        backgroundColor: theme.palette.secondary.main,
    },
    backgroundGray: {
        color: AppColors.white,
        backgroundColor: '#aaaaaa',
    },
    eventRoundButton: {
        borderRadius: 4,
        minWidth: 90
    },
    muiButton: {
        marginTop: 8,
        flexShrink: 0,
        marginRight: 8
    },
    toolbarButton: {
        color: AppColors.white,
        backgroundColor: theme.palette.primary.main,
        height: 32,
        minHeight: 32,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: AppColors.webBlue300
        }
    },
    toolbarButtonActive: {
        color: AppColors.white,
        backgroundColor: AppColors.webBlue200,
        height: 32,
        minHeight: 32,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: AppColors.webBlue300
        }
    },
    toolbarButtonInv: {
        color: theme.palette.secondary.main,
        backgroundColor: AppColors.white,
        height: 32,
        minHeight: 32,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        boxShadow: 'none',
        border: 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: AppColors.hoverColor
        }
    },
    tableHeader: {
        display: 'inline-block',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: theme.palette.grey[100],
        color: 'black'
    },
    headerCell: {
        position: 'relative',
        width: 56,
        height: editscoreCellSize * .60,
        display: 'inline-block',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '0.85rem'
    },
    portalScorecardPanel: {
        width: '100%',
    },
    portalRow: {
        width: '100%',
        color: 'black',
        borderBottom: '0px solid grey'
    },
    portalHeader: {
        width: '100%',
        borderBottom: '0px solid grey'
    },
    portalHeaderCell: {
        color: AppColors.white,
        width: scorecardCellWidth,
        height: scorecardCellHeight,
    },
    portalCellsRoot: {
        //width: '100%',
        paddingTop: 2,
        paddingBottom: 2
    },
    portalCells: {
        //width: '100%',
        display: 'flex',
        justifyContent: 'space-around'
    },
    portalCell: {
        color: AppColors.webBlack,
        width: scorecardCellWidth,
        height: scorecardCellHeight,
    },
    portalCellsInOut: {
        position: 'relative',
        width: scorecardCellInOutWidth,
        height: editscoreCellSize,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    portalDecor: {
        top: 4,
        left: 4,
        right: 4,
        bottom: 4
    },
    portalListItemHeader: {
        color: AppColors.white,
        backgroundColor: theme.palette.secondary.main,
        width: 'auto',
        height: '40px',
        margin: 0,
        border: 0,
        borderTopLeftRadius: itemRadius,
        borderTopRightRadius: itemRadius,
        fontWeight: 600,
        fontSize: '0.75rem',
        paddingTop: 2,
        paddingBottom: 2
    },
    portalListItemZebra: {
        width: 'auto',
        margin: 0,
        border: 0,
        fontSize: '0.8rem',
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.grey[200]
        },
        '&:nth-child(even)': {
            backgroundColor: AppColors.white
        },
        '&:hover': {
            backgroundColor: AppColors.hoverColor
        },
        paddingTop: 2,
        paddingBottom: 2
    },
    tableInput: {
        width: 56
    },
    link: {
        display: 'inline',
        color: theme.palette.primary.main
    },
    linkBlue: {
        display: 'inline-block',
        color: '#025987'
    },
    linkBluePointer: {
        display: 'inline-block',
        color: '#025987',
        cursor: "pointer",
        textDecoration: "underline"
    },
    linkWhy: {
        display: 'inline-block',
        color: '#025987',
        textDecoration: 'none',
        fontWeight: 600
    },
    textIcon: {
        color: 'inherit',
        verticalAlign: 'middle',
        width: 18,
        height: 18,
        position: 'relative',
        margin: 2
    },
    textIconActive: {
        color: 'inherit',
        verticalAlign: 'middle',
        width: 16,
        height: 16,
        position: 'relative',
        margin: 2,
        '&:hover': {
            backgroundColor: AppColors.webBlue200
        }
    },
    textIconInverted: {
        color: AppColors.white,
        backgroundColor: theme.palette.secondary.main,
        verticalAlign: 'middle',
        width: 16,
        height: 16,
        position: 'relative',
        margin: 2,
    },
    textFieldCompact: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    textField: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    searchField: {
        width: 280,
    },
    eventHeader: {
        margin: 0,
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(2.3),
        paddingBottom: theme.spacing(2.3),
    },
    tileEventTitle: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tileEventText: {
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tileBox: {
        maxWidth: 400,
        minHeight: 160,
        margin: theme.spacing(.5),
        backgroundColor: AppColors.white,
        border: 0,
        borderRadius: itemRadius,
    },
    tileNewEvent: {
        maxWidth: 400,
        margin: theme.spacing(.5),
        backgroundRepeat: 'no-repeat',
        border: 0,
        borderRadius: itemRadius
    },
    tileAboutBox: {
        padding: 8,
        width: 300,
        minHeight: 166,
        backgroundColor: AppColors.white,
        border: `1px solid ${AppColors.webGrey200}`,
        borderRadius: 4,
    },
    tileScheduleSession: {
        maxWidth: 810,
        minHeight: 200,
        margin: theme.spacing(.5),
        backgroundRepeat: 'no-repeat',
        border: 0,
        borderRadius: itemRadius
    },
    eventTabs: {
        margin: theme.spacing(2),
    },
    eventName: {
        textTransform: 'none',
        fontSize: '0.9rem',
        fontWeight: 'bold',
    },
    eventDetails: {
        textTransform: 'none',
        fontSize: '0.9rem',
    },
    listTitle: {
        marginLeft: 0,
        marginTop: 0,
        fontWeight: 600,
    },
    listTitleMisc: {
        paddingTop: 16,
        marginLeft: 0,
        marginTop: 0,
        fontWeight: 600,
    },
    listTitleNoMargin: {
        margin: 0,
        fontWeight: 600,
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        margin: 0,
    },
    pubRoot: {
        maxWidth: 1000,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    midScreenRoot: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '40vh'
    },
    listRootGreyRound: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        margin: 0,
        borderRadius: itemRadius,
    },
    listRootGrey: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(1),
        margin: 0,
    },
    listRootGreySmallVert: {
        backgroundColor: theme.palette.grey[100],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 2,
        paddingBottom: theme.spacing(1),
        margin: 0,
    },
    listItemHeader: {
        color: AppColors.white,
        backgroundColor: theme.palette.secondary.main,
        width: 'auto',
        margin: 0,
        border: 0,
        borderTopLeftRadius: itemRadius,
        borderTopRightRadius: itemRadius,
        fontWeight: 600,
        fontSize: '0.8rem',
        height: '40px'
    },
    listItemHeaderWhite: {
        backgroundColor: AppColors.white,
        width: 'auto',
        margin: 0,
        border: 0,
        borderTopLeftRadius: itemRadius,
        borderTopRightRadius: itemRadius,
        fontWeight: 600,
        fontSize: '0.8rem',
    },
    listItem1: {
        backgroundColor: AppColors.white,
        width: 'auto',
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        border: 0,
        borderRadius: itemRadius,
        fontSize: '0.85rem',
    },
    listItem: {
        width: 'auto',
        margin: 0,
        border: 0,
        fontSize: '0.85rem',
        backgroundColor: AppColors.white,
    },
    listItemGreen: {
        width: 'auto',
        margin: 0,
        border: 0,
        fontSize: '0.85rem',
        backgroundColor: AppColors.reportedColor,
    },
    textBox: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    homeCourseOrCity: {
        fontSize: '0.6rem',
    },
    homeCourseOrCityTV: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.9rem',
    },
    legendIconGreen: {
        width: 25,
        height: 25,
        marginBottom: -8,
        display: 'inline-block',
        backgroundColor: AppColors.reportedColor,
    },
    marginUnit: {
        margin: theme.spacing(1),
        // border: '0.1em solid gray',
    },
    listItemZebra: {
        width: 'auto',
        margin: 0,
        border: 0,
        fontSize: '0.85rem',
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.grey[200]
        },
        '&:nth-child(even)': {
            backgroundColor: AppColors.white
        },
        '&:hover': {
            backgroundColor: AppColors.hoverColor
        }
    },
    minHeight40: {
        minHeight: 40,
    },
    height40: {
        height: 40,
    },
    width0: {
        width: '0 !important',
    },
    width70: {
        width: 70
    },
    marginNeg: {
        margin: -1
    },
    margin0: {
        margin: 0
    },
    margin10: {
        margin: 10
    },
    margin15: {
        margin: 15
    },
    margin20: {
        margin: 20
    },
    padding0: {
        padding: 0
    },
    padding1u: {
        padding: theme.spacing(1)
    },
    padding2u: {
        padding: theme.spacing(2)
    },
    padding5u: {
        padding: theme.spacing(5)
    },
    centerOuter: {
        backgroundColor: AppColors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    centerInner: {
        backgroundColor: theme.palette.grey[100],
        width: '100%',
        height: 'calc(100% - 40px)',
        margin: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    zebraOdd: {
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.grey[200]
        },
        '&:nth-child(even)': {
            backgroundColor: AppColors.white
        },
        '&:hover': {
            backgroundColor: AppColors.hoverColor
        }
    },
    'hover:hover': {
        backgroundColor: AppColors.hoverColor
    },
    zebraEven: {
        '&:nth-child(even)': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey[200]
        },
        '&:hover': {
            backgroundColor: AppColors.hoverColor
        }
    },
    paddingLeftRight: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    borderRight: {
        borderRight: '0.1em solid gray',
        padding: '0.5em'
    },
    contactBar: {
        minHeight: 120,
        color: AppColors.white,
    },
    img: {
        maxWidth: '100%',
        maxHeight: 150
    },
    tabPanel: {
        display: 'flex',
        flexShrink: 0
    },
    tabRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        '& > *': {
            flexShrink: 0
        }
    },
    tab: {
        padding: '12px 14px',
        fontSize: '0.8rem',
        fontWeight: 600,
        outline: 'none',
        textDecoration: 'none',
    },
    tabPanelPub: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
    },
    tabRootPub: {
        margin: 0,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    tabPub: {
        flex: '1 1 auto',
        fontSize: 18,
        lineHeight: '24.3px',
        paddingBottom: 10,
        fontWeight: 500,
        outline: 'none',
        textDecoration: 'none',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    active: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.grey[100],
    },
    passive: {
        color: theme.palette.primary.light,
        backgroundColor: AppColors.white,
    },
    activePub: {
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    passivePub: {
        textAlign: 'center',
        color: theme.palette.grey[400],
    },
    invisible: {
        visibility: 'hidden',
    },
    transparent: {
        opacity: 0
    },
    stickyTop: {
        backgroundColor: AppColors.white,
        opacity: 1,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
    },
    body: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    paper: {
        margin: 0,
        padding: theme.spacing(2),
    },
    table: {
        minWidth: 400,
    },
    containerContered: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%'
    },
    buttonContainer: {
        alignItems: 'left',
        marginTop: theme.spacing(4)
    },
    buttonContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginTop: theme.spacing(4)
    },
    input: {
        width: 160
    },
    uppercaseText: {
        textTransform: 'uppercase',
    },
    noLeftMargin: {
        marginLeft: 0,
    },
    noSidesMargin: {
        marginRight: 0,
        marginLeft: 0,
    },
    checkBox: {
        height: 26,
        marginLeft: 0,
    },
    boldText: {
        fontWeight: 600
    },
    italicText: {
        fontStyle: 'italic'
    },
    thickText: {
        fontWeight: 700
    },
    thickerText: {
        fontWeight: 900
    },
    rightText: {
        textAlign: 'right'
    },
    primaryText: {
        color: theme.palette.primary.main,
    },
    secondaryText: {
        color: theme.palette.secondary.main,
    },
    survey: {
        marginTop: theme.spacing(4)
    },
    containerCentered: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%'
    },
    childrenCentered: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    childrenCenteredLeft: {
        alignSelf: 'center',
        textAlign: 'left'
    },
    buttonContainerCentered: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonCentered: {
        marginTop: theme.spacing(1),
        minWidth: 124
    },
    compactButton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        minWidth: theme.spacing(9)
    },
    smallIconButton: {
        margin: theme.spacing(1 / 4),
        width: 30,
        height: 30,
    },
    iconButton: {
        minWidth: 0,
        maxHeight: theme.spacing(5),
        minHeight: theme.spacing(5)
    },
    rightButtonIcon: {
        marginLeft: theme.spacing(1 / 2),
        marginRight: -theme.spacing(1 / 2)
    },
    leftButtonIcon: {
        marginLeft: -theme.spacing(1 / 2),
        marginRight: theme.spacing(1 / 2)
    },
    selected: {
        color: theme.palette.getContrastText(theme.palette.primary.main) + ' !important',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: AppColors.hoverColorInv
        }
    },
    disabled: {
        //color: theme.palette.getContrastText(theme.palette.primary.main) + ' !important',
        backgroundColor: AppColors.webGrey100,
        '&:hover': {
            backgroundColor: AppColors.hoverColorInv
        }
    },
    flex: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    centeredGrid: {
        display: 'inline-block',
        alignItems: 'center'
    },
    labelTeeRegular: {
        width: theme.spacing(8.5),
        minWidth: theme.spacing(8.5),
    },
    labelTeeShotgun: {
        width: theme.spacing(6),
        minWidth: theme.spacing(6)
    },
    formSelector: {
        marginTop: '16px',
        marginRight: '15px',
        marginLeft: '0px'
    },
    helperTextInfo: {
        display: 'block',
        marginBottom: '15px'
    },
    helperTextError: {
        color: 'red',
        display: 'block',
        marginBottom: '15px'
    },
    inputError: {
        color: '#BE3636'
    },
    chip: {
        margin: theme.spacing(1 / 2),
        background: AppColors.white,
        border: '1px solid gray',
    },
    chipFixed: {
        margin: theme.spacing(1 / 2),
        background: AppColors.white,
        border: '1px solid gray',
        width: 100,
        overflow: 'hidden',
        textOverflow: '----'
    },
    chipContainer: {
        overflow: 'hidden',
        textOverflow: '----',
        maxHeight: 64 + gap(theme, 3),
    },
    chipMargin: {
        margin: theme.spacing(1 / 2)
    },
    scrollPanel: {
        overflowY: 'auto'
    },
    gridLabel: {
        width: 150,
        marginRight: 0
    },
    labelOverflow: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    labelOverflow2: {
        display: 'block',
        lineHeight: 1.2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: theme.spacing(4),
        maxHeight: theme.spacing(4)
    },
    teamBadge: {
        top: theme.spacing(6.5),
        right: theme.spacing(0.5),
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: '50%'
    },
    groupBadge: {
        top: theme.spacing(6.5),
        right: theme.spacing(2.5),
        height: theme.spacing(2.5),
        width: theme.spacing(8),
        borderRadius: theme.spacing(1.25)
    },
    groupBadge2: {
        top: theme.spacing(8),
        right: theme.spacing(2.5),
        height: theme.spacing(2.5),
        width: theme.spacing(8),
        borderRadius: theme.spacing(1.25)
    },
    loginPaper: {
        width: '100%',
        paddingLeft: '15%',
        paddingRight: '15%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    eventPaper: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(15),
    },
    holePaper: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxWidth: theme.spacing(16),
        minWidth: theme.spacing(16)
    },
    teamPaper: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxWidth: theme.spacing(32),
        minWidth: theme.spacing(24)
    },
    teamPaperShort: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxWidth: theme.spacing(24),
        minWidth: theme.spacing(24),
    },
    buttonBadge: {
        marginTop: theme.spacing(2),
    },
    buttonWhite: {
        color: AppColors.secondary,
        backgroundColor: AppColors.white
    },
    rotate180: {
        WebkitTransform: 'rotate(180deg)',
        '-moz-transform': 'rotate(180deg)',
        msTransform: 'rotate(180deg)',
        OTransform: 'rotate(180deg)',
        transform: 'rotate(180deg)',
    },
    rotate45: {
        WebkitTransform: 'rotate(45deg)',
        '-moz-transform': 'rotate(45deg)',
        msTransform: 'rotate(45deg)',
        OTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
    },
    rotate90: {
        WebkitTransform: 'rotate(90deg)',
        '-moz-transform': 'rotate(90deg)',
        msTransform: 'rotate(90deg)',
        OTransform: 'rotate(90deg)',
        transform: 'rotate(90deg)',
    },
    rotate135: {
        WebkitTransform: 'rotate(135deg)',
        '-moz-transform': 'rotate(135deg)',
        msTransform: 'rotate(135deg)',
        OTransform: 'rotate(135deg)',
        transform: 'rotate(135deg)',
    },
    rotate270: {
        WebkitTransform: 'rotate(270deg)',
        '-moz-transform': 'rotate(270deg)',
        msTransform: 'rotate(270deg)',
        OTransform: 'rotate(270deg)',
        transform: 'rotate(270deg)',
    },
    noselect: {
        WebkitTouchCallout: 'none', /* iOS Safari */
        WebkitUserSelect: 'none', /* Safari */
        MozUserSelect: 'none', /* Firefox */
        msUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none', /* Non-prefixed version, currently supported by Chrome and Opera */
    },
    clickable: {
        cursor: 'pointer'
    },
    dragActive: {
        backgroundColor: theme.palette.primary.light
    },
    dragCanDrop: {
        backgroundColor: theme.palette.primary.light
    },
    dragDragging: {
        backgroundColor: theme.palette.grey[200],
        opacity: .5
    },
    dragPlaceholder: {
        backgroundColor: '#ffc'
    },
    breakpoint: {
        '&::before': {
            [theme.breakpoints.down('md')]: {
                content: `'screen xs'`
            },
            [theme.breakpoints.up('sm')]: {
                content: `'screen sm'`
            },
            [theme.breakpoints.up('md')]: {
                content: `'screen md'`
            },
            [theme.breakpoints.up('lg')]: {
                content: `'screen lg'`
            },
            [theme.breakpoints.up('xl')]: {
                content: `'screen xl'`
            }
        }
    },
    welcomeFooter: {
        width: '100%',
        flexShrink: 0,
        padding: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'bottom',
        backgroundImage: `url(${welcomeImage})`
    },
    welcomeImage: {
        width: '100%',
        visibility: 'hidden'
    },
    welcomeHeader1: {
        paddingTop: 52,
        fontSize: 32,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        [theme.breakpoints.down('md')]: {
            fontSize: 28
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        }
    },
    welcomeHeader2: {
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        [theme.breakpoints.down('md')]: {
            fontSize: 17
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 15
        }
    },
    welcomeNormalButton: {
        paddingBottom: 40,
        [theme.breakpoints.down('lg')]: {
            visibility: 'hidden',
            height: 0,
            paddingBottom: 0
        }
    },
    welcomeFooterButton: {
        width: '100%',
        height: 80,
        backgroundColor: 'white',
        position: 'sticky',
        bottom: 0,
        zIndex: 1000,
        visibility: 'hidden',
        [theme.breakpoints.down('lg')]: {
            visibility: 'visible'
        }
    },
    emailDialog: {
        width: '75%',
    },
    fileButton: {
        color: AppColors.white,
        backgroundColor: theme.palette.secondary.main,
        width: 'auto',
        height: theme.spacing(10),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        borderRadius: theme.spacing(5),
    },
    eventsDataTable: {
        marginTop: 15,
        paddingLeft: 15,
        paddingRight: 15
    },
    infoBaseLL: {
        fontStyle: 'normal',
        marginLeft: 4
    },
    courseNameLL: {
        fontSize: '24px',
        lineHeight: '36px',
        color: '#000000',
        fontWeight: 500
    },
    dateTimeAndCompetitionLL: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400
    },
    darkGrey: {
        color: '#6D6D71'
    },
    grey: {
        color: '#9A9A9A'
    },
    paddingLeft6: {
        paddingLeft: 6
    },
    paddingBottom4: {
        paddingBottom: 4
    },
    purseAlertBase: {
        color: 'red',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14
    },
    fixPurseAlert: {
        marginTop: 2,
        marginLeft: 10
    },
    computedPurseAlert: {
        marginTop: 4,
        marginLeft: 20
    },
    missingHandicapsContainer: {
        padding: '6px 12px',
        marginLeft: 0,
        background: '#EAF0F3',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        alignItems: 'flex-start',
        border: '1px solid #BCD2DD',
        borderRadius: 4
    },
    missingHandicapsBase: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        flex: 'none',
        flexGrow: 0
    },
    missingHandicapsAlert: {
        color: AppColors.webBlack,
        order: 0
    },
    missingHandicapsEdit: {
        order: 1,
        marginLeft: 5,
        display: 'revert'
    },
    textCentered: {
        textAlign: 'center'
    },
    notAllHandicapsPresent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        borderRadius: 4
    },
    flexCenteredRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flexCenteredRow21px: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        maxHeight: 21
    },
    smallerShiftedIcon: {
        transform: 'scale(0.8)',
        marginLeft: 4
    },
    pickupsMsg: {
        fontSize: "0.875rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 400,
        lineHeight: '1.46429em',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    liveScoresOn: {
        fontSize: "0.54rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 400,
        lineHeight: '0.5em',
        paddingTop: 2
    },
    showLLs: {
        fontSize: "0.7rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 400,
        lineHeight: '0.7em',
        marginTop: 4
    },
    flexColumnContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRowContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    alertText: {
        fontSize: '1.1rem',
        fontStyle: 'bold',
        color: 'black'
    },
    mainAlertSpan: {
        lineHeight: '1.8em',
        fontSize: '1.05rem'
    },
    dateSpan: {
        margin: 'auto',
        fontSize: "0.75rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 400,
        lineHeight: '0.75em'
    },
    dropDownList: {
        float: 'left',
        paddingRight: 0,
        margin: 0,
        minWidth: 85,
        width: 150,
        paddingTop: 3,
        marginRight: 20
    },
    handicapIndexSpan: {
        float: 'left',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        textAlign: 'left',
        fontFamily: '"poppins", sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        width: '100%'
    },
    handicapTextField: {
        float: 'left',
        padding: 0,
        margin: 0
    },
    handicapField: {
        maxWidth: 120
    },
    handicapIdField: {
        minWidth: 150
    },
    handicapByIdError: {
        color: '#EB9718'
    },
    handicapIdLookupButton: {
        float: "left",
        marginTop: 15,
        minWidth: 60,
        position: 'relative'
    },
    alertSpan: {
        display: 'flex',
        justifyContent: 'left'
    },
    individualOrTeam: {
        fontSize: "0.7rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 600,
        lineHeight: '0.8em',
        paddingTop: 4,
        paddingLeft: 10
    },
    setUpTeams: {
        fontSize: "0.6rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 500,
        lineHeight: '0.6em'
    },
    paddingLeftRight16: {
        paddingLeft: 16,
        paddingRight: 16
    },
    changeDisplayOrder: {
        fontSize: "0.9rem",
        fontFamily: 'poppins, sans-serif',
        fontWeight: 400,
        lineHeight: '1em',
        textAlign: 'left',
        paddingTop: 16,
        paddingBottom: 16
    },
    competitionsOrSideGames: {
        lineHeight: '0.8em',
        fontSize: "0.75rem"
    },
    draggableElement: {
        border: '1px solid #025987',
        borderRadius: 16,
        display: 'block',
        overflow: 'hidden',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        height: 22,
        paddingTop: 6,
        paddingBottom: 3,
        color: '#025987',
        marginTop: 5,
        marginBottom: 6
    },
    marginLeftRight16: {
        marginLeft: 16,
        marginRight: 16
    },
    dragHandleIcon: {
        float: 'left',
        marginLeft: 6,
        marginTop: 6
    },
    dragIndex: {
        float: 'left',
        paddingTop: 5
    },
    connectedPhoneIcon: {
        position: 'relative',
        marginLeft: 2,
        marginTop: -1,
        transform: 'scale(0.85)'
    },
    connectedWithApp: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '6px 12px',
        marginTop: '12px',
        gap: '5px',
        background: '#EAF0F3',
        border: '1px solid #BCD2DD',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px'
    },
    dropDownListSelect: {
        background: '#025987',
        borderRadius: 16,
        color: 'white',
        height: 30,
        minWidth: 100
    },
    incorrectHandicapId: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: 9,
        lineHeight: '120%',
        fontWeight: 400,
        float: 'left',
        maxWidth: 'inherit',
        //maxHeight: 12,
        color: '#EB9718',
        display: 'flex',
        alignItems: 'center'
    },
    handicapId: {
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 120,
        position: 'relative'
    },
    handicapIdWide: {
        minWidth: 115,
        marginRight: 20
    },
    handicapIdTight: {
        minWidth: 80,
        marginRight: 5
    },
    handicapIndexSuccess: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        float: 'left',
        maxWidth: 30,
        lineHeight: '24px',
        marginTop: 2,
        textAlign: 'left'
    },
    handicapIndexById: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px'
    },
    textAndPaddingLeft21: {
        textAlign: 'left',
        paddingLeft: 21
    },
    textAndPaddingLeft5: {
        textAlign: 'left',
        paddingLeft: 5
    },
    handicapOption: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '21px'
    },
    handicapSourceLabel: {
        paddingBottom: 3,
        width: 'inherit',
        maxHeight: 40
    },
    handicap: {
        width: '100%',
        height: 60,
        display: 'flex',
        flexDirection: 'row'
    },
    marginAuto: {
        margin: 'auto'
    },
    roundScorecardLink: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '17px',
        color: '#025987'
    },
    roundScorecardLinkIconDiv: {
        marginRight: 6
    },
    roundScorecardLinkIcon: {
        marginBottom: 1
    },
    linkIconImage: {
        float: 'right',
        marginTop: 2,
        transform: 'scale(0.8)',
        marginRight: -2,
    },
    modeListTitle: {
        fontSize: '1rem',
        fontFamily: 'poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.75,
        color: 'black'
    },
    skinTieBackground: {
        backgroundColor: '#ffffcc'
    },
    skinWinBackground: {
        backgroundColor: '#ccff99'
    },
    skinColorsExplanation: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 17,
        lineHeight: '21px'
    },
    size26x26: {
        width: 26,
        height: 26
    },
    shiftedExplanations: {
        marginTop: 3,
        marginLeft: 4
    },
    width100: {
        width: 100
    },
    width160: {
        width: 160
    },
    minWidth640: {
        minWidth: 640
    },
    emailsList: {
        fontSize: '0.9rem',
        fontFamily: 'poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 1.75,
        color: 'darkgray'
    },
    skinsSummaryBtn: {
        marginLeft: 'auto',
        minWidth: 80,
        "& .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            display: "block"
        }
    },
    marginLeft10: {
        marginLeft: 10
    },
    sansSerifNormalBlack: {
        fontFamily: 'poppins, sans-serif',
        fontStyle: 'normal',
        color: 'black'
    },
    bold09rem: {
        fontWeight: 'bold',
        fontSize: '0.9rem'
    },
    minWidth270: {
        minWidth: 270
    },
    minWidth230: {
        minWidth: 230
    },
    bigHeaderFontStyle: {
        color: '#FFFFFF',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '24px',
        fontFamily: 'poppins, sans-serif'
    },
    portalInfoNotSet: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '30px',
        fontFamily: 'poppins, sans-serif',
        color: AppColors.webBlack
    },
    atTheTop: {
        marginBottom: 'auto'
    },
    marginRight2: {
        marginRight: 2
    },
    payPalButtons: {
        marginRight: "auto",
        marginLeft: "auto"
    },
    centeredDarkGrayText: {
        textAlign: 'center',
        fontSize: '1.3rem',
        fontFamily: 'poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 460,
        color: '#333333',
        margin: '16px 12px'
    },
    smallTextLabel: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        fontFamily: 'poppins, sans-serif',
    },
    contactDialogEventName: {
        color: AppColors.webBlue100,
        marginTop: 8
    },
    boldLabel: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '21px',
        fontFamily: 'poppins, sans-serif',
        color: AppColors.webBlack
    },
    basicLabel: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '21px',
        fontFamily: 'poppins, sans-serif',
    },
    onlyAvailableLabel: {
        marginRight: 17,
        marginTop: -8
    },
    registrationCompletedAlertBody: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        fontFamily: 'poppins, sans-serif',
    },
    greyBackground: {
        backgroundColor: AppColors.webGrey100,
        borderRadius: 4
    },
    blueArrowDown: {
        fill: AppColors.bluish,
    },
    // TV board
    tvRoot: {
        padding: 0,
        margin: 0,
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'round', // 'repeat-y', 'no-repeat',
        backgroundSize: '100%',
        backgroundImage: `url(${tvBackgroundWide})`,
        backgroundAttachment: 'fixed',
    },
    tvContent: {
        minHeight: '100vh',
        paddingTop: '2%',
        paddingBottom: 0,
        paddingLeft: '8%',
        paddingRight: '8%',
        margin: 0,
    },
    tvFooter: {
        position: 'fixed',
        bottom: 0,
        width: '84%',
    },
    tvBadge: {
        margin: 30,
        backgroundColor: theme.palette.common.white,
        width: 100,
        height: 100,
        borderRadius: 100 / 2,
        border: '1px solid ' + theme.palette.grey[300],
    },
    boxShadow: {
        boxShadow: '0px 0px 8px #888888',
    },
    roundCorners: {
        borderRadius: itemRadius,
    },
    tvHeader: {
        marginBottom: 30,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        letterSpacing: '0.05em',
        opacity: tvOpacity,
        width: '100%',
        height: 160,
        border: 0,
        borderRadius: tvItemRadius,
        fontWeight: 600,
        fontSize: 50,
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        // background: 'linear-gradient(180deg, rgba(52, 148, 196, 0.85) 0%, rgba(0, 91, 136, 0.85) 68.13%)',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    },
    tvHeaderSecondary: {
        letterSpacing: '0.1em',
        fontSize: 36,
    },
    tvListItemHeader: {
        color: theme.palette.secondary.main,
        backgroundColor: 'rgba(0,0,0,0)',
        width: 'auto',
        margin: 0,
        border: 0,
        fontWeight: 'bold',
        fontSize: 18,
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
    },
    tvListItemHeaderFirst: {
        borderTopLeftRadius: tvItemRadius,
    },
    tvListItemHeaderLast: {
        borderTopRightRadius: tvItemRadius,
    },
    tvListItemElemFirst: {
        borderBottomLeftRadius: tvItemRadius,
    },
    tvListItemElemLast: {
        borderBottomRightRadius: tvItemRadius,
    },
    tvItem: {
        height: tvItemHeight
    },
    tvItemElem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '50vw',
    },
    tvListItem: {
        color: '#333333',
        backgroundColor: 'rgba(0,0,0,0)',
        width: 'auto',
        margin: 0,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '0.1em',
        border: 0,
        borderTop: '1px solid #cccccc',
        alignItems: 'center',
    },
    tvListItemElem: {
        height: tvItemHeight,
        backgroundColor: theme.palette.grey[200],
        opacity: tvOpacity,
        //fontSize: '1.2vw',
    },
    tvListItemTextElem: {
        height: tvItemHeight,
        backgroundColor: theme.palette.grey[200],
        opacity: tvOpacity
    },
    tvListItemSpacing: {
        height: tvItemHeight,
        color: 'rgba(0,0,0,0)',
        minWidth: 6,
        maxWidth: 6,
        width: 6,
    },
    tvListItemTotal: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    tvListItemThru: {
    },
    tvListItemRelScore: {
        backgroundColor: theme.palette.common.white,
    },
    tvListItemLast: {
        borderBottomLeftRadius: itemRadius,
        borderBottomRightRadius: itemRadius,
    },
    tvListItemScore: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    tvListItemScore0: {
        color: theme.palette.common.white,
        backgroundColor: '#4595c8',
    },
    tvListItemScore1: {
        color: theme.palette.common.white,
        backgroundColor: '#4391C3',
    },
    tvListItemScore2: {
        color: theme.palette.common.white,
        backgroundColor: '#418DBE',
    },
    tvListItemScore3: {
        color: theme.palette.common.white,
        backgroundColor: '#3F89B9',
    },
    tvListItemScore4: {
        color: theme.palette.common.white,
        backgroundColor: '#3D85B4',
    },
    tvListItemScore5: {
        color: theme.palette.common.white,
        backgroundColor: '#3B81AF',
    },
    tvListItemScore6: {
        color: theme.palette.common.white,
        backgroundColor: '#397DAA',
    },
    tvListItemScore7: {
        color: theme.palette.common.white,
        backgroundColor: '#3779A5',
    },
    tvListItemScore8: {
        color: theme.palette.common.white,
        backgroundColor: '#35759F',
    },
    tvTieBreaker: {
        textAlign: 'center',
        width: '20%',
        fontSize: 18,
        maxHeight: 30
    },
    winner: {
        backgroundColor: AppColors.reportedColor,
    },
    winnerItem: {
        backgroundColor: AppColors.reportedColor,
        fontSize: '0.85rem',
    },
    listAvatar: {
        width: 40,
        height: 40,
        borderRadius: 48 / 2,
        margin: 2,
        padding: 4,
        color: theme.palette.common.white,
        backgroundColor: AppColors.webBlue300
    },
    listAvatarDisabled: {
        width: 40,
        height: 40,
        borderRadius: 48 / 2,
        margin: 2,
        padding: 4,
        color: theme.palette.common.white,
        backgroundColor: AppColors.webGrey300
    },
    tvAvatar: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        margin: 10
    },
    tvLink: {
        fontSize: 24,
        fontWeight: 600,
        color: 'white',
        textDecoration: 'none',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    },
    tvTextIcon: {
        color: 'inherit',
        verticalAlign: 'middle',
        width: 24,
        height: 24,
        position: 'relative',
        top: -2,
        margin: 2,
    },
    notSelected: {
        color: '#aaaaaa',
    },
    marginBottom3: {
        marginBottom: 3
    },
    marginBottom16: {
        marginBottom: 16
    },
    // edit score dialog styles
    editscoreHeader: {
        paddingLeft: theme.spacing(0.5)
    },
    editscoreContacts: {
        display: 'flex',
        paddingBottom: 3,
    },
    editscoreNames: {
        fontWeight: 600,
        maxWidth: 480,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: 3,
    },
    editscoreHandicaps: {
        fontWeight: 600,
        paddingRight: 9,
    },
    editscoreSubhead: {
        paddingBottom: theme.spacing(1)
    },
    editscoreScrollPanel: {
        overflowX: 'auto'
    },
    dialogRoot18: {
        maxWidth: 18 * editscoreCellSize + editscoreLabelWidth + 4 + gap(theme, 4)
    },
    dialogRoot9: {
        maxWidth: 9 * editscoreCellSize + editscoreLabelWidth + 4 + gap(theme, 4)
    },
    dialogRoot20: {
        maxWidth: 20 * editscoreCellSize + editscoreLabelWidth + 4 + gap(theme, 4)
    },
    dialogRoot10: {
        maxWidth: 10 * editscoreCellSize + editscoreLabelWidth + 4 + gap(theme, 4)
    },
    scorecard18: {
        fontSize: 14,
        fontWeight: 600,
        width: 18 * editscoreCellSize + editscoreLabelWidth + 1
    },
    scorecard9: {
        fontSize: 14,
        fontWeight: 600,
        width: 9 * editscoreCellSize + editscoreLabelWidth + 1
    },
    scorecard20: {
        fontSize: 14,
        fontWeight: 600,
        width: 20 * editscoreCellSize + editscoreLabelWidth + 1
    },
    scorecard10: {
        fontSize: 14,
        fontWeight: 600,
        width: 10 * editscoreCellSize + editscoreLabelWidth + 1
    },
    editscoreActions: {
        margin: theme.spacing(2),
        '& >:nth-child(2)': {
            marginRight: 'auto'
        }
    },
    editscoreStableford: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.getContrastText(theme.palette.grey[200])
    },
    editscoreTotal: {
        fontWeight: 600,
        marginTop: 10
    },
    holeRow: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main)
    },
    parRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    hcpRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    hcpGross: {
        fontWeight: 500,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.getContrastText(theme.palette.grey[200])
    },
    hcpGrossCells: {
        backgroundColor: 'white',
        overflow: 'hidden'
    },
    hcpNet: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.getContrastText(theme.palette.grey[200])
    },
    publicBannerContainer: {
        paddingTop: '25%',
        boxSizing: 'border-box',
        position: 'relative'
    },
    publicBanner: {
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    publicBadge: {
        position: 'absolute',
        objectFit: 'cover',
        backgroundColor: theme.palette.common.white,
        bottom: -headerShift,
        width: badgeSize,
        height: badgeSize,
        left: theme.spacing(2),
        borderRadius: badgeSize / 2,
        border: '1px solid ' + theme.palette.grey[300]
    },
    publicName: {
        color: theme.palette.getContrastText(theme.palette.common.black),
        textShadow: '1px 1px 2px black',
        maxWidth: '100%',
        fontSize: '2.0rem',
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingLeft: badgeSize + gap(theme, 3),
        paddingRight: theme.spacing(1),
        boxSizing: 'border-box'
    },
    quillEd: {
        height: '100%'
    },
    radioTwoLines: {
        marginTop: theme.spacing(0.5)
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    },
    '@media (max-width: 1440px)': {
        tvBadge: {
            margin: 15,
            width: 60,
            height: 60,
            borderRadius: 60 / 2,
            border: '1px solid ' + theme.palette.grey[300],
        },
        tvHeader: {
            marginBottom: 15,
            height: 100,
            fontSize: 30,
        },
        tvHeaderSecondary: {
            fontSize: 24,
        },
        tvListItemHeader: {
            fontSize: 14,
        },
        tvItem: {
            height: 40
        },
        tvListItem: {
            fontSize: 16,
        },
        tvAvatar: {
            width: 24,
            height: 24,
            borderRadius: 24 / 2,
            margin: 5,
        },
        tvLink: {
            fontSize: 20,
        },
    },
    '@media (max-width: 960px)': {
        tvListItemHeader: {
            fontSize: 12,
        },
        loginPaper: {
            width: '100%',
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
        welcomeHeader1: {
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '100%',
            paddingTop: 52
        },
    },
    '@media (max-width: 640px)': {
        tvListItemHeader: {
            fontSize: 9,
        },
        listItemHeader: {
            fontSize: '0.55rem',
        },
        listItemHeaderWhite: {
            fontSize: '0.55rem',
        },
        emailDialog: {
            width: '100%',
        },
        listItem1: {
            fontSize: '0.70rem',
        },
        listItem: {
            fontSize: '0.70rem',
        },
        tabPub: {
            fontSize: '.9rem',
        },
        tabRootPub: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0),
        },
        publicBadge: {
            bottom: 0,
            width: badgeSizeSmall,
            height: badgeSizeSmall,
        },
        publicName: {
            paddingLeft: badgeSizeSmall + gap(theme, 3),
            fontSize: '1.5rem',
        },
        loginPaper: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
        }
    },
    '@media (max-width: 340px)': {
        loginPaper: {
            width: '100%',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        publicBadge: {
            display: 'none',
        },
        publicName: {
            paddingLeft: theme.spacing(3),
            fontSize: '1.4rem',
        },
    },
    '@media (max-device-aspect-ratio: 4/3)': {
        tvRoot: {
            backgroundSize: '133%',
        }
    }
} as StyleRules);

export const appStyles = makeStyles(styles);
