import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { IconButton, MenuItem, Menu, TextField, InputAdornment, DialogContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RecentCoursesSelectionDialog from './RecentCoursesSelectionDialog';
import { Facility, CourseResponse, ResultStatus } from '../../../../../types/EventTypes';
import { XSMobileDialog } from '../../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../../common/dialog/DialogAppBar';
import { toSafeString } from '../../../../../util/utility';
import { processEnterKey } from '../../../../../util/react_utils';
import { styles } from '../../../../../styles';
import { FacilityList } from './FacilityView';
import { saveToRecent } from './providers/RecentProvider';
import LocationFacilitiesProvider from './providers/LocationFacilitiesProvider';
import AddressFacilitiesProvider from './providers/AddressFacilitiesProvider';

const SELECT_ACTIONS = ['PREVIOUS COURSES', 'NEAR ME', 'SEARCH BY CITY OR COURSE NAME'];
type SELECT_ACTIONS = 'PREVIOUS COURSES' | 'NEAR ME' | 'SEARCH BY CITY OR COURSE NAME' | 'MENU' | '';

export interface FacilitySelectProps {
    userId: string;
    handleClose: () => void;
    handleFacilitySelect: (selectedFacility: Facility) => void;
}

class CourseSelectionMenu extends React.Component<{ anchorSelect: any } & FacilitySelectProps> {
    state: { selectedAction: SELECT_ACTIONS } = { selectedAction: 'MENU' };
    private handleActionSelect = (res: SELECT_ACTIONS) => () => this.setState({ selectedAction: res });
    private handleFacilitySelect = (selectedFacility: Facility) => {
        const { userId, handleFacilitySelect } = this.props;
        if (selectedFacility) {
            saveToRecent(selectedFacility, userId);
        }
        handleFacilitySelect(selectedFacility);
    }
    render() {
        const { anchorSelect, userId, handleClose } = this.props;
        const { selectedAction } = this.state;
        const availableActions = SELECT_ACTIONS;
        return (
            <React.Fragment>
                {selectedAction === 'MENU' &&
                    <Menu anchorEl={anchorSelect}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={true} onClose={handleClose}>
                        {SELECT_ACTIONS.map(a => <MenuItem key={a} disabled={!availableActions.includes(a as SELECT_ACTIONS)} onClick={this.handleActionSelect(a as SELECT_ACTIONS)}>{a}</MenuItem>)}
                    </Menu>}
                {selectedAction === 'PREVIOUS COURSES' && <RecentCoursesSelectionDialog userId={userId} handleFacilitySelect={this.handleFacilitySelect} handleClose={handleClose} />}
                {selectedAction === 'NEAR ME' && <NearmeSelectionDialog userId={userId} handleFacilitySelect={this.handleFacilitySelect} handleClose={handleClose} />}
                {selectedAction === 'SEARCH BY CITY OR COURSE NAME' && <SearchSelectionDialog userId={userId} handleFacilitySelect={this.handleFacilitySelect} handleClose={handleClose} />}
            </React.Fragment>
        );
    }
}

class NearmeSelectionDialog extends React.Component<FacilitySelectProps> {
    state: { loadStatus: ResultStatus, loadResult: string, facilities?: Array<Facility> } =
        { loadStatus: 'in_progress', loadResult: '' }; 
    private onResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const facilities = courseResponse?.facilities;
        const loadResult = err ?? '';
        this.setState({ facilities, loadStatus, loadResult });
    }
    render() {
        const { userId, handleFacilitySelect, handleClose } = this.props;
        const { facilities, loadStatus, loadResult } = this.state;
        return (
            <XSMobileDialog open={true} onClose={handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Select course" close={handleClose} />
                <FacilityList userId={userId} facilitiesGroups={[{ facilities, loadStatus, loadResult }]} onSelect={handleFacilitySelect} />
                <LocationFacilitiesProvider withTeesOnly={true} onResult={this.onResult} />
            </XSMobileDialog>
        );
    }
}

class SearchSelectionDialogImpl extends React.Component<FacilitySelectProps & WithStyles<typeof styles>> {
    state: { loadStatus: ResultStatus, address: string, loadResult: string, facilities?: Array<Facility> } =
        { loadStatus: 'ok', address: '', loadResult: '' };
    private readonly coursesLoader: React.RefObject<AddressFacilitiesProvider>;
    constructor(props: FacilitySelectProps & WithStyles<typeof styles>) {
        super(props);
        this.coursesLoader = React.createRef();
    }
    private onResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const address = courseResponse?.city.formattedString ?? '';
        const facilities = courseResponse?.facilities;
        const loadResult = err ?? '';
        this.setState({ address, facilities, loadStatus, loadResult });
    }
    private handleSearch = () => this.coursesLoader.current?.load();
    private setAddress = (address: string) => this.setState({ address });
    render() {
        const { userId, classes, handleFacilitySelect, handleClose } = this.props;
        const { address, facilities, loadStatus, loadResult } = this.state;
        return (
            <XSMobileDialog open={true} onClose={handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Select course" close={handleClose} />
                <DialogContent>
                    <TextField
                        variant="standard"
                        id="city"
                        placeholder="Search by city or course name"
                        autoFocus
                        value={address}
                        onKeyDown={e => processEnterKey(e, this.handleSearch)}
                        onChange={e => this.setAddress(toSafeString(e.target.value))}
                        className={classes.searchField}
                        InputProps={{
                            autoComplete: 'off',
                            endAdornment: (<InputAdornment position="end"><IconButton onClick={this.handleSearch} size="large"><SearchIcon /></IconButton></InputAdornment>)
                        }} />
                    <FacilityList userId={userId} spaceIfNotLoaded={true} facilitiesGroups={[{ facilities, loadStatus, loadResult }]} onSelect={handleFacilitySelect} />
                </DialogContent>
                <AddressFacilitiesProvider withTeesOnly={true} address={address} onResult={this.onResult} ref={this.coursesLoader} />
            </XSMobileDialog>
        );
    }
}

const SearchSelectionDialog = withStyles(styles)(SearchSelectionDialogImpl);

export default CourseSelectionMenu;
