import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions } from '@mui/material';
import { Event } from 'src/types/EventTypes';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import { styles } from 'src/styles';
import { PracticeRoundPage } from 'src/event/list/PracticeRoundPage';
import * as Backend from 'src/util/firebase';
import { withProgress } from 'src/util/ProgressPromise';
import { elog } from 'src/event/Event';

type Props = { event: Event; } & WithStyles<typeof styles> & DialogProps;
type State = { practiceRound: boolean; practiceRoundInfo: string; };

class PracticeRoundSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            practiceRound: Boolean(props.event.practiceRoundInfo),
            practiceRoundInfo: props.event.practiceRoundInfo ?? '',
        };
    }

    private handleSave = () => {
        const { event } = this.props;
        const { practiceRound, practiceRoundInfo } = this.state;
        withProgress(Backend.update(Backend.eventsDb, { id: event.id, practiceRoundInfo: practiceRound ? practiceRoundInfo : '' })).then(() => {
            elog(event, `practiceRoundInfo modified`, `practiceRoundInfo: ${practiceRoundInfo}`, `Id: ${event.id}`);
            this.handleClose();
        });
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    render() {
        const { classes, open } = this.props;
        const { practiceRound, practiceRoundInfo } = this.state;
        return <>
            <XSMobileDialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Practice round settiings" close={this.handleClose} />
                <div style={{ padding: '16px' }}>
                    <PracticeRoundPage
                        autoFocus
                        practiceRound={practiceRound}
                        setPracticeRound={p => this.setState({ practiceRound: p })}
                        practiceRoundInfo={practiceRoundInfo ?? ''}
                        setPracticeRoundInfo={val => this.setState({ practiceRoundInfo: val })}
                    />
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave} disabled={practiceRound && !practiceRoundInfo}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        </>;
    }
}

export default withStyles(styles)(PracticeRoundSettingsDialog);
