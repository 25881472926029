import * as React from 'react';
import { Chip, Dialog, DialogActions, DialogContent, Divider, TextField, Slide, ButtonGroup, Menu, MenuItem, FormControl, Popper, Paper, ClickAwayListener, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../util/firebase';
import { Event, EventData, Contact, ContactDetails, ContactInvite, SpreadsheetImportResult, MAX_INVITES_COUNT } from '../../../types/EventTypes';
import { ContactGroup, Email } from '../../Event';
import { indexOfContact, newInvite, emailOrName, contactName, getRosterContactMatcher } from '../../../contact/Contact';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import AddGolfersDialog from './AddGolfersDialog';
import LabeledField from '../../../common/form/LabeledFieldSimple';
import RichTextQuill from '../../../common/form/richtext/RichTextQuill';
import { showError, ProgressFunction } from '../../../redux/ReduxConfig';
import { styles } from '../../../styles';
import { UserAware, userProviderContextTypes } from '../../../auth/Auth';
import { inviteEmailTemplate, sendInviteEmail } from "../../../util/email_utils";
import { PlusIcon } from "../../../common/Icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircle from '@mui/icons-material/CheckCircle';
import InviteEditDialog from "../../../contact/EditInviteDialog";
import { ListItemX, Spacing } from "../../../common/Misc";
import ImportContactDialog from "../../../contact/import/ImportContactDialog";
import { emailFormat, withS } from "../../../util/utility";
import { processArrowDownKey, processArrowUpKey, processEnterKey, processEscKey, processTabKey, processSpaceKey } from "../../../util/react_utils";

interface ContactProps {
    contact: ContactDetails;
    selected: boolean;
    disabled: boolean;
    onSelect: (contact: ContactDetails) => void;
}

const ContactItem = withStyles(styles)((props: ContactProps & WithStyles<typeof styles>) => {
    const { classes, contact, onSelect, selected, disabled } = props;
    const name = contactName(contact);
    const decorations = classes.listItem + (disabled && !selected ? (' ' + classes.disabled) : '') + (selected ? (' ' + classes.selected) : '');
    return (
        <ListItemX
            button={true}
            onClick={() => {
                if (!disabled) {
                    onSelect(contact)
                }
            }}
            className={decorations}>
            <Grid container alignItems="center" xs={12}>
                <Grid container direction={'column'} xs={disabled ? 11 : 12}>
                    <Grid>
                        <Typography>{name}</Typography>
                    </Grid>
                    <Grid>
                        {!!contact.email && <Typography variant="caption" sx={{ color: 'grey', fontSize: '12px' }}>
                            {contact.email}
                        </Typography>}
                    </Grid>
                </Grid>
                {disabled && <Grid xs={1}>
                    <CheckCircle color="disabled" />
                </Grid>}
            </Grid>
        </ListItemX>
    );
});

export interface RosterListProps {
    contacts: Array<ContactDetails>;
    invitesIds: string[];
    participantsIds: string[];
    onSelect: (contact: ContactDetails) => void;
    selectedIdx?: number;
}

const RosterList = withStyles(styles)((props: RosterListProps & WithStyles<typeof styles>) => {
    const renderContactItem = (contact: ContactDetails, key: number, disabled: boolean) => {
        const { onSelect, selectedIdx, classes } = props;
        return (
            <React.Fragment key={String(key)}>
                <React.Fragment key={contact.id}>
                    <ContactItem contact={contact} onSelect={onSelect} selected={key === selectedIdx} disabled={disabled} classes={classes} />
                </React.Fragment>
            </React.Fragment>
        );
    }
    const { contacts, invitesIds, participantsIds } = props;
    const unselectableIds: string[] = [];
    unselectableIds.push(...participantsIds);
    unselectableIds.push(...invitesIds);
    return (
        <React.Fragment>
            {contacts.map((contact, idx) => renderContactItem(contact, idx, unselectableIds.findIndex(id => id === contact.id) > -1))}
        </React.Fragment>
    );
});

type RosterListWrappedProps = {
    onSelect: (contact: ContactDetails) => void,
    rosterList: Array<ContactDetails>,
    invitesIds: Array<string>,
    participantsIds: Array<string>,
    onClickAway: () => void
    selectedIdx?: number;
};

const RosterListWrapped = withStyles(styles)((props: RosterListWrappedProps & WithStyles<typeof styles>) => {
    const { onSelect, rosterList, onClickAway, selectedIdx, classes, invitesIds, participantsIds } = props;
    const selectedIndex = (selectedIdx ?? 0) % rosterList.length;
    return (
        <Paper style={{ overflow: 'auto', marginTop: 10, maxHeight: 500 }}>
            <Spacing />
            <ClickAwayListener onClickAway={() => onClickAway()}>
                <div>
                    <RosterList contacts={rosterList} invitesIds={invitesIds} participantsIds={participantsIds} onSelect={onSelect} selectedIdx={selectedIndex} classes={classes} />
                </div>
            </ClickAwayListener>
            <Spacing />
        </Paper>
    );
});

interface NewEmailProps {
    open: boolean;
    event: Event;
    eventData: EventData;
    initialInvites: ContactInvite[];
    excludedFromPast?: ContactDetails[];
    handleCancel: () => void;
    handleSent: () => void;
}

interface State {
    inputValue: string;
    editedRecipient?: ContactInvite;
    filteredRoster: Array<ContactDetails>;
    inputHelper?: string;
    addRecipientsMenuOpen: boolean;
    selectRecipientsDialogOpen: boolean;
    editRecipientDialogOpen: boolean;
    importingFromSpreadsheet: boolean;
    directEmailInput: boolean;
    rosterOpen: boolean;
    newMail: Email;
    rosterSelectedIdx?: number;
}

const Transition = (props: any) => <Slide direction="up" {...props} />;
type Props = NewEmailProps & WithStyles<typeof styles>;

class InvitesEmailDialog extends React.Component<Props, State> {
    private readonly addMenuRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly inputRef: React.RefObject<HTMLDivElement> = React.createRef();

    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    constructor(props: Props) {
        super(props);
        const { event, initialInvites, eventData } = this.props;

        const contactDetails: ContactDetails[] = initialInvites.map(invite => {
            return {
                ...invite!,
                hidden: false,
                firstName: invite.firstName,
                lastName: invite.lastName ?? ''
            } as ContactDetails
        });

        this.state = {
            inputValue: '',
            addRecipientsMenuOpen: false,
            editRecipientDialogOpen: false,
            selectRecipientsDialogOpen: false,
            importingFromSpreadsheet: false,
            rosterOpen: false,
            directEmailInput: false,
            filteredRoster: [],
            newMail: {
                subject: `Tournament invitation!`,
                replyTo: eventData.adminEmail ?? '',
                recipients: contactDetails.filter(c => Boolean(c.email)),
                text: inviteEmailTemplate(event, eventData.adminEmail ?? '')
            },
            editedRecipient: undefined
        };
    }

    private toList() {
        const { classes, initialInvites } = this.props;
        const { addRecipientsMenuOpen, directEmailInput } = this.state;
        const renderAddButton = !initialInvites.length && !directEmailInput;
        return (
            <div className={classes.chipContainer + ' ' + classes.scrollPanel}>
                {this.toListChips()}
                {directEmailInput && this.input()}
                {renderAddButton && <ButtonGroup ref={this.addMenuRef} className={classes.chipMargin} style={{ display: '-webkit-inline-box' }}>
                    <AppButton
                        color="secondary" onClick={this.handleAddClick}>
                        <PlusIcon sx={{ width: '.7em', height: '.7em' }} className={classes.leftButtonIcon} /><Spacing />Add
                    </AppButton>
                    <AppButton
                        color={addRecipientsMenuOpen ? 'info' : 'secondary'}
                        onClick={this.handleAddArrowClick}
                        sx={{ width: '10px', margin: '0px', padding: '0px' }}>
                        <ArrowDropDownIcon className={classes.rightButtonIcon} />
                    </AppButton>
                </ButtonGroup>}
                <Menu
                    open={this.state.addRecipientsMenuOpen}
                    anchorEl={this.addMenuRef.current}
                    onClose={this.handleRecipientsMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <MenuItem onClick={this.handleAddContactClick}>New invited contact</MenuItem>
                    <MenuItem onClick={this.handleAddFromRosterClick}>From past events</MenuItem>
                    <MenuItem onClick={this.handleAddFromSpreadsheet}>Import from spreadsheet</MenuItem>
                </Menu>
            </div>
        );
    }

    private toListChips() {
        const { classes } = this.props;
        const { newMail } = this.state;
        return newMail.recipients.map(recipient => (
            <Chip key={recipient.id}
                label={emailOrName(recipient)}
                className={classes.chip}
                onDelete={() => this.handleDeleteRecipient(recipient)} />));
    }

    private handleClose = () => this.props.handleCancel();
    private handleAddClick = () => this.setState({ addRecipientsMenuOpen: false, editRecipientDialogOpen: false, directEmailInput: true });
    private handleAddArrowClick = () => this.setState({ addRecipientsMenuOpen: !this.state.addRecipientsMenuOpen });
    private handleRecipientsMenuClose = () => this.setState({ addRecipientsMenuOpen: false });
    private handleAddContactClick = () => this.setState({ addRecipientsMenuOpen: false, editRecipientDialogOpen: true, editedRecipient: newInvite() });
    private handleAddFromRosterClick = () => this.setState({ addRecipientsMenuOpen: false, selectRecipientsDialogOpen: true });
    private handleAddFromSpreadsheet = () => this.setState({ importingFromSpreadsheet: true, addRecipientsMenuOpen: false });
    private selectRecipientsDialogClose = () => this.setState({ selectRecipientsDialogOpen: false });

    private input() {
        const { classes, eventData } = this.props;
        const { inputHelper, inputValue, rosterOpen, rosterSelectedIdx, newMail, filteredRoster } = this.state;
        const id = rosterOpen ? 'roster-popper' : undefined;
        const alreadyRecipient = newMail.recipients.filter(r => !!r.id).map(r => r.id);
        const invitesIds = Array.from(eventData.invitedContacts.values()).map(i => i.id);
        invitesIds.push(...alreadyRecipient);
        const participantsIds = Array.from(eventData.golfersAggregated.values()).map(p => p.id);
        return <>
            <FormControl variant="standard" margin="dense" className={classes.chipMargin} style={{ display: '-webkit-inline-box' }}>
                <TextField
                    id="course"
                    variant="standard"
                    ref={this.inputRef}
                    value={inputValue}
                    placeholder="Input email or contact name"
                    helperText={inputHelper}
                    error={!!inputHelper}
                    style={{ width: 240 }}
                    onFocus={() => {
                        if (!rosterOpen) {
                            if (emailFormat.test(this.state.inputValue!)) {
                                this.handleSubmitDirectMail();
                            } else {
                                this.handleEscDirectInput();
                            }
                        }
                    }}
                    onBlur={() => {
                        if (!rosterOpen) {
                            if (emailFormat.test(this.state.inputValue!)) {
                                this.handleSubmitDirectMail();
                            } else {
                                this.handleEscDirectInput();
                            }
                        }
                    }}
                    onKeyDown={e => {
                        processArrowDownKey(e, this.handleShiftPaperSelectionDown, !rosterOpen);
                        processArrowUpKey(e, this.handleShiftPaperSelectionUp, !rosterOpen);
                        processTabKey(e, this.handleSubmitPaper, !rosterOpen);
                        processEscKey(e, this.handleEscDirectInput);
                        processEnterKey(e, this.handleSubmitPaper);
                        processSpaceKey(e, rosterOpen ? this.handleSubmitPaper : () => { });
                    }}
                    onChange={this.handleInput}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        style: { width: '100%' },
                        autoComplete: 'off',
                        autoFocus: true,
                        onFocus: e => { }
                    }}
                />
            </FormControl>
            <Popper
                id={id}
                sx={{ zIndex: 1500, width: this.inputRef.current?.clientWidth ?? 150 }}
                placement='bottom-start'
                open={Boolean(rosterOpen) && Boolean(this.inputRef.current) && Boolean(inputValue)}
                anchorEl={this.inputRef.current}>
                <RosterListWrapped
                    onSelect={(contact) => {
                        this.handleAddRecipient({ id: contact.id, email: contact.email, firstName: contact.firstName, lastName: contact.lastName } as ContactInvite);
                        this.setState({ directEmailInput: false, inputValue: '', rosterOpen: false });
                    }}
                    rosterList={filteredRoster}
                    invitesIds={invitesIds}
                    participantsIds={participantsIds}
                    onClickAway={() => this.setState({ rosterOpen: false })}
                    selectedIdx={rosterSelectedIdx}
                />
            </Popper>
        </>
    }

    private subject() {
        return (
            <TextField
                variant="standard"
                fullWidth
                value={this.state.newMail.subject}
                onChange={e => this.handlePropChange('subject', e.target.value)} />
        );
    };

    private replyTo() {
        const user = Backend.firebaseAuth.currentUser;
        return (
            <TextField
                variant="standard"
                type="email"
                value={this.state.newMail.replyTo || user?.email || ''}
                fullWidth
                onChange={e => this.handlePropChange('replyTo', e.target.value)} />
        );
    };

    private handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { eventData } = this.props;
        const inputValue = e.target.value;
        const filteredRoster = inputValue?.length > 0
            ? Array.from(eventData.roster.values()).filter(getRosterContactMatcher(e.target.value))
            : [];
        if (filteredRoster.length == 0) {
            if (!!inputValue && inputValue.length > 0) {
                const cleanText = inputValue!.trim();
                if (emailFormat.test(cleanText)) {
                    filteredRoster.push({ email: cleanText, lastName: '', hidden: false, id: '' });
                    this.setState({ filteredRoster, rosterOpen: true, inputValue, rosterSelectedIdx: 0 });
                } else {
                    this.setState({ inputValue: e.target.value, filteredRoster, inputHelper: undefined, rosterOpen: false, rosterSelectedIdx: 0 });
                }
            } else {
                this.setState({ inputValue: e.target.value, filteredRoster });
            }
        } else {
            this.setState({ inputValue: e.target.value, filteredRoster, rosterOpen: true });
        }

    }

    private handleContentChange = (value: any) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            if (!value) {
                return;
            }
            const newMail = this.state.newMail;
            newMail['text'] = value;
            this.setState({ newMail });
        }, 500);
    };

    private handleSubmitDirectMail = () => {
        const { inputValue } = this.state;
        if (!!inputValue && inputValue.length > 0) {
            if (emailFormat.test(inputValue!)) {
                this.handleAddRecipient({ email: inputValue } as ContactInvite)
                this.setState({ directEmailInput: false, inputValue: '', inputHelper: undefined, rosterOpen: false });
            } else {
                this.setState({ inputHelper: 'Invalid Email.' });
            }
        } else {
            this.setState({ directEmailInput: false });
        }
    }

    private handleSubmitPaper = () => {
        const { eventData } = this.props;
        const { newMail, filteredRoster } = this.state;
        if (filteredRoster.length > 0) {
            const index = Math.abs(this.state.rosterSelectedIdx ?? 0) % filteredRoster.length;
            const recipient = filteredRoster[index];
            const alreadyRecipient = newMail.recipients.filter(r => !!r.id).map(r => r.id);
            const invitesIds = Array.from(eventData.invitedContacts.values()).map(i => i.id);
            invitesIds.push(...alreadyRecipient);
            const participantsIds = Array.from(eventData.golfersAggregated.values()).map(p => p.id);
            if (invitesIds.findIndex(id => id === recipient.id) === -1 && participantsIds.findIndex(id => id === recipient.id) === -1) {
                newMail.recipients.push(recipient);
                this.setState({ editRecipientDialogOpen: false, newMail, rosterOpen: false, inputValue: '', inputHelper: undefined, directEmailInput: false });
            }
        } else {
            this.setState({ inputHelper: 'Invalid Email.' });
        }
    }

    private handleEscDirectInput = () => {
        this.setState({ directEmailInput: false, inputValue: '', inputHelper: undefined, rosterOpen: false, rosterSelectedIdx: 0 });
    }

    private handleShiftPaperSelectionDown = () => {
        this.setState({ rosterSelectedIdx: (this.state.rosterSelectedIdx ?? 0) + 1 });
    }

    private handleShiftPaperSelectionUp = () => {
        this.setState({ rosterSelectedIdx: (this.state.rosterSelectedIdx ?? 0) - 1 });
    }

    private handleCloseEditDialog = () => this.setState({ editRecipientDialogOpen: false, editedRecipient: undefined });

    private timeoutId?: any;

    private content() {
        return (
            <RichTextQuill value={this.state.newMail.text} onChange={val => this.handleContentChange(val)} />
        );
    }

    private evalFreeSlots() {
        const { eventData } = this.props;
        return MAX_INVITES_COUNT - eventData.invitedContacts.size;
    }

    private handleSend = () => {
        sendInviteEmail(this.state.newMail, this.props.eventData)
            .then(this.props.handleSent);
    };

    private handleDeleteRecipient = (recipient: Contact) => {
        const newMail = this.state.newMail;
        newMail.recipients.splice(indexOfContact(newMail.recipients, recipient.id), 1);
        this.setState({ newMail });
    }

    private handleAddRecipient = (recipient: ContactInvite) => {
        const newMail = this.state.newMail;
        if (newMail.recipients.length < this.evalFreeSlots()) {
            newMail.recipients.push({ ...recipient, lastName: recipient.lastName ?? '', firstName: recipient.firstName ?? '', hidden: false });
        } else {
            showError('Max invites count exceed.');
        }
        this.setState({ editRecipientDialogOpen: false, newMail });
    }

    private handleAddRecipientsGroup = (contacts: Array<ContactGroup>) => {
        const newMail = this.state.newMail;
        const contactDetails: ContactDetails[] = contacts
            .filter(contactItem => contactItem.contact && newMail.recipients.findIndex(c => c.id === contactItem.contact!.id) === -1)
            .map(contactItem => {
                return {
                    ...contactItem.contact!,
                    hidden: false,
                    lastName: contactItem.contact?.lastName ?? '',
                    firstName: contactItem.contact?.firstName ?? ''
                } as ContactDetails
            })
            .slice(0, this.evalFreeSlots() - newMail.recipients.length);
        newMail.recipients.push(...contactDetails);
        this.setState({ selectRecipientsDialogOpen: false, newMail });
    }

    private handlePropChange = (prop: keyof Email, value: any) => {
        const newMail = this.state.newMail;
        newMail[prop] = value;
        this.setState({ newMail });
    }

    private handleImportResults = (result: SpreadsheetImportResult, progress?: ProgressFunction) => {
        const newMail = this.state.newMail;
        const contactDetails: ContactDetails[] = result.contacts.filter(contactItem => contactItem && newMail.recipients.findIndex(c => c.email === contactItem.email) === -1).map(contactItem => {
            return {
                ...contactItem,
                hidden: false,
                lastName: contactItem.lastName ?? ''
            } as ContactDetails
        })
            .slice(0, this.evalFreeSlots() - newMail.recipients.length);
        newMail.recipients.push(...contactDetails);
        if (progress) {
            progress(`${withS(contactDetails.length, 'email')} added to the recipient list.`);
        }
        this.setState({ selectRecipientsDialogOpen: false, importingFromSpreadsheet: false, newMail });
    }

    render() {
        const { classes, event, eventData } = this.props;
        const { newMail, selectRecipientsDialogOpen, editRecipientDialogOpen, editedRecipient, importingFromSpreadsheet } = this.state;
        const selectedGolfersIds = newMail.recipients.map(contact => contact.id);
        const excludedGolfersIds = Array.from(eventData.invitedContacts.values()).map(invite => invite.id);
        const toTitle = 'To: (' + this.state.newMail.recipients.length + ' total)';
        return (
            <Dialog
                fullScreen
                maxWidth='xl'
                className={classes.emailDialog}
                open={this.props.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}>
                {importingFromSpreadsheet && <ImportContactDialog
                    open={importingFromSpreadsheet}
                    importResult={this.handleImportResults}
                    handleCancel={() => this.setState({ importingFromSpreadsheet: false })} />}
                {editRecipientDialogOpen && editedRecipient && <InviteEditDialog
                    open
                    event={event}
                    eventData={eventData}
                    initialInvite={editedRecipient!}
                    handleSave={this.handleAddRecipient}
                    handleClose={this.handleCloseEditDialog}
                />}
                {selectRecipientsDialogOpen && <AddGolfersDialog
                    open={selectRecipientsDialogOpen}
                    label={'Select recipients'}
                    okLabel={'Add'}
                    {...{
                        eventOrRound: event,
                        golfers: eventData.roster,
                        teams: new Map(),
                        groups: [],
                        roster: eventData.roster,
                        loadedTeams: 1,
                        loadedGroups: 1,
                        loadedGolfers: 1,
                        loadedRoster: 1
                    }}
                    golferDB={'GOLFERS_EMAILS'}
                    handleAddGolfers={this.handleAddRecipientsGroup}
                    handleCancel={this.selectRecipientsDialogClose}
                    selectedGolfersIds={selectedGolfersIds}
                    excludedGolfersIds={excludedGolfersIds} />}
                <DialogContent>
                    <LabeledField label={toTitle} value={this.toList()} />
                    <Divider />
                    <LabeledField label="Reply-To:" value={this.replyTo()} />
                    <Divider />
                    <LabeledField label="Subject:" value={this.subject()} />
                    <Divider />
                    <LabeledField label="Content:" value={this.content()} />
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <AppButton color="info" onClick={this.handleClose}>Close</AppButton>
                        <AppButton color="secondary" onClick={this.handleSend}
                            disabled={newMail.recipients.length === 0 || !newMail.subject.trim()}>
                            <SendIcon
                                className={classes.leftButtonIcon} />Send
                        </AppButton>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(InvitesEmailDialog);