import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as Backend from '../util/firebase';
import { showProgress } from '../redux/ReduxConfig';
import { logInfo } from 'src/util/utility';

export interface UserAware {
    user?: Backend.User | null;
    loggedIn?: boolean;
    hasSup?: boolean;
    hasPro?: boolean;
    signingOut?: boolean;
    hasUpdated?: boolean;
    effectiveUserId?: string;
    eventId?: string;
    setEffectiveUserId: (effectiveUserId?: string) => void;
    setSigningOut: (signingOut?: boolean) => void;
    setHasUpdated: (hasUpdated?: boolean) => void;
    setEventId: (eventId?: string) => void;
}

export const userProviderContextTypes = {
    user: PropTypes.object,
    hasSup: PropTypes.bool,
    hasPro: PropTypes.bool,
    loggedIn: PropTypes.bool,
    signingOut: PropTypes.bool,
    hasUpdated: PropTypes.bool,
    effectiveUserId: PropTypes.string,
    eventId: PropTypes.string,
    setEffectiveUserId: PropTypes.func,
    setSigningOut: PropTypes.func,
    setHasUpdated: PropTypes.func,
    setEventId: PropTypes.func,
};

class UserProvider extends React.Component<{ children?: React.ReactNode[] }, UserAware> {
    private authUnsubscribe?: () => void;
    private userUnsubscribe?: () => void;

    state: UserAware = {
        setEffectiveUserId: () => { },
        setSigningOut: () => { },
        setHasUpdated: () => { },
        setEventId: () => { },
    };

    static childContextTypes = userProviderContextTypes;

    getChildContext(): UserAware {
        return { ...this.state };
    }

    setEffectiveUserId = (effectiveUserId?: string) => this.setState({ effectiveUserId });
    setSigningOut = (signingOut?: boolean) => this.setState({ signingOut });
    setHasUpdated = (hasUpdated?: boolean) => this.setState({ hasUpdated });
    setEventId = (eventId?: string) => this.setState({ eventId });

    componentDidMount() {
        this.initAuthState();
    }

    private initAuthState = () => {
        const hideProgress = showProgress();
        this.setState({
            setEffectiveUserId: this.setEffectiveUserId,
            setSigningOut: this.setSigningOut,
            setHasUpdated: this.setHasUpdated,
            setEventId: this.setEventId,
        });
        this.authUnsubscribe = Backend.firebaseAuth.onAuthStateChanged(user => {
            if (this.userUnsubscribe) {
                this.userUnsubscribe();
                this.userUnsubscribe = undefined;
            }
            if (!user) {
                this.setState({ user, hasSup: false, hasPro: false, loggedIn: false });
                hideProgress();
                return;
            }
            this.userUnsubscribe = Backend.onSnapshot(Backend.doc(Backend.accessDb, user.uid), async doc => {
                hideProgress();
                const data = doc.data() as any;
                logInfo(`u ${doc.id} ${data?.sup} ${data?.pro}`);
                let hasSup = false;
                let hasPro = false;
                if (data) {
                    hasSup = data.sup;
                    hasPro = data.pro;
                }
                this.setState({ user, hasSup, hasPro, loggedIn: true });
            }, (err: any) => hideProgress(err.message));
        });
    }

    componentWillUnmount() {
        if (this.userUnsubscribe) {
            this.userUnsubscribe();
        }
        if (this.authUnsubscribe) {
            this.authUnsubscribe();
        }
        this.userUnsubscribe = undefined;
        this.authUnsubscribe = undefined;
    }

    render() {
        return (<React.Fragment>{this.props.children}</React.Fragment>);
    }
}

export default UserProvider;

export interface WithUserData {
    data: { [field: string]: any };
}

export const withUserData = function <P>(Component: React.ComponentType<P & UserAware>) {
    return class extends React.Component<P> {
        static contextTypes = userProviderContextTypes;
        context!: UserAware;
        render() {
            return (<Component {...this.props} {...this.context} />);
        }
    };
};

export interface WithUserId {
    userId: string;
    sup?: boolean;
    pro?: boolean;
}

export const withUserId = function <P>(Component: React.ComponentType<P & WithUserId>) {
    return class extends React.Component<P> {
        static contextTypes = userProviderContextTypes;
        context!: UserAware;
        render() {
            const { user, effectiveUserId, hasSup, hasPro } = this.context;
            if (!user) {
                return null;
            }
            const uid = effectiveUserId ? effectiveUserId : user.uid;
            return (<Component {...this.props} userId={uid} sup={hasSup} pro={hasPro} />);
        }
    };
};
