import { WithStyles } from "@mui/styles";
import { styles } from "../styles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { Box, Divider, MenuItem, Select} from "@mui/material";
import { AppColors } from "../main/Theme";
import { BlueEmailIcon } from "../common/Icons";


type PaidStatusSelectionComponentProps = {
    feePaid?: boolean;
    contactEmail?: string;
    styles: React.CSSProperties;
    disableUnderline: boolean;
    horizontalPopupPlacement?: 'left' | 'center' | 'right' | number;
    handleSendMessage?: () => void;
    handleFeePaidStatusUpdate: (feePaid: boolean) => Promise<void>;
} & WithStyles<typeof styles>;

export const PaidStatusSelectionComponent = withStyles(styles)((props: PaidStatusSelectionComponentProps) => {
    const {
        feePaid, contactEmail, styles, disableUnderline, classes, horizontalPopupPlacement, handleSendMessage,
        handleFeePaidStatusUpdate
    } = props;
    const [paidStatus, setPaidStatus] = React.useState(feePaid ? 'Paid' : 'Unpaid');
    React.useEffect(
        () => {
            const newStatus = feePaid ? 'Paid' : 'Unpaid';
            if (newStatus !== paidStatus) {
                setPaidStatus(newStatus);
            }
        },
        [feePaid]
    );
    return (
        <Select id="select_paid_status"
                variant="standard"
                onClick={e => e.stopPropagation()}
                value={paidStatus}
                disableUnderline={disableUnderline}
                sx={{
                    '& .MuiSelect-select': {
                        paddingTop: 0,
                        paddingBottom: 0
                    },
                    color: paidStatus === 'Unpaid' ? AppColors.webError : 'unset', ...styles
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: horizontalPopupPlacement ?? 'left'
                    }
                }}
                SelectDisplayProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0)'
                    }
                }}
                inputProps={{
                    classes: {
                        icon: classes.blueArrowDown
                    }
                }}
                onChange={async e => {
                    if (e.target.value && e.target.value !== paidStatus) {
                        await handleFeePaidStatusUpdate(e.target.value === 'Paid');
                        setPaidStatus(e.target.value);
                    }
                }}>
            <MenuItem style={{ fontSize: 14, lineHeight: '21px' }} value="Paid">Paid</MenuItem>
            <MenuItem style={{ fontSize: 14, lineHeight: '21px' }} value="Unpaid">Unpaid</MenuItem>
            {handleSendMessage && <Box>
                <Divider variant="middle" />
                <MenuItem style={{ fontSize: 14, lineHeight: '21px', color: AppColors.webBlue500 }}
                          disabled={!contactEmail}
                          onClick={handleSendMessage}>
                    <BlueEmailIcon />
                    <Box width={4} />
                    Send PayPal link
                </MenuItem>
            </Box>}
        </Select>
    );
});
