import { Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import { Container, ListElem, Spacing } from "../../common/Misc";
import TextField, { maxLength, validPriceAmount } from "../../validation/ValidatedTextField";
import { formatCurrency } from "../../util/utility";
import * as React from "react";
import { appStyles } from "../../styles";
import { EventPaymentSettings } from "../../types/EventTypes";
import LabeledField from "../../common/form/LabeledField";
import { PayPalSettingsDialog } from "./PayPalSettingsDialog";
import CurrencyList from 'currency-list';
import DialogAppBar from "../../common/dialog/DialogAppBar";
import { XSMobileDialog } from "../../common/dialog/MobileDialog";
import { AppColors, theme } from "../../main/Theme";
import AppButton from "../../common/components/AppButton";
import { Urls } from "../../util/config";
import { getPaymentFeeStr } from "../../event/Event";
import { InfoIcon } from "../../common/Icons";

type EntryFeeDialogProps = {
    open: boolean;
    currencySymbol: string;
    cost: number;
    description?: string;
    onClose: () => void;
    onSave: (feeCostStr: string, description?: string) => void;
};

export const EntryFeeDialog = (props: EntryFeeDialogProps) => {
    const classes = appStyles();
    const { open, currencySymbol, cost, description, onClose, onSave } = props;
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [feeCostStr, setFeeCostStr] = React.useState(cost ? `${formatCurrency(cost)}` : '');
    const [feeDescription, setFeeDescription] = React.useState(description ?? '');
    const descriptionRule = maxLength(100);
    const saveButtonDisabled = !(feeCostStr && validPriceAmount.valid(feeCostStr) &&
        descriptionRule.valid(feeDescription));
    return (
        <XSMobileDialog PaperProps={{
            style: {
                maxWidth: isXs ? 360 : 440, margin: isXs ? 28 : 'unset',
                maxHeight: isXs ? 440 : 'unset'
            }
        }} open={open} maxWidth={'xs'} fullWidth={true}>
            <DialogAppBar label="Paypal payments" close={onClose} />
            <DialogContent style={{ padding: 12 }}>
                <Box display="flex" flexDirection="column">
                    <TextField
                        textFieldProps={{
                            id: 'description',
                            label: 'Entry fee',
                            value: feeCostStr,
                            variant: 'standard',
                            onChange: e => setFeeCostStr(e.target.value),
                            style: { width: '100%' },
                            autoFocus: true,
                            InputLabelProps: { shrink: true },
                            InputProps: {
                                startAdornment: <Typography style={{ color: AppColors.webGrey, marginRight: 4 }}>
                                    {currencySymbol}
                                </Typography>
                            }
                        }} rules={[validPriceAmount]} />
                    <Typography style={{ lineHeight: '15.95px', fontSize: 11, color: AppColors.webGrey }}>
                        Determine the entry fee that golfers will pay after registration.
                    </Typography>
                    <Spacing />
                    <TextField
                        textFieldProps={{
                            id: 'description',
                            label: 'Fee description (what it covers)',
                            variant: 'standard',
                            value: feeDescription,
                            placeholder: 'Fee description',
                            onChange: e => setFeeDescription(e.target.value),
                            style: { width: '100%' },
                            autoFocus: true,
                            InputLabelProps: { shrink: true }
                        }}
                        rules={[descriptionRule]} />
                    <Typography style={{ lineHeight: '15.95px', fontSize: 11, color: AppColors.webGrey }}>
                        Tournament participants will see this description when registering
                    </Typography>
                    <Box height={12} />
                    <Box style={{ padding: 8, backgroundColor: AppColors.webGreyLight }} display="flex"
                        flexDirection="row" alignItems="center">
                        <InfoIcon color="secondary" />
                        <Box width={12} />
                        <Typography color={AppColors.webBlack}>
                            Amount above is subject to PayPal fees.
                            &nbsp;
                            <a href={Urls.payPalFeesArticle} target="blank" className={classes.linkBlue}>Learn more</a>
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <AppButton color="info" onClick={onClose}>Cancel</AppButton>
                <AppButton color="secondary"
                    disabled={saveButtonDisabled}
                    onClick={() => onSave(feeCostStr, feeDescription)}>
                    Save
                </AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
};

type PayPalPaymentSettingsProps = {
    paymentSettings: EventPaymentSettings;
    disabled?: boolean;
    onPayPalDialogSave: (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => void;
    setPayeeEmail: (email: string) => void;
    setFeeCostWithDescription: (costStr: string, description?: string) => void;
    setPaymentSettingsEnableStatus: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
    style?: React.CSSProperties;
};

export const PayPalPaymentSettings = (props: PayPalPaymentSettingsProps) => {
    const classes = appStyles();
    const {
        paymentSettings, disabled, setPaymentSettingsEnableStatus, setFeeCostWithDescription,
        onPayPalDialogSave, style
    } = props;
    const payPalTitle = paymentSettings.payeeEmailAddress || 'Not set';
    const payPalSubtitle = 'A verified email address used to accept PayPal payments from tournament participants.';
    const [payPalSettingsOpened, setPayPalSettingsOpened] = React.useState(false);
    const closeSettings = () => setPayPalSettingsOpened(false);
    const openPayPalSettings = () => setPayPalSettingsOpened(true);
    const currencySymbol = React.useMemo(
        () => CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '',
        [paymentSettings.currencyCode]
    );
    const [entryFeeDialogOpened, setEntryFeeDialogOpened] = React.useState(false);
    const closeFeeDialog = () => setEntryFeeDialogOpened(false);
    const openFeeDialog = () => setEntryFeeDialogOpened(true);
    const entryFeeTitle = getPaymentFeeStr(paymentSettings) || 'Set entry fee';
    const entryFeeSubTitle = paymentSettings.feeDescription ?? 'Enter the amount and description what it covers.';
    return <Container style={style}>
        <FormControlLabel
            disabled={disabled}
            className={classes.noSidesMargin}
            style={{ minHeight: 35 }}
            control={<Checkbox color="secondary"
                onChange={setPaymentSettingsEnableStatus}
                checked={paymentSettings.enabled} />}
            label={<span>Enable entry fee payments</span>} />
        {paymentSettings.enabled && <Box display="flex" flexDirection="column">
            <Box>
                <LabeledField label="PayPal settings"
                    itemClass={classes.greyBackground}
                    value={(<ListElem id="paypal_settings"
                        title={payPalTitle}
                        subtitle={payPalSubtitle}
                        titleColor={paymentSettings.payeeEmailAddress ? undefined : '#949494'}
                    />)}
                    edit={openPayPalSettings} />
                {payPalSettingsOpened && <PayPalSettingsDialog
                    emailAddress={paymentSettings.payeeEmailAddress}
                    currencyCode={paymentSettings.currencyCode}
                    onClose={closeSettings}
                    onSave={(email: string, currency: string) => {
                        onPayPalDialogSave(email, currency);
                        closeSettings();
                    }} />}
            </Box>
            <Box height={4} />
            <Box>
                <LabeledField label="Entry fee"
                    itemClass={classes.greyBackground}
                    value={(<ListElem id="entry_fee"
                        title={entryFeeTitle}
                        subtitle={entryFeeSubTitle}
                        titleColor={paymentSettings.feeCost ? undefined : '#949494'} />)}
                    edit={openFeeDialog} />
                <EntryFeeDialog open={entryFeeDialogOpened}
                    currencySymbol={currencySymbol}
                    cost={paymentSettings.feeCost}
                    description={paymentSettings.feeDescription}
                    onClose={closeFeeDialog}
                    onSave={(feeCostStr, description) => {
                        setFeeCostWithDescription(feeCostStr, description);
                        closeFeeDialog();
                    }} />
            </Box>
        </Box>}
    </Container>;
};
