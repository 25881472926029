export const COLOR_NAMES = [
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'black',
    'blanchedalmond',
    'blue',
    'blueviolet',
    'brown',
    'burlywood',
    'cadetblue',
    'chartreuse',
    'chocolate',
    'coral',
    'cornflowerblue',
    'cornsilk',
    'crimson',
    'cyan',
    'darkblue',
    'darkcyan',
    'darkgoldenrod',
    'darkgray',
    'darkgreen',
    'darkgrey',
    'darkkhaki',
    'darkmagenta',
    'darkolivegreen',
    'darkorange',
    'darkorchid',
    'darkred',
    'darksalmon',
    'darkseagreen',
    'darkslateblue',
    'darkslategrey',
    'darkturquoise',
    'darkviolet',
    'deeppink',
    'deepskyblue',
    'dimgray',
    'dimgrey',
    'dodgerblue',
    'firebrick',
    'floralwhite',
    'forestgreen',
    'fuchsia',
    'gainsboro',
    'ghostwhite',
    'gold',
    'goldenrod',
    'gray',
    'green',
    'greenyellow',
    'grey',
    'honeydew',
    'hotpink',
    'indianred',
    'indigo',
    'ivory',
    'khaki',
    'lavender',
    'lavenderblush',
    'lawngreen',
    'lemonchiffon',
    'lightblue',
    'lightcoral',
    'lightcyan',
    'lightgoldenrodyellow',
    'lightgray',
    'lightgreen',
    'lightgrey',
    'lightpink',
    'lightsalmon',
    'lightseagreen',
    'lightskyblue',
    'lightslategrey',
    'lightsteelblue',
    'lightyellow',
    'lime',
    'limegreen',
    'linen',
    'magenta',
    'maroon',
    'mediumaquamarine',
    'mediumblue',
    'mediumorchid',
    'mediumpurple',
    'mediumseagreen',
    'mediumslateblue',
    'mediumspringgreen',
    'mediumturquoise',
    'mediumvioletred',
    'midnightblue',
    'mintcream',
    'mistyrose',
    'moccasin',
    'navajowhite',
    'navy',
    'oldlace',
    'olive',
    'olivedrab',
    'orange',
    'orangered',
    'orchid',
    'palegoldenrod',
    'palegreen',
    'paleturquoise',
    'palevioletred',
    'papayawhip',
    'peachpuff',
    'peru',
    'pink',
    'plum',
    'powderblue',
    'purple',
    'rebeccapurple',
    'red',
    'rosybrown',
    'royalblue',
    'saddlebrown',
    'salmon',
    'sandybrown',
    'seagreen',
    'seashell',
    'sienna',
    'silver',
    'skyblue',
    'slateblue',
    'slategray',
    'snow',
    'springgreen',
    'steelblue',
    'tan',
    'teal',
    'thistle',
    'tomato',
    'turquoise',
    'violet',
    'wheat',
    'white',
    'whitesmoke',
    'yellow',
    'yellowgreen',
];

export function getAvailableColor(color?: string) {
    if (!color) {
        return undefined;
    }
    color = color.trim().toLowerCase();
    return COLOR_NAMES.find(c => c === color);
}

export function getContrastTextColor(fillColor?: string) {
    if (!fillColor) {
        return undefined;
    }
    if (COLOR_NAMES.indexOf(fillColor) < 0) {
        return undefined;
    }
    if (fillColor.indexOf('skyblue') >= 0 ||
        fillColor.indexOf('light') >= 0 ||
        fillColor.indexOf('gold') >= 0 ||
        fillColor.indexOf('white') >= 0 ||
        fillColor.indexOf('yellow') >= 0) {
        return undefined;
    }
    // on dark colors -> white
    if (fillColor.indexOf('medium') >= 0 ||
        fillColor.indexOf('dark') >= 0 ||
        fillColor.indexOf('gray') >= 0 ||
        fillColor.indexOf('grey') >= 0 ||
        fillColor.indexOf('brick') >= 0 ||
        fillColor.indexOf('green') >= 0 ||
        fillColor.indexOf('indigo') >= 0 ||
        fillColor.indexOf('maroon') >= 0 ||
        fillColor.indexOf('olive') >= 0 ||
        fillColor.indexOf('purple') >= 0 ||
        fillColor.indexOf('red') >= 0 ||
        fillColor.indexOf('teal') >= 0 ||
        fillColor.indexOf('crimson') >= 0 ||
        fillColor.indexOf('coral') >= 0 ||
        fillColor.indexOf('brown') >= 0 ||
        fillColor.indexOf('blue') >= 0 ||
        fillColor.indexOf('black') >= 0) {
        return 'white';
    }
    return undefined;
}
