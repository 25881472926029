import {Resize} from './modules/Resize';
import Quill from 'quill';
import {BaseModule} from './modules/BaseModule';
import {Toolbar} from './modules/Toolbar';

const overlayStyles = {
    position: 'absolute',
    boxSizing: 'border-box',
    border: '1px dashed #444'
};
const modules = [Resize, Toolbar];
const selectAttrs = ['userSelect', 'mozUserSelect', 'webkitUserSelect', 'msUserSelect'];

/**
 * Custom module for quilljs to allow user to resize <img> elements
 * (Works on Chrome, Edge, Safari and replaces Firefox's native resize behavior)
 * @see https://quilljs.com/blog/building-a-custom-module/
 */
export default class ImageResize {
    private quill: Quill;
    private parent: HTMLElement;
    private overlay: HTMLDivElement | null = null;
    private img: HTMLImageElement | null = null;
    private modules: Array<BaseModule> = [];

    options: any;

    constructor(quill: Quill) {
        // disable native image resizing on firefox
        document.execCommand('enableObjectResizing', false, 'false');
        this.quill = quill;
        this.parent = quill.root.parentNode! as HTMLElement;
        this.parent.addEventListener('click', this.handleClick, false);
        this.parent.addEventListener('keydown', this.hide);
    }

    private handleClick = (evt: MouseEvent) => {
        const target = evt.target as HTMLImageElement;
        if (target && target.tagName && target.tagName.toUpperCase() === 'IMG') {
            if (this.img === target) {
                // we are already focused on this image
                return;
            }
            if (this.img) {
                // we were just focused on another image
                this.hide();
            }
            // clicked on an image inside the editor
            this.show(target);
        } else if (this.img) {
            // clicked on a non image
            this.hide();
        }
    }

    private show = (img: HTMLImageElement) => {
        // keep track of this img element
        this.img = img;
        this.removeModules();
        this.showOverlay();
        this.modules = modules.map(ModuleClass => new (ModuleClass)(img, this.overlay!, this.repositionElements));
        this.modules.forEach((module) => module.onCreate());
    }

    private hide = () => {
        this.hideOverlay();
        this.removeModules();
        this.img = null;
    }

    private removeModules = () => {
        this.modules.forEach((module) => module.onDestroy());
        this.modules = [];
    }

    private showOverlay = () => {
        if (this.overlay) {
            this.hideOverlay();
        }
        const Parchment = Quill.import('parchment');
        const blot = Parchment.find(this.img);
        if (blot) {
            this.quill.setSelection(blot.offset() + blot.length(), 0, 'silent');
        }
        // prevent spurious text selection
        this.setUserSelect('none');
        // Create and add the overlay
        this.overlay = document.createElement('div');
        Object.assign(this.overlay.style, overlayStyles);
        this.parent.appendChild(this.overlay);
        this.repositionElements();
    }

    private hideOverlay = () => {
        if (!this.overlay) {
            return;
        }
        // Remove the overlay
        this.parent.removeChild(this.overlay);
        this.overlay = null;
        // reset user-select
        this.setUserSelect('');
    }

    private repositionElements = () => {
        if (!this.overlay || !this.img) {
            return;
        }
        // position the overlay over the image
        const parent = this.parent as HTMLElement;
        const imgRect = this.img.getBoundingClientRect();
        const containerRect = parent.getBoundingClientRect();

        Object.assign(this.overlay.style, {
            left: `${imgRect.left - containerRect.left - 1 + parent.scrollLeft}px`,
            top: `${imgRect.top - containerRect.top + parent.scrollTop}px`,
            width: `${imgRect.width}px`,
            height: `${imgRect.height}px`
        });
    }

    private setUserSelect = (value: string) => {
        selectAttrs.forEach((prop) => {
            this.parent.style[prop as any] = value;
            document.documentElement.style[prop as any] = value;
        });
    }
}