import * as React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Container, Item } from '../common/Misc';
import ButtonBar from '../common/components/ButtonBar';
import AppButton from '../common/components/AppButton';
import Typography from '@mui/material/Typography';
import { UserAware, userProviderContextTypes } from '../auth/Auth';
import { hideAllProgresses } from '../redux/ReduxConfig';
import * as Backend from '../util/firebase';
import * as Utils from '../util/utility';
import Logo from '../common/Logo';
import { styles } from '../styles';
import { Urls, VERSION } from '../util/config';
import { ErrorInfo } from "../types/EventTypes";
const errBanner = '/img/event-err.jpg';

export function logError(id: string, e: ErrorInfo) {
    return Backend.setDoc(Backend.doc(Backend.errorsDb, id), e);
}

type ErrorProps = {
    error: any;
    errorInfo: any;
    noheader?: boolean;
} & WithStyles<typeof styles>;

export const ErrorPage = withStyles(styles)(class extends React.Component<ErrorProps> {
    render() {
        const { error, classes, noheader } = this.props;
        return (
            <Container>
                {!noheader && <AppBar>
                    <Toolbar variant="regular">
                        <Logo />
                    </Toolbar>
                </AppBar>}
                <Item>
                    <img src={errBanner} style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }} alt="" />
                </Item>
                <Item style={{ maxWidth: '80%' }} >
                    <Typography style={{ margin: 16 }} variant="h5" className={classes.colorPrimary}>Sorry! ﻿We missed the fairway.</Typography>
                    <Typography style={{ margin: 16, maxWidth: '80%', wordWrap: "break-word" }} variant="body1">Something went wrong, and we have been notified. </Typography>
                    <Typography style={{ margin: 16, maxWidth: '80%', wordWrap: "break-word" }} variant="body1">{error && error.toString()}</Typography>
                    <Typography style={{ margin: 16, maxWidth: '80%', wordWrap: "break-word" }} variant="body1">Please proceed with one of the following options.<br /> Thank you. </Typography>
                    <ButtonBar margin>
                        <div style={{ marginRight: 8, marginLeft: 8, marginTop: -8 }} >
                            <AppButton color="info" onClick={() => window.location.replace(Urls.helpURL)}>Support</AppButton>
                            <AppButton color="primary" onClick={() => window.location.replace('/')}>Events home</AppButton>
                        </div>
                    </ButtonBar>
                </Item>
            </Container>
        );
    }
});

const ErrorBoundary = withStyles(styles)(class extends React.Component<{ children?: React.ReactNode } & WithStyles<typeof styles>, { error: any, errorInfo: any }> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    constructor(props: { children?: React.ReactNode } & WithStyles<typeof styles>) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        const { user } = this.context;
        logError(Utils.formatDateTimeMs(new Date()), { v: VERSION, p: window.location.pathname, e: error?.toString(), u: user?.uid } as ErrorInfo)
            .then(() => this.setState({ error, errorInfo }));
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        const { error, errorInfo } = this.state;
        if (errorInfo) {
            setTimeout(() => {
                hideAllProgresses();
            }, 0);
            return <ErrorPage error={error} errorInfo={errorInfo} />;
        }
        return this.props.children;
    }
});

export default ErrorBoundary;
