import * as React from 'react';
import { ResultStatus, CourseResponse } from '../../../../../../types/EventTypes';
import { getFacilitiesForAddress } from '../../../../../Courses';

interface Props {
    address: string;
    auto?: boolean;
    onResult: (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => void;
    withTeesOnly: boolean;
}

export default class AddressFacilitiesProvider extends React.Component<Props> {

    private mounted: boolean = false;
    private controller = new AbortController();

    componentDidMount() {
        this.mounted = true;
        const { auto } = this.props;
        if (auto) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    render() {
        return null;
    }

    private onResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        if (this.mounted) {
            const { onResult } = this.props;
            onResult(loadStatus, courseResponse, err);
        }
    }

    public load = async () => {
        this.onResult('in_progress');
        try {
            const { address } = this.props;
            if (!address) {
                return this.onResult('error');
            }
            const courseResponse = await getFacilitiesForAddress(address, this.props.withTeesOnly, this.controller);
            if (!courseResponse.city || !courseResponse.facilities) {
                return this.onResult('error', undefined, 'No courses found');
            }
            this.onResult('ok', courseResponse);
        } catch (err) {
            this.onResult('error', undefined, err);
        }
    }
}
