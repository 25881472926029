import * as React from 'react';
import * as Backend from '../../../../../../util/firebase';
import { Cancelable, CancelablePromise, makeCancelable } from '../../../../../../util/promise';
import { Facility, ResultStatus } from '../../../../../../types/EventTypes';

type QuerySnapshot = Backend.QuerySnapshot;

interface RecentProviderProps {
    onResult: (loadStatus: ResultStatus, facilities?: Array<Facility>, err?: any) => void;
    savedCourse?: boolean;
}

export interface RecentFacility extends Facility, Backend.Entity {
    time: number;
}

export function saveToRecent(facility: Facility, userId: string) {
    const facilityWithTime = { ...facility, time: new Date().getTime() };
    Backend.updateOrAdd(Backend.recentCoursesDb(userId), facilityWithTime, true);
}

export class RecentProvider extends React.Component<RecentProviderProps & { userId: string }> {
    
    private mounted: boolean = false;
    private loadingPromise?: CancelablePromise<QuerySnapshot>;

    componentDidMount() {
        this.mounted = true;
        this.load();
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.loadingPromise) {
            this.loadingPromise.cancel();
        }
    }

    render() {
        return null;
    }

    private onResult = (loadStatus: ResultStatus, facilities?: Array<Facility>, err?: any) => {
        if (this.mounted) {
            const { onResult } = this.props;
            onResult(loadStatus, facilities, err);
        }
    }

    public load = () => {
        const { userId, savedCourse } = this.props;
        this.onResult('in_progress');
        const q =  Backend.query((savedCourse ? Backend.coursesDb : Backend.recentCoursesDb)(userId), Backend.orderBy('time', 'desc'));
        this.loadingPromise = makeCancelable(Backend.getDocs(q));
        this.loadingPromise.promise.then(querySnapshot => {
            if (!this.mounted) {
                return Promise.reject();
            }
            const facilities = Backend.toArray<RecentFacility>(querySnapshot);
            if (facilities.length === 0) {
                return this.onResult('error', undefined, 'No courses found');
            }
            this.onResult('ok', facilities);
        }, (err?: Cancelable) => {
            if (err && !err.canceled) {
                this.onResult('error', undefined, `Loading failed (${err})`);
            }
        });
    }
}

export default RecentProvider;
