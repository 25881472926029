import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { liveScoresName } from '../../Event';
import { processEnterKey } from '../../../util/react_utils';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { styles } from '../../../styles';

type Func = () => void;
type SaveFunc<T> = (teams: T) => void;
type TeamsSelectDialogProps = DialogProps & WithStyles<typeof styles> & { save: SaveFunc<boolean | 'OFF' | 'ON' | 'VERIFIED' | undefined>, close: Func, hideLiveScores: boolean | 'OFF' | 'ON' | 'VERIFIED' | undefined };
type TeamsSelectDialogState = { hideLiveScores: boolean | 'OFF' | 'ON' | 'VERIFIED' | undefined };

class SelectLiveScoresDialog extends React.Component<TeamsSelectDialogProps, TeamsSelectDialogState> {
    constructor(props: TeamsSelectDialogProps) {
        super(props);
        this.state = { hideLiveScores: props.hideLiveScores };
    }

    private handleSelectChange = (val: 'OFF' | 'ON' | 'VERIFIED') => this.setState({ hideLiveScores: val });
    private handleSave = () => this.props.save(this.state.hideLiveScores);

    render() {
        const { close, open, classes } = this.props;
        const hideLiveScores = this.state.hideLiveScores || 'ON';
        return (
            <XSMobileDialog open={open} onClose={close}>
                <DialogAppBar label={'Live scores'} close={close} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, () => this.handleSave)} >
                    <FormControl
                        variant="standard"
                        margin="dense"
                        fullWidth
                        style={{ flexDirection: 'column' }}>
                        <TypedFormRadioLabel
                            value={'ON'}
                            label={<div><Typography>{liveScoresName('ON')}</Typography><Typography className={classes.liveScoresOn}>
                                All scores posted by golfers until 11:59pm on the day of the event will be shown.</Typography></div>}
                            currentValue={hideLiveScores as 'OFF' | 'ON' | 'VERIFIED'}
                            handleChange={this.handleSelectChange}
                            className={classes.formSelector} />
                        <TypedFormRadioLabel
                            value={'OFF'}
                            label={liveScoresName('OFF')}
                            currentValue={hideLiveScores as 'OFF' | 'ON' | 'VERIFIED'}
                            handleChange={this.handleSelectChange}
                            className={classes.formSelector} />
                        <TypedFormRadioLabel
                            value={'VERIFIED'}
                            label={liveScoresName('VERIFIED')}
                            currentValue={hideLiveScores as 'OFF' | 'ON' | 'VERIFIED'}
                            handleChange={this.handleSelectChange}
                            className={classes.formSelector} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={close}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(SelectLiveScoresDialog);
