import * as React from 'react';
import { DialogActions, Typography } from '@mui/material';
import LabeledField from './LabeledField';
import { formatDateUniversal } from 'src/event/Event';
import { XSMobileDialog } from '../dialog/MobileDialog';
import DialogAppBar from '../dialog/DialogAppBar';
import AppButton from '../components/AppButton';
import MaterialDate from '../MaterialDate';
import { Spacing } from '../Misc';
import { MONTH_DATE_FORMAT } from 'src/util/config';

type DlgProps = {
    eventDate: number;
    onClose: () => void;
    onSave: (v: number) => void;
};

type DlgState = {
    eventDate: number;
};

const EditDateDialog = class extends React.Component<DlgProps, DlgState> {
    state: DlgState = {
        eventDate: this.props.eventDate
    };
    render() {
        const { onClose, onSave } = this.props;
        const { eventDate } = this.state;
        return <XSMobileDialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
            <DialogAppBar label="Date" close={onClose} />
            <div style={{ padding: '16px' }}>
                <MaterialDate
                    icon
                    enableUnderline
                    label="Event date"
                    value={eventDate}
                    format={MONTH_DATE_FORMAT}
                    onChange={date => this.setState({ eventDate: date.getTime() })}
                    popperPlacement={'bottom'}
                    style={{ width: '100%', maxWidth: 400 }} />
                <Spacing />
            </div>
            <DialogActions>
                <AppButton color="info" onClick={onClose}>Cancel</AppButton>
                <AppButton color="secondary" onClick={() => onSave(eventDate)}>Save</AppButton>
            </DialogActions>
        </XSMobileDialog>;
    }
};

type Props = {
    value: number;
    label: string;
    save: (v: number) => void;
};

type State = { dialogOpened?: boolean };

export const EditDateField = class extends React.Component<Props, State> {
    state: State = {}

    onClose = () => this.setState({ dialogOpened: false });
    onOpen = () => this.setState({ dialogOpened: true });
    onSave = (dateTime: number) => this.setState({ dialogOpened: false }, () => this.props.save(dateTime));

    render() {
        const { label, value } = this.props;
        const { dialogOpened } = this.state;
        return <>
            <LabeledField
                label={label}
                value={<Typography variant="body1">{formatDateUniversal(value, MONTH_DATE_FORMAT)}</Typography>}
                edit={this.onOpen}
                editMode
            />
            {dialogOpened && <EditDateDialog
                onClose={this.onClose}
                onSave={this.onSave}
                eventDate={value}
            />}
        </>;
    }
};
