import { Box, TextField } from "@mui/material";
import { formatDateUniversal } from "../event/Event";
import { TimeIcon } from "./Icons";
import { TimeDialog } from "./TimeDialog";
import * as React from "react";
import { AppColors } from "../main/Theme";

type TimeTextFieldProps = {
    date: number;
    onTimeSelected: (newDate: Date) => void;
    label: string;
    style?: React.CSSProperties;
    autoFocus?: boolean;
};

export const TimeTextField = ({ date, onTimeSelected, label, style, autoFocus }: TimeTextFieldProps) => {
    const [timeDialogOpened, setTimeDialogOpened] = React.useState(false);
    const changeTimeDialogOpened = () => setTimeDialogOpened(!timeDialogOpened);
    return (
        <Box>
            <TextField
                variant="standard"
                id="time"
                label={label}
                style={style}
                autoFocus={autoFocus}
                value={formatDateUniversal(date, "h:mm A")}
                onClick={changeTimeDialogOpened}
                InputProps={{
                    readOnly: true,
                    endAdornment: <TimeIcon style={{ cursor: 'pointer' }} color={AppColors.webBlue500} />
                }}
            />
            {timeDialogOpened && <TimeDialog eventDate={date}
                setTime={onTimeSelected}
                onClose={changeTimeDialogOpened} />}
        </Box>
    )
}