import * as React from 'react';

import { Event, getRegistrationDate } from '../../../../types/EventTypes';
import { formatRegistrationDate, getPaymentFeeStr } from '../../../Event';
import RegistrationSettingsDialog from './RegistrationSettingsDialog';
import LabeledField from '../../../../common/form/LabeledField';
import { ListElem } from '../../../../common/Misc';
import { roundDate, roundToNextDate } from '../../../../util/utility';

interface Props {
    event: Event;
}

interface State {
    dialogOpened?: boolean;
}

const HCP_ELEM = 'hcp_select_elem';

class RegistrationSettingsField extends React.Component<Props, State> {
    state: State = {};

    private handleOpen = () => this.setState({ dialogOpened: true });
    private handleClose = () => this.setState({ dialogOpened: false });

    render() {
        const { event } = this.props;
        const { dialogOpened } = this.state;
        const deadline = roundToNextDate(getRegistrationDate(event));
        const now = roundDate(new Date().getTime());
        const feeStr = getPaymentFeeStr(event.paymentSettings);
        const title = event.registrationByAdmin ? 'Only event administrator can add golfers' : deadline >= now ?
            `Open to anyone with the event link${feeStr ? ` (${feeStr} fee)` : ''}` : 'Registration is now closed';
        const formatted = formatRegistrationDate(event);
        const subtitle = event.registrationByAdmin ? ' ' : `Self-registration deadline: ${formatted}`;
        return <>
            <LabeledField label="Registration" value={(<ListElem id={HCP_ELEM} title={title} subtitle={subtitle} />)} edit={this.handleOpen} />
            {dialogOpened && <RegistrationSettingsDialog
                open
                event={event}
                onClose={this.handleClose}
            />}
        </>;
    }
}

export default RegistrationSettingsField;
