import * as React from 'react';
import { List } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../../util/firebase';
import { WithDataItem, withDataItem } from '../../../../common/WithData';
import RichTextField from '../../../../common/form/richtext/RichTextField';
import LabeledField from '../../../../common/form/LabeledField';
import { Event, Portal } from '../../../../types/EventTypes';
import { TEMPLATE_ABOUT, elog } from '../../../Event';
import ChooseImgDialog from './ChooseImgDialog';
import { styles } from '../../../../styles';
import { FirebaseDocComponent } from '../../../../common/WithData';

interface PortalSettingsState {
    event: Event;
    dialogOpened: boolean;
    imgTypeDialog: Backend.ImageType;
}

class PortalSettings extends React.Component<WithDataItem<Portal> & WithStyles<typeof styles>, PortalSettingsState> {

    state = { dialogOpened: false } as PortalSettingsState;

    componentDidMount() {
        Backend.trackEvent('view_portal_settings');
    }

    private setEvent = (event: Event) => this.setState({ event });

    private handleSave = (key: keyof Portal) => (value: any) => {
        const p = { ...this.props.item };
        p[key] = value;
        const portal = p as Portal;
        Backend.updateOrAdd(Backend.portalInfoDb, portal, true)
            .then(() => elog(this.state.event, `Portal ${key} changed`, `No details`, `Id: ${this.state.event.id}`))
            .then(() => Backend.setUserDataPromise({ lastBadge: portal.badgeUrl, lastBanner: portal.bannerUrl }));
    }

    private handleSaveImg = (type: Backend.ImageType) => (url: string) => {
        this.handleSave(type === 'badge' ? 'badgeUrl' : 'bannerUrl')(url);
        this.handleDialogClose();
    }

    private handleImgClick = (type: Backend.ImageType) => () => this.setState({ imgTypeDialog: type, dialogOpened: true });
    private handleDialogClose = () => this.setState({ dialogOpened: false });

    render() {
        const portal = this.props.item;
        const { classes } = this.props;
        const badgeUrl = portal ? portal.badgeUrl : undefined;
        const bannerUrl = portal ? portal.bannerUrl : undefined;
        const about = (portal && portal.about) ? portal.about : '';
        const template = TEMPLATE_ABOUT;
        const placeholder = 'Event description has not been added yet';
        const { imgTypeDialog, dialogOpened } = this.state;
        return (
            <React.Fragment>
                <List className={classes.listRootGreyRound}>
                    <LabeledField label="Badge" value={<img src={badgeUrl} className={classes.img} alt="" />} edit={this.handleImgClick('badge')} />
                    <LabeledField label="Banner" value={<img src={bannerUrl} className={classes.img} alt="" />} edit={this.handleImgClick('banner')} />
                    <RichTextField label="About" value={about} placeholder={placeholder} template={template} save={this.handleSave('about')} />
                    {dialogOpened && <ChooseImgDialog open={dialogOpened} imageType={imgTypeDialog}
                        handleClose={this.handleDialogClose} handleSelection={this.handleSaveImg(imgTypeDialog)} />}
                </List>
                <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} docReference={Backend.eventFields(portal!.id)} />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withDataItem<Portal, {}>(Backend.portalInfoDb)(PortalSettings));
