import { useEffect, useRef } from "react";

const useDidUpdateEffect = <T>(func: Function, deps: T[]) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            func();
        } else {
            mounted.current = true;
        }
        // TODEL __eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useDidUpdateEffect;