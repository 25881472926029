import * as React from 'react';
import { Fab, Hidden, Menu, IconButton, MenuItem } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { RoutingButton, RoutingMenuItem } from './Routing';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MouseEvent } from 'react';
import { UserAware, userProviderContextTypes } from '../auth/Auth';
import { styles } from '../styles';
import { ITEM_NEW_EVENT_ID, Urls } from '../util/config';
import { FlexCenter } from 'src/common/Misc';
import { GobletIcon, HelpIcon, PersonIcon, PlusIcon, RightArrowIcon } from 'src/common/Icons';
import AppButton from 'src/common/components/AppButton';
import NewEventDialog from 'src/event/list/NewEventDialogDetailed';
import DuplicateEventDialog from 'src/event/list/DuplicateEventDialog';
import { Event } from 'src/types/EventTypes';

interface NavigationMenuState {
    anchor?: HTMLElement;
    addDialogOpened?: boolean;
    duplicateEvent?: Event;
}

class NavigationMenu extends React.Component<WithStyles<typeof styles>, NavigationMenuState> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;
    state: NavigationMenuState = {};

    handleMenuOpen = (event: MouseEvent<HTMLElement>) => this.setState({ anchor: event.currentTarget });
    handleMenuClose = () => this.setState({ anchor: undefined });

    private handleCloseDialog = (addedId?: string) => {
        this.setState({ addDialogOpened: false, duplicateEvent: undefined });
        if (addedId) {
            localStorage.setItem(ITEM_NEW_EVENT_ID, addedId);
            window.location.replace(`/events/${addedId}`);
        }
    }

    private handleDuplicateEvent = () => {
        this.setState({ addDialogOpened: false, duplicateEvent: { id: '' } as Event });
    }

    private openHelpPage = () => {
        window.open(Urls.helpURL, 'help');
        this.handleMenuClose();
    }

    render() {
        const { classes } = this.props;
        const { loggedIn, effectiveUserId } = this.context;
        const { duplicateEvent, addDialogOpened } = this.state;
        if (loggedIn) {
            const path = window.location.pathname;
            const pathEvents = path === '/events' || path == '/events/';
            const pathInEvent = !pathEvents && path.startsWith('/events/');
            return <>
                <span color="inherit" style={{ flex: 1, fontSize: 12 }} />
                <Hidden mdDown>
                    <RoutingButton color="inherit" variant="extended" to="/events"
                        sx={{ minWidth: 140 }}
                        className={pathEvents ? classes.toolbarButtonActive : classes.toolbarButton}>
                        <FlexCenter><GobletIcon /> &nbsp; My Events</FlexCenter>
                    </RoutingButton>
                    <RoutingButton color="inherit" variant="extended" to="/account"
                        sx={{ minWidth: 140 }}
                        className={path.indexOf('/account') === 0 ? classes.toolbarButtonActive : classes.toolbarButton}>
                        <FlexCenter><PersonIcon /> &nbsp; Account</FlexCenter>
                    </RoutingButton>
                    <Fab href={Urls.helpURL} target="help" variant="extended"
                        sx={{ minWidth: 124 }}
                        className={classes.toolbarButton}>
                        <HelpIcon /> &nbsp; HELP <RightArrowIcon sx={{ height: '.5em' }} />
                    </Fab>
                    {!pathInEvent && <AppButton color="info"
                        sx={{ minWidth: 124 }}
                        className={classes.toolbarButtonInv}
                        onClick={() => this.setState({ addDialogOpened: true })}>
                        <PlusIcon sx={{ width: '.7em', height: '.7em' }} /> New event
                    </AppButton>}
                    {effectiveUserId &&
                        <RoutingButton color="inherit" variant="extended" to="/sup"
                            className={path.indexOf('/sup') === 0 ? classes.toolbarButtonActive : classes.toolbarButton}>SUP</RoutingButton>}
                </Hidden>
                <Hidden mdUp>
                    {!pathInEvent && <AppButton color="info"
                        sx={{ minWidth: 70 }}
                        className={classes.toolbarButtonInv}
                        onClick={() => this.setState({ addDialogOpened: true })}>
                        <PlusIcon sx={{ width: '.7em', height: '.7em' }} /> New
                    </AppButton>}
                    {effectiveUserId &&
                        <RoutingButton color="inherit" variant="extended" to="/sup"
                            className={path.indexOf('/sup') === 0 ? classes.toolbarButtonActive : classes.toolbarButton}>SUP</RoutingButton>}
                    <IconButton onClick={this.handleMenuOpen} color="inherit" size="large">
                        <MoreVertIcon color="inherit" />
                    </IconButton>
                    <Menu
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        slotProps={{ root: { style: { backgroundColor: 'rgba(21, 21, 21, 0.4)' } } }}
                        anchorEl={this.state.anchor} open={!!this.state.anchor} onClose={this.handleMenuClose}>
                        <RoutingMenuItem color="info" to="/events" onClick={this.handleMenuClose}>
                            <FlexCenter><GobletIcon color="secondary" /> &nbsp; My Events</FlexCenter>
                        </RoutingMenuItem>
                        <RoutingMenuItem color="info" to="/account" onClick={this.handleMenuClose}>
                            <FlexCenter><PersonIcon color="secondary" /> &nbsp; Account</FlexCenter>
                        </RoutingMenuItem>
                        <MenuItem onClick={this.openHelpPage} >
                            <HelpIcon color="secondary" /> &nbsp; Help <RightArrowIcon sx={{ height: '.5em' }} />
                        </MenuItem>
                    </Menu>
                </Hidden>
                {addDialogOpened &&
                    <NewEventDialog
                        noDuplicate
                        handleClose={this.handleCloseDialog}
                        handleDuplicateEvent={this.handleDuplicateEvent}
                    />}
                {duplicateEvent &&
                    <DuplicateEventDialog
                        event={duplicateEvent}
                        handleClose={this.handleCloseDialog}
                    />}
            </>;
        } else {
            return null;
        }
    }
}

export default withStyles(styles)(NavigationMenu);
